import firebase from "firebase/app";
import { db } from "lib-fullstack";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

// Components
import BoltIcon from "@mui/icons-material/BoltRounded";
import { Button, Stack, Tooltip, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// UI Utilities
import yoodliTheme from "lib-frontend/ui/Theme";

// Assets
import MetaphorGraphic from "../graphics/metaphor.svg";
import NoFillerGraphic from "../graphics/nofillers.svg";
import SpinGraphic from "../graphics/spinayarn.svg";
import StorytellerGraphic from "../graphics/storyteller.svg";

// Utils
// Utilities
import { DRAWER_MENU_WIDTH } from "../../../utils/Constants";
import PageContent from "../../PageContent";
import toastmastersGameTheme from "../toastmasters/ToastmastersGameTheme";
import GameCard from "./GameCard";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { determineDailyStreak } from "lib-frontend/utils/DbUtils";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { WebServerInternalPath } from "utils/paths";

export const IMPROMPTU_GAME_OPTIONS = {
  METAPHOR_MANIA: {
    name: "Metaphor Mania",
    description: "Quickly build analogies without letting the pressure affect you",
    labels: ["🧠 Lateral Thinking", "🙌 Conciseness", "🏃‍♀️ Agility"],
    graphic: MetaphorGraphic,
  },
  SPIN_A_YARN: {
    name: "Spin a Yarn",
    description: "Maintain your train of thought with plot twists along the way",
    labels: ["🗣 Articulation", "🛖 Structure", "🧠 Lateral Thinking"],
    graphic: SpinGraphic,
  },
  NO_FILLERS: {
    name: "No Filler",
    description: "Speak without using filler words like 'um', 'like', and 'so'",
    labels: ["🙈 Filler Words", "🗣 Articulation", "🛖 Structure"],
    graphic: NoFillerGraphic,
  },
  STORY_TELLER: {
    name: "Storyteller (Beta)",
    description: "Tell a creative story with the help of your AI co-author",
    labels: ["🗣 Articulation", "🛖 Structure", "🧠 Lateral Thinking"],
    graphic: StorytellerGraphic,
  },
};

export default function GamesCatalog(): React.ReactElement {
  const navigate = useNavigate();

  const user = firebase.auth().currentUser;
  const { highScoreYarn, highScoreNoFiller } = getLiveUserDocMain();

  const [highScores, setHighScores] = React.useState<{
    SPIN_A_YARN?: number;
    NO_FILLERS?: number;
  }>({});

  React.useEffect(() => {
    setHighScores({
      SPIN_A_YARN: highScoreYarn,
      NO_FILLERS: highScoreNoFiller,
    });
  }, [highScoreYarn, highScoreNoFiller]);

  const siteConf = getStaticFullSiteConf();
  const userDocPublic = useUserDocsState()[db.UserDocTypes.PUBLIC];

  const siteTheme = isToastmasters(siteConf) ? toastmastersGameTheme : yoodliTheme;

  const [dailyStreak, setDailyStreak] = React.useState(undefined);

  useEffect(() => {
    if (user) {
      const updatedDailyStreak = determineDailyStreak();
      setDailyStreak(updatedDailyStreak);
    }
  }, [userDocPublic]);

  const handleGameStartPlayingClick = (gameOption) => {
    switch (gameOption) {
      case IMPROMPTU_GAME_OPTIONS.SPIN_A_YARN:
        console.log("User wants to play Yarn Game");
        navigate(WebServerInternalPath.EXERCISES_SPIN_A_YARN);
        break;
      case IMPROMPTU_GAME_OPTIONS.METAPHOR_MANIA:
        console.log("User wants to play Metaphor Mania");
        navigate(WebServerInternalPath.EXERCISES_METAPHOR_MANIA);
        break;
      case IMPROMPTU_GAME_OPTIONS.NO_FILLERS:
        console.log("User wants to play No Fillers");
        navigate(WebServerInternalPath.EXERCISES_NO_FILLER);
        break;
      case IMPROMPTU_GAME_OPTIONS.STORY_TELLER:
        console.log("User wants to play Storyteller");
        navigate(WebServerInternalPath.EXERCISES_STORYTELLER);
        break;
    }
  };

  return (
    <ThemeProvider theme={siteTheme}>
      <PageContent title="Exercises">
        <Box
          sx={{
            width: "100%",
            top: 0,
            left: DRAWER_MENU_WIDTH.CLOSED,
            overflowY: "scroll",
            pt: 3,
            backgroundSize: "cover",
            backgroundColor: getDynamicColor("gradient.slantedLight"),
            justifyContent: "center",
            alignItems: "stretch",
            verticalAlign: "middle",

            ".exercise-graphic": {
              mx: "auto",
            },
          }}
        >
          <Tooltip
            title={isMobile ? "" : "Use Yoodli everyday to build your streak!"}
            placement="bottom-end"
          >
            <Button
              sx={{
                zIndex: 2,
                position: "absolute",
                top: 16,
                right: 0,
                ml: { xs: 0, sm: "auto" },
                mr: { xs: 0, sm: 2 },
                cursor: "default",
              }}
              startIcon={<BoltIcon />}
            >
              Daily Streak: {dailyStreak} {dailyStreak === 1 ? "day" : "days"}
            </Button>
          </Tooltip>
          <Stack
            justifyContent="center"
            alignItems="stretch"
            direction="row"
            flexWrap="wrap"
            height={!user ? "100%" : "auto"}
            padding={2}
          >
            {Object.entries(IMPROMPTU_GAME_OPTIONS).map(([key, gameOption]) => {
              return (
                <GameCard
                  key={key}
                  handleGameStartPlayingClick={() => handleGameStartPlayingClick(gameOption)}
                  game={gameOption}
                  highScore={highScores[key]}
                />
              );
            })}
          </Stack>
        </Box>
      </PageContent>
    </ThemeProvider>
  );
}
