import React from "react";

// Components
import { ExpandCircleDownRounded as ExpandCircleDownRoundedIcon } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// Utils
import { YoodliSelectOption, setBorderColor } from "./YoodliSelect";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";

type YoodliAutocompleteProps = {
  options: YoodliSelectOption[];
  placeholder?: string;
};

export const YoodliAutocomplete = (
  props: Partial<AutocompleteProps<YoodliSelectOption, boolean, boolean, boolean>> &
    YoodliAutocompleteProps
): JSX.Element => {
  const { options, placeholder, onChange } = props;
  // remove invalid dom attributes before spreading props on Autocomplete
  const autocompleteProps = { ...props };

  const [isOpen, setIsOpen] = React.useState<boolean>(props.open ?? false);

  return (
    <Autocomplete
      {...autocompleteProps}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      value={props.value}
      isOptionEqualToValue={(option: YoodliSelectOption, value: YoodliSelectOption) =>
        option.value === value.value
      }
      open={isOpen}
      onChange={onChange}
      options={options}
      disablePortal={props.disablePortal ?? true}
      renderOption={(optionProps, option: YoodliSelectOption) => {
        return (
          <MenuItem
            {...optionProps}
            key={option.value}
            value={option.value}
            sx={{
              all: "initial",
              width: "100%",
              "&.Mui-selected": {
                ".actualMenuItem": {
                  color: getDynamicColor("primary"),
                  backgroundColor: `${getDynamicColor("light2")}`,
                },
                ".subLabel": {
                  color: getDynamicColor("dark5"),
                },
              },
            }}
            disableRipple
            disableTouchRipple
          >
            {option.showDivider && (
              <Divider
                sx={{
                  my: 1,
                  position: "relative",
                  zIndex: 2,
                }}
              />
            )}
            <Box
              // this is the menu item that has the hover effects and the click listener
              className={`actualMenuItem ${option.value === props.value ? "selected" : ""}`}
              sx={{
                width: "100%",
                cursor: "pointer",
                fontFamily: "poppins",
                color: `${
                  props.value === option.value
                    ? getDynamicColor("primary")
                    : getDynamicColor("purple3")
                }`,
                "&:hover": {
                  backgroundColor: getDynamicColor("dark1"),
                },

                fontSize: "inherit",
              }}
            >
              {option.JSXOverride ? (
                option.JSXOverride
              ) : (
                <Box sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    gap={1}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      className="label"
                      sx={{
                        flexGrow: 1,
                        mb: 0.5,
                      }}
                    >
                      {option.label}
                    </Typography>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: 12,
                        color: getDynamicColor("dark4"),
                        textAlign: "right",
                        ...option.labelTagSx,
                      }}
                    >
                      {option.labelTag}
                    </Typography>
                  </Stack>
                  {option.subLabel && (
                    <Typography
                      className="subLabel"
                      sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: getDynamicColor("dark4"),
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        ...option.subLabelSx,
                      }}
                    >
                      {option.subLabel}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={!props?.value ? placeholder : undefined}
          sx={{
            fontSize: 16,
            fontWeight: 500,
            border: "none",
            fieldset: {
              border: "none",
            },
          }}
        />
      )}
      popupIcon={
        <ExpandCircleDownRoundedIcon
          sx={{
            color: `${props.disabled ? getDynamicColor("dark4") : getDynamicColor("primary")}`,
            transform: `${props.open ? "rotate(180deg)" : "rotate(0)"}`,
          }}
        />
      }
      componentsProps={{
        paper: {
          sx: {
            borderRadius: "8px !important",
            boxShadow: Y_SHADOWS.box_shadow_1,
            p: 1,
            mt: 1,
            fontFize: 16,
            fontFamily: "poppins",
            fontWeight: 500,
          },
        },
      }}
      sx={{
        fontFamily: "poppins",
        minHeight: 57,
        minWidth: 250,
        width: "100%",
        flexGrow: 1,
        borderRadius: "4px",

        border: setBorderColor(props.disabled, isOpen),
        ul: {
          py: 0,
        },
        ...props.sx,
      }}
    />
  );
};
