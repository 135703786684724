import { Editor, Extensions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

import { isValidUrl } from "utils/navUtils";

interface UseWYSIWYGProps {
  content?: string;
  characterLimit?: number;
  placeholder?: string;
}

export const useWYSIWYG = ({ content = "", characterLimit, placeholder }: UseWYSIWYGProps = {}): {
  editor: Editor | null;
  characterCount: number;
  isAtCharacterLimit: boolean;
} => {
  const extensions: Extensions = [
    StarterKit,
    CharacterCount.configure({
      limit: characterLimit,
    }),
    TextStyle,
    Underline,
    Link.configure({
      openOnClick: false,
      linkOnPaste: false,
      validate: (href) => isValidUrl(href),
      autolink: false,
    }),
    // Add this conditional extension
    ...(placeholder ? [Placeholder.configure({ placeholder })] : []),
  ];

  const editor = useEditor({
    content,
    extensions,
  });

  return {
    editor,
    characterCount: editor?.storage.characterCount.characters() ?? 0,
    isAtCharacterLimit: characterLimit
      ? (editor?.storage.characterCount.characters() ?? 0) >= characterLimit
      : false,
  };
};
