// Utils
import { DefaultGoal, GoalKind } from "lib-fullstack/db/db_runtypes";

/**
 * Map of default goals to their labels.
 */
export const GoalLabels: { [key in DefaultGoal]: string } = {
  [DefaultGoal.UseGROW]: "Use the GROW method",
  [DefaultGoal.ActiveListening]: "Actively listen",
  [DefaultGoal.HitTalkingPoints]: "Hit talking points",
  [DefaultGoal.EngageSmallTalk]: "Engage in small talk",
  [DefaultGoal.IdentifyPainPoints]: "Identify pain points",
  [DefaultGoal.BookFollowUp]: "Book a follow-up",
  [DefaultGoal.HitTimeTarget]: "Hit target time",
  [DefaultGoal.UseSTAR]: "Use the STAR method",
};

/**
 * Map of default goals to their kinds.
 */
export const GoalKinds: { [key in DefaultGoal]: GoalKind } = {
  [DefaultGoal.ActiveListening]: GoalKind.ScoreGoal,
  [DefaultGoal.BookFollowUp]: GoalKind.BinaryGoal,
  [DefaultGoal.UseGROW]: GoalKind.CompoundGoal,
  [DefaultGoal.HitTimeTarget]: GoalKind.BinaryGoal,
  [DefaultGoal.IdentifyPainPoints]: GoalKind.ScoreGoal,
  [DefaultGoal.EngageSmallTalk]: GoalKind.BinaryGoal,
  [DefaultGoal.HitTalkingPoints]: GoalKind.TPGoal,
  [DefaultGoal.UseSTAR]: GoalKind.CompoundGoal,
};

/**
 * Map of default goals to their descriptions.
 */
export const GoalDescriptions: { [key in DefaultGoal]: string } = {
  [DefaultGoal.UseGROW]:
    "Demonstrate good communication skills through the effective use of the GROW Model ",
  [DefaultGoal.ActiveListening]:
    "Demonstrate active listening by responding thoughtfully and acknowledging key points.",
  [DefaultGoal.HitTalkingPoints]: "Ensure that all key talking points are covered.",
  [DefaultGoal.EngageSmallTalk]:
    "Start with casual conversation to build rapport before diving into main topics.",
  [DefaultGoal.IdentifyPainPoints]:
    "Articulate the main challenge or problem faced by the audience.",
  [DefaultGoal.BookFollowUp]: "Successfully arrange a follow-up call to continue the conversation.",
  [DefaultGoal.HitTimeTarget]: "Stay within the time limit for the scenario.",
  [DefaultGoal.UseSTAR]:
    "Demonstrate good interview skills through the effective use of the STAR Method.",
};
