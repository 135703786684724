// Components
import { Button, Typography, Box, Stack } from "@mui/material";

// Utils
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

type ActionCardProps = {
  icon?: JSX.Element;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  handler: (() => Promise<void>) | (() => void);
};

export default function ActionCard({
  icon,
  title,
  subtitle,
  handler,
}: ActionCardProps): JSX.Element {
  const handleClick = async () => {
    await Promise.resolve(handler());
  };
  return (
    <Button
      onClick={handleClick}
      sx={{
        borderRadius: "8px",
        border: `1px solid ${getDynamicColor("dark2")}`,
        background: getDynamicColor("light1"),
        p: 2,
        minWidth: "200px",
        maxWidth: { xs: "unset", sm: "350px" },
        width: { xs: "100%", sm: "unset" },
        textAlign: "center",
        flexDirection: "column",
        color: getDynamicColor("purple3"),
        fontFamily: "Poppins",
        flexGrow: 0.3,
        fontWeight: 400,
        fontSize: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
      }}
    >
      <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
        <Box sx={{ width: 24, height: 24, color: getDynamicColor("primary") }}>{icon}</Box>
        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>{title}</Typography>
      </Stack>
      {subtitle && <Typography>{subtitle}</Typography>}
    </Button>
  );
}
