import React from "react";

// Components
import { Button, Stack, Typography } from "@mui/material";

// Utils
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import InterviewBank from "../ManageContent/CustomizePractice/InterviewBanks/InterviewBank";
import {
  CustomizePracticeTabEnum,
  OrgSettingsTabs,
  isOrgOwnerAdmin,
} from "lib-frontend/utils/orgUtils";
import { OrgContentQueryParams } from "lib-fullstack/utils/queryParams";
import { WebServerInternalPath } from "utils/paths";
import { useNavigate } from "react-router-dom";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export const InterviewBanksTab = ({
  interviewBanks,
}: {
  interviewBanks: InterviewBankResponse[];
}): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { defaultOrg } = React.useContext(UserOrgContext);
  const navigate = useNavigate();

  const bankCount = `${interviewBanks?.length} bank${interviewBanks?.length > 1 ? "s" : ""}`;

  return (
    <Stack gap={3} direction="column">
      <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "baseline" }}>
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", alignItems: "baseline", fontFamily: "poppins", fontWeight: 700 }}
        >
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              mr: 2,
            }}
          >
            Interview Banks
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 14, md: 16 },
              color: getDynamicColor("dark4"),
            }}
          >
            {interviewBanks?.length > 0 && bankCount}
          </Typography>
        </Stack>
        {isOrgOwnerAdmin(defaultOrg) && (
          <Button
            variant="text"
            onClick={() => {
              const qp = new URLSearchParams({
                [OrgContentQueryParams.TAB]: OrgSettingsTabs.CUSTOMIZE_PRACTICE,
                [OrgContentQueryParams.SUBTAB]: CustomizePracticeTabEnum.QuestionBanks,
              });
              navigate({ pathname: WebServerInternalPath.ORG_CONTENT, search: qp.toString() });
            }}
          >
            Manage
          </Button>
        )}
      </Stack>
      {interviewBanks?.map((bank, index) => (
        <InterviewBank
          isSmallScreen={isSmallScreen}
          key={`bank-${index}`}
          index={index}
          interviewBank={bank}
          canUpdateBank={false}
          showBankHubsDetails={false}
        />
      ))}
      {interviewBanks?.length === 0 && <Typography px={2}>No interview banks found</Typography>}
    </Stack>
  );
};
