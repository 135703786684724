import firebase from "firebase/app";
import { db } from "lib-fullstack";
import _ from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router";
import type Slider from "react-slick";

// Components
import {
  BoltRounded as BoltIcon,
  ChevronLeft,
  ChevronRight,
  OndemandVideo as OndemandVideoIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Collapse,
  Link,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import ItemCarousel, { ItemCarouselProps } from "components/Home/ItemCarousel";
import { RecentSessions } from "components/Home/RecentSessions";
import ZoodliCard from "components/Home/ZoodliCard";
import { VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY } from "components/Orgs/MyLearning";
import { MyLearningTabs } from "components/Orgs/MyLearning";
import { OrgProgramsQueryKeys } from "components/Orgs/Programs/OrgPrograms";
import videoLightbox from "ui/VideoLightbox";

// Utils
import Checklist from "./OnboardingChecklist/Checklist";
import { ProgramsCarousel } from "./ProgramsCarousel";
import { ReportCard } from "./ReportCard/ReportCard";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { usePrevious } from "lib-frontend/hooks";
import {
  AuthorizationAPIHeaders,
  getOrgContentMemberViewV2,
  listProgramsMemberView,
} from "lib-frontend/modules/AxiosInstance";
import { currentUserFirstName, currentUserName } from "lib-frontend/utils/AccountUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { determineDailyStreak } from "lib-frontend/utils/DbUtils";
import { getSiteId, getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { getLiveUserDocMain, useUserDocsState } from "lib-frontend/utils/LiveUserDocs";
import {
  markAllOnboardingTasksComplete,
  OnboardingChecklistDoc,
} from "lib-frontend/utils/onboardingUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { CourseResponse, DemoVideoResponse } from "lib-fullstack/api/hubApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import {
  AudienceType,
  FeatureUpdateVisibility,
  LifecycleState,
  updateUserDocMain,
} from "lib-fullstack/db";
import {
  LandingPageExternalPath,
  WebServerExternalPath,
  getLandingPageExternalUrl,
} from "lib-fullstack/utils/paths";
import {
  HomePageChecklistEvents,
  WhatsNewAnnouncementType,
} from "lib-fullstack/utils/productAnalyticEvents";
import {
  MyLearningQueryParams,
  OrgInviteQueryParams,
  OrgContentQueryParams,
} from "lib-fullstack/utils/queryParams";
import { getSpeechThumbnails } from "lib-fullstack/utils/speechUtils";
import seedrandom from "seedrandom";
import { MOBILE_TOP_NAVBAR_HEIGHT } from "utils/Constants";
import { WebServerInternalPath } from "utils/paths";
import { getCoachTutorialSlug, isCoach, shouldShowCoachChecklist } from "utils/Utilities";
import { B2BNudgeHomepageBanner } from "./B2BNudgeHomepageBanner";
import { OrgSettingsTabs } from "lib-frontend/utils/orgUtils";
import { MANAGE_CONTENT_COURSES_SECTION_ID } from "components/Orgs/ManageContent/VideosAndCourses/CoursesSection";
import { FeatureUpdateBanner } from "components/FeatureUpdate/FeatureUpdateBanner";
import { ContentVideoState } from "lib-fullstack/utils/enums";
import { useFeatureUpdatesContext } from "contexts/FeatureUpdatesContext";

type FilteredFeaturedContent = db.SampleSpeech | db.SampleVideo;

export type BaseCarouselVideoContent = {
  data: {
    id: string;
    title: string;
    videoURL: string;
    thumbnailPath: string;
    audience: AudienceType;
  };
};

enum VideoSectionType {
  Courses = "courses",
  WelcomeVideos = "welcomeVideos",
}
const VideoSectionTitles = {
  [VideoSectionType.Courses]: "Courses",
  [VideoSectionType.WelcomeVideos]: "Welcome Videos",
};
type HomepageVideoSectionShown = VideoSectionType;
type BaseCarouselCourseContent = {
  data: {
    id: string;
    items: db.CourseListItem[];
    description: string;
    title: string;
    creator?: db.CourseCreator;
    thumbnail: string;
    slug?: string;
  };
};

export type CarouselContent =
  | db.Doc<db.Speech>
  | db.Doc<db.SampleVideo>
  | db.Doc<db.CourseInfo>
  | BaseCarouselVideoContent
  | BaseCarouselCourseContent;

export enum CarouselContentType {
  VIDEO = "video",
  SPEECH = "speech",
  FEATURED_COURSE = "featured_course",
  HUB_COURSE = "hub_course",
  PROGRAM = "program",
}

export enum HomePageEls {
  ZOODLI_CARD = "yoodli-for-your-team",
}

const HOME_PAGE_ITEM_PADDING = {
  pb: { xs: 4, md: 5 },
};

const NORMAL_DOT_COLOR = getDynamicColor("dark3");
const SELECTED_COLOR = getDynamicColor("purple3");
const ARROW_COLOR = getDynamicColor("dark3");

export const HOMEPAGE_SLIDER_STYLES: SxProps = {
  ".slick-slide": {
    height: "100%",
    outline: "none !important",
  },
  ".slick-list": {
    pb: 2,
    // target all child divs
    "& div": {
      outline: "none !important",
    },
  },
  ".slick-dots li": {
    margin: "0 2px 0 2px", // Spacing between dots
    top: 2,
    position: "relative",
  },
  ".slick-dots button": {
    p: "4px !important",
    borderRadius: "50%",
    transition: "all 0.15s ease",
  },
  ".slick-dots li button:before": {
    position: "relative",
    fontSize: "13px", // Dots size
    opacity: "1",
    color: NORMAL_DOT_COLOR,
    transition: "all 0.15s ease",
  },
  ".slick-dots li.slick-active button:before": {
    color: SELECTED_COLOR,
  },
  ".slick-dots li button:hover:before": {
    color: getDynamicColor("primary"),
  },
};

export const renderHomepageSliderDots = (
  dots: React.ReactNode,
  sliderRef: React.RefObject<Slider>
): React.ReactNode => (
  <Stack
    direction="row"
    gap={1}
    alignItems="center"
    justifyContent="center"
    sx={{
      button: {
        color: getDynamicColor("dark3"),
        opacity: 1,
        p: 0,
        transition: "all 0.15s ease",
        "&:hover": {
          color: getDynamicColor("dark5"),
        },
      },
    }}
  >
    <IconButton
      onClick={() => sliderRef.current?.slickPrev()}
      sx={{
        mr: 3,
        color: ARROW_COLOR,
      }}
    >
      <ChevronLeft />
    </IconButton>
    {dots}
    <IconButton onClick={() => sliderRef.current?.slickNext()} sx={{ ml: 3, color: ARROW_COLOR }}>
      <ChevronRight />
    </IconButton>
  </Stack>
);

const SAMPLE_VIDEOS_QUERY_KEY = "sampleVideosHomepageQueryKey";
const COURSES_QUERY_KEY = "coursesHomepageQueryKey";
const SAMPLE_SPEECHES_QUERY_KEY = "sampleSpeecheHomepageQueryKey";
const LATEST_SPEECHES_QUERY_KEY = "latestSpeechesHomepageQueryKey";

/**
 * Filter speeches by audience
 * Can't filter in useOnQuery because db.where and db.sort have to be on same key
 */
const fetchItemsByAudience = (
  items: db.Doc<FilteredFeaturedContent>[]
): db.Doc<FilteredFeaturedContent>[] => {
  const filterParameters: AudienceType[] = [AudienceType.ALL];

  if (!isToastmasters()) {
    if (isCoach()) {
      filterParameters.push(AudienceType.COACH);
    } else {
      filterParameters.push(AudienceType.USER);

      const userDocMain: db.UserDocMain = getLiveUserDocMain();
      const onboardingEventsAnswer: string = userDocMain.onboardingAnswers?.eventsFocus ?? "SPEECH";

      // Filter by specific OBQ1 end user parameters if there are videos specific to these types
      if (onboardingEventsAnswer) {
        switch (onboardingEventsAnswer) {
          case "SPEECH":
            filterParameters.push(AudienceType.SPEECH);
            break;
          case "EXPLORING":
            filterParameters.push(AudienceType.EXPLORING);
            break;
          case "INTERVIEW":
            filterParameters.push(AudienceType.INTERVIEW);
            break;
          default:
            filterParameters.push(AudienceType.SPEECH);
            break;
        }
      }
    }
  }

  const filteredItems: db.Doc<FilteredFeaturedContent>[] = items?.filter((i) =>
    filterParameters.includes(i.data.audience as AudienceType)
  );

  return filteredItems;
};

export default function Home(): JSX.Element {
  // #region State
  const isSmallScreen = useIsSmallScreen();
  const location = useLocation();
  const { defaultOrgPrimaryLogo } = React.useContext(UserOrgContext);
  const navigate = useNavigate();
  const qp = new URLSearchParams(location.search);

  const user = firebase.auth().currentUser;
  const userDoc = useUserDocsState();

  const [displayName, setDisplayName] = React.useState("");
  const [dailyStreak, setDailyStreak] = React.useState(undefined);
  const [showOnboardingChecklist, setShowOnboardingChecklist] = React.useState<boolean>(false);

  const [videoSectionShown, setVideoSectionShown] =
    React.useState<HomepageVideoSectionShown | null>(null);
  // #endregion

  const { lightbox, setLightboxURL } = videoLightbox(
    "",
    "",
    "",
    () => null,
    () => null,
    () => null,
    () => null,
    () => null,
    true
  );

  const zoodliCardScrollRef = React.useRef<HTMLDivElement>(null);
  const {
    orgModuleAccess: { zoodliForUsersEnabled },
    userInCoachOrganization,
    userInEnterpriseOrganization,
    userInOrg,
    defaultOrg,
    isOrgOwnerAdmin,
  } = React.useContext(UserOrgContext);

  const { featureUpdate, featureUpdateVisibility, handleCloseFeatureUpdate } =
    useFeatureUpdatesContext();

  // #region Effects
  React.useLayoutEffect(() => {
    if (qp.has(OrgInviteQueryParams.ACCEPT_INVITE)) {
      navigate(
        `${WebServerInternalPath.ACCEPT_INVITE}?${OrgInviteQueryParams.HUB_ID}=${qp.get(
          OrgInviteQueryParams.HUB_ID
        )}&${OrgInviteQueryParams.INVITE_ID}=${qp.get(OrgInviteQueryParams.INVITE_ID)}`,
        { replace: true }
      );
    }
  }, []);

  React.useEffect(() => {
    if (zoodliForUsersEnabled && location?.hash?.includes(HomePageEls.ZOODLI_CARD)) {
      zoodliCardScrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [zoodliCardScrollRef.current]);

  const coursesQuery = useApiQuery<db.Doc<db.CourseInfo>[]>({
    queryKey: [COURSES_QUERY_KEY, getSiteId()],
    queryFn: async () => {
      try {
        const courses = await db.all(db.courseList(getSiteId()));
        return courses;
      } catch (error) {
        console.error("Error fetching courses:", error);
        throw error;
      }
    },
  });

  const latestSpeechesQueryLimit = 25;

  const latestSpeechesQuery = useApiQuery<db.Doc<db.Speech>[]>({
    queryKey: [LATEST_SPEECHES_QUERY_KEY, getSiteId(), firebase.auth().currentUser?.uid],
    queryFn: async () => {
      const speeches = await db.query<db.Speech>(
        db.userSpeeches([getSiteId(), firebase.auth().currentUser?.uid]),
        [
          db.where("lifecycleState", "in", [LifecycleState.CREATED, LifecycleState.REDACTED]),
          db.order("recordedDate", "desc"),
        ]
      );

      return (speeches ?? [])
        .filter((dbSpeech) => !dbSpeech.data.usageDecremented)
        .slice(0, latestSpeechesQueryLimit);
    },
    enabled: !!firebase.auth().currentUser?.uid, // Only run query when we have a user ID
  });

  const programMemberViewQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.MemberView, { userId: user?.uid }],
    queryFn: () => listProgramsMemberView(),
    enabled: !!userInOrg,
    refetchOnWindowFocus: false,
  });

  // removing + completing checklist if more than 5 recorded speeches or if user is a program member
  React.useEffect(() => {
    if (
      (latestSpeechesQuery?.data?.length >= 5 || programMemberViewQuery.data?.length > 0) &&
      showOnboardingChecklist
    ) {
      setShowOnboardingChecklist(false);
      if (!onboardingChecklistCompleted) {
        markAllOnboardingTasksComplete().catch((er) => {
          console.error("failed to mark all onboarding tasks complete", er);
        });
      }
    }
  }, [programMemberViewQuery.data, latestSpeechesQuery?.data]);

  // Since we're using a limit query, we need to manually count the number of long speeches (>= 30 seconds).
  const numLongSpeeches = React.useMemo(() => {
    if (!latestSpeechesQuery?.data) {
      return 0;
    }
    const longSpeechCount = latestSpeechesQuery?.data?.filter(
      (speech) => speech.data.totalTimeS >= 30
    ).length;
    return longSpeechCount;
  }, [latestSpeechesQuery?.data]);

  const onboardingChecklistDoc = userDoc[db.UserDocTypes.MAIN]
    .onboardingChecklist as OnboardingChecklistDoc;
  const onboardingChecklistCompleted =
    !onboardingChecklistDoc || !!onboardingChecklistDoc?.completedTimestamp;

  const userDocPublic = userDoc[db.UserDocTypes.PUBLIC];
  const coachWelcomeSlug = getCoachTutorialSlug();
  const showCoachChecklist = shouldShowCoachChecklist();
  const anyProgramsActive =
    userInOrg && !programMemberViewQuery.isLoading && programMemberViewQuery?.data?.length > 0;
  // show the report card if we have any data to show, or if there's nothing else for us to show
  const showReportCard = numLongSpeeches > 0 || (!showOnboardingChecklist && !anyProgramsActive);

  React.useEffect(() => {
    const currentName = currentUserName();
    const firstName = currentUserFirstName();

    if (firstName) {
      setDisplayName(firstName);
    } else if (currentName) {
      setDisplayName(currentName);
    }

    Instrumentation.logAmplitudeEvent(HomePageChecklistEvents.VIEWED);
  }, []);

  React.useEffect(() => {
    if (user && userDocPublic) {
      const updatedDailyStreak = determineDailyStreak();
      setDailyStreak(updatedDailyStreak);
    }
  }, [userDocPublic]);

  React.useEffect(() => {
    if (onboardingChecklistCompleted) {
      // Wait 3 seconds so user can see checklist complete state first.
      setTimeout(() => {
        setShowOnboardingChecklist(false);
      }, 3000);
    } else if (onboardingChecklistDoc && !isToastmasters()) {
      setShowOnboardingChecklist(true);
    }
  }, [onboardingChecklistCompleted, !!onboardingChecklistDoc]);

  // #endregion

  const memberContentQuery = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY, defaultOrg?.id],
    queryFn: () => getOrgContentMemberViewV2(defaultOrg?.id),
    enabled: !!defaultOrg,
  });

  // #region Sample Speeches

  // Sample speeches have analytics
  const sampleSpeechesQuery = useApiQuery<db.Doc<db.Speech>[]>({
    queryKey: [SAMPLE_SPEECHES_QUERY_KEY, getSiteId()],
    queryFn: async () => {
      try {
        let speeches: db.Doc<db.Speech>[] = [];

        if (isToastmasters()) {
          // Get sample speeches and filter by audience
          const sampleSpeeches = await db.query<db.SampleSpeech>(db.sampleSpeeches(getSiteId()), [
            db.order("id", "asc"),
          ]);
          const filteredSpeeches = fetchItemsByAudience(
            sampleSpeeches
          ) as db.Doc<db.SampleSpeech>[];

          // Get auth headers for thumbnails
          const authHeaders = await AuthorizationAPIHeaders();

          // Map sample speeches to full speech objects with thumbnails
          speeches = await Promise.all(
            _.map(filteredSpeeches, async (sampleSpeechItem) => {
              const speech = await db.get(db.pathToRef<db.Speech>(sampleSpeechItem.data.path));
              if (speech) {
                const { userId, speechId } = db.pathToIds(sampleSpeechItem.data.path);
                const thumbnailsResult = await getSpeechThumbnails(
                  [
                    {
                      speechId: speechId,
                      ownerId: userId,
                    },
                  ],
                  authHeaders
                );
                speech.data.featuredSpeechThumbnail = thumbnailsResult[0];
              }
              return speech;
            })
          );

          // Filter out invalid speeches
          speeches = _.filter(
            speeches,
            (item) => !!item?.data && item.data.lifecycleState === LifecycleState.CREATED
          );
        }

        const userDocMain = getLiveUserDocMain();

        // Handle first visit vs returning visit
        if (!userDocMain.sampleSpeechesVisited && speeches.length) {
          await updateUserDocMain(getSiteId(), user.uid, { sampleSpeechesVisited: true });
          return speeches;
        } else {
          const isoDate = new Date().toISOString().slice(0, 10);
          seedrandom(isoDate, { global: true });
          return speeches.sort(() => Math.random() - 0.5);
        }
      } catch (error) {
        console.error(`Error fetching sample speeches: ${error}`);
        throw error;
      }
    },
  });

  const sampleVideosQuery = useApiQuery<db.Doc<db.SampleVideo>[]>({
    queryKey: [SAMPLE_VIDEOS_QUERY_KEY],
    queryFn: async () => {
      try {
        const videos = await db.query<db.SampleVideo>(db.sampleVideos(getSiteId()), [
          db.order("id", "asc"),
        ]);
        return fetchItemsByAudience(videos) as db.Doc<db.SampleVideo>[];
      } catch (error) {
        console.error(`Error fetching sample videos: ${error}`);
        throw error;
      }
    },
  });

  // #endregion

  // #region Video Mapping (Courses, Welcome Videos)

  const mapDemoVideosToCarouselContent = (
    demoVideos: DemoVideoResponse[]
  ): BaseCarouselVideoContent[] => {
    return demoVideos.map((video) => {
      return {
        data: {
          id: video.id,
          title: video.title,
          videoURL: video.view_url,
          thumbnailPath: video.thumbnail_url,
          audience: AudienceType.ALL,
        },
      };
    });
  };
  const mapCoursesToCarouselContent = (courses: CourseResponse[]): BaseCarouselCourseContent[] => {
    if (!courses) return [];
    return courses.map((course) => {
      return {
        data: {
          id: course.id,
          items: course.videos.map((video) => {
            return {
              title: video.title,
              description: video.description,
              videoURL: video.view_url,
              thumbnail: video.thumbnail_url,
            };
          }),
          description: course.description,
          title: course.title,

          thumbnail: course?.videos[0]?.thumbnail_url ?? null,
        },
      };
    });
  };

  // #endregion

  // #region Render

  const showOrgBrandLogo = isMobile && !!defaultOrgPrimaryLogo;
  const showDailyStreak = !showOnboardingChecklist && latestSpeechesQuery?.data?.length >= 3;

  const showRecentSessions = numLongSpeeches >= 3 && !isToastmasters();
  const showCoursesCarousel = !isToastmasters();

  const isContentBelowChecklist =
    showDailyStreak || showRecentSessions || showCoursesCarousel || showOnboardingChecklist;

  const pageContentTitle = () => {
    if (showOnboardingChecklist) {
      return isSmallScreen || !displayName
        ? "Finish Setting Up"
        : `Hi ${displayName}! Finish Setting Up`;
    } else {
      return displayName ? `Hi ${displayName}!` : "Hi!";
    }
  };

  // Don't mark loading if loading the course content, as that could
  // be slower & should not hang the entire page waiting for it.
  const loading =
    coursesQuery?.isLoading ||
    sampleSpeechesQuery?.isLoading ||
    sampleVideosQuery.isLoading ||
    programMemberViewQuery.isLoading;

  const renderToastmastersVideos = () => {
    if (!isToastmasters()) {
      return null;
    }
    return (
      <HomepageContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <ItemCarousel
          items={sampleVideosQuery?.data}
          title="Tutorials"
          type={CarouselContentType.VIDEO}
          setLightboxURL={setLightboxURL}
          showCoachChecklist={showCoachChecklist}
          coachWelcomeSlug={coachWelcomeSlug}
        />
        <ItemCarousel
          items={sampleSpeechesQuery?.data}
          title="Today's featured speeches"
          type={CarouselContentType.SPEECH}
          showCoachChecklist={showCoachChecklist}
          coachWelcomeSlug={coachWelcomeSlug}
        />
      </HomepageContainer>
    );
  };

  const renderVideoSectionCard = (
    videos: DemoVideoResponse[] | CourseResponse["videos"],
    section: HomepageVideoSectionShown,
    length: number,
    title: string
  ) => {
    return (
      <Box
        onClick={() => setVideoSectionShown(videoSectionShownDelayed === section ? null : section)}
        sx={{
          flexBasis: "50%",
          minHeight: { xs: 120, sm: 200, md: 220 },
          borderRadius: "12px",
          overflow: "hidden",
          cursor: "pointer",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          transition: "opacity 0.3s ease",
          backgroundImage: `url(${videos[0]?.thumbnail_url})`,
          opacity: !videoSectionShown || videoSectionShown === section ? 1 : 0.5,
          p: {
            userSelect: "none",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              opacity: !videoSectionShown || videoSectionShown !== section ? 1 : 0,
              transition: "opacity 0.3s ease",
              background: `linear-gradient(to bottom, transparent 0%, ${getDynamicColor(
                "black",
                0.65
              )} 100%)`,
            }}
          />
          <Stack
            sx={{
              position: "absolute",
              left: 16,
              bottom: 12,
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <OndemandVideoIcon sx={{ fontSize: 16, color: getDynamicColor("light1") }} />
              <Typography sx={{ fontSize: { xs: 12, md: 14 }, fontWeight: 500 }}>
                {length} {section === VideoSectionType.Courses ? "Course" : "Video"}
                {length === 1 ? "" : "s"}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  };

  const [videoSectionShownDelayed, setVideoSectionShownDelayed] =
    React.useState<HomepageVideoSectionShown | null>(null);
  const prevVideoSectionShown = usePrevious(videoSectionShown);
  const videoCarouselAnimationMs = 300;
  React.useEffect(() => {
    if (prevVideoSectionShown && !videoSectionShown) {
      // delay the state change here so the drawer finishes closing before it changes
      setTimeout(() => {
        setVideoSectionShownDelayed(null);
      }, videoCarouselAnimationMs);
    } else {
      setVideoSectionShownDelayed(videoSectionShown);
    }
  }, [videoSectionShown]);
  const coursesToShow = React.useMemo(() => {
    let courses = memberContentQuery?.data?.courses;
    if (!userInOrg && coursesQuery.data?.length) {
      courses = coursesQuery.data.map((course) => ({
        id: course.data.id || course.data.slug,
        title: course.data.title,
        description: course.data.description,
        available_hubs: [], // Since this is a non-hub course
        videos: course.data.items.map((item) => ({
          id: item.title,
          title: item.title,
          description: item.description,
          state: ContentVideoState.READY,
          view_url: item.videoURL,
          thumbnail_url: course.data.thumbnail,
        })),
      }));
    }
    return courses;
  }, [userInOrg, memberContentQuery?.data?.courses, coursesQuery.data]);
  const getCarouselProps = React.useCallback(
    (hasCourseHubVideos: boolean, videoSection: HomepageVideoSectionShown): ItemCarouselProps => {
      const titleCta = {
        copy: "See more",
        onClick: () =>
          navigate({
            pathname: WebServerExternalPath.MY_LEARNING,
            search: new URLSearchParams({
              [MyLearningQueryParams.TAB]: MyLearningTabs.Courses,
            }).toString(),
            hash:
              videoSection === VideoSectionType.Courses
                ? MANAGE_CONTENT_COURSES_SECTION_ID
                : undefined,
          }),
      };
      const titleSx = {
        fontSize: { xs: 14, md: 16 },
      };
      if (videoSection === VideoSectionType.WelcomeVideos) {
        return {
          items: mapDemoVideosToCarouselContent(memberContentQuery?.data?.demo_videos),
          title: VideoSectionTitles[videoSection],
          titleSx,
          titleCta,
          type: CarouselContentType.VIDEO,
          setLightboxURL,
          loadingContent: memberContentQuery.isLoading,
          showCoachChecklist,
          coachWelcomeSlug,
        };
      }
      return {
        items: mapCoursesToCarouselContent(coursesToShow),
        title: VideoSectionTitles[videoSection],
        titleSx,
        titleCta,
        // only show the "view in settings" CTA if the user is an owner/admin of the hub
        type: CarouselContentType[
          memberContentQuery?.data?.courses.length ? "HUB_COURSE" : "FEATURED_COURSE"
        ],
        itemOnClick: (courseId: string) => {
          if (hasCourseHubVideos) {
            navigate({
              pathname: WebServerExternalPath.MY_LEARNING,
              search: new URLSearchParams({
                [MyLearningQueryParams.TAB]: MyLearningTabs.Courses,
              }).toString(),
              hash: `course-${courseId}`,
            });
          } else {
            window.location.href = `${getLandingPageExternalUrl(
              getClientEnvConfig(),
              LandingPageExternalPath.COURSES
            )}/${courseId}`;
          }
        },
        loadingContent: coursesQuery.isLoading,
      };
    },
    [coursesToShow, videoSectionShownDelayed]
  );

  const findFirstValidCourseVideos = (courses: CourseResponse[]) => {
    return courses?.find((course) => course.videos?.[0])?.videos;
  };

  const renderCoursesAndWelcomeVideos = () => {
    // hide while loading
    if (isToastmasters() || memberContentQuery.isLoading) {
      return null;
    }
    const hasCourseHubVideos = memberContentQuery.data?.courses?.some(
      (course) => course.videos?.length > 0
    );
    // always hide Yoodli courses for coaching or ENT organizations
    const showCourses =
      (!(userInCoachOrganization || userInEnterpriseOrganization) && hasCourseHubVideos) ||
      (!userInOrg && coursesQuery.data?.length);
    const hasWelcomeVideos = memberContentQuery.data?.demo_videos?.length > 0;
    const showWelcomeVideos = !showOnboardingChecklist && hasWelcomeVideos && !isToastmasters();

    const showContent = showCourses || showWelcomeVideos;
    if (!showContent) {
      return null;
    }

    const carouselProps = getCarouselProps(hasCourseHubVideos, videoSectionShownDelayed);

    return (
      <Stack gap={2} sx={{ ...HOME_PAGE_ITEM_PADDING, pb: { xs: 3, md: 4 }, minHeight: 200 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="poppins"
            component="h2"
            sx={{
              fontSize: { xs: 18, sm: 20 },
              fontWeight: 600,
            }}
          >
            Videos
          </Typography>
          {(hasCourseHubVideos || hasWelcomeVideos) && isOrgOwnerAdmin && (
            <Button
              variant="text"
              onClick={() =>
                navigate({
                  pathname: WebServerInternalPath.ORG_CONTENT,
                  search: new URLSearchParams({
                    [OrgContentQueryParams.TAB]: OrgSettingsTabs.VIDEOS_COURSES,
                  }).toString(),
                })
              }
              sx={{
                fontSize: 14,
              }}
            >
              Manage content
            </Button>
          )}
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={2}
          sx={{
            color: getDynamicColor("light1"),
            fontFamily: "poppins",
          }}
        >
          {findFirstValidCourseVideos(coursesToShow) &&
            renderVideoSectionCard(
              findFirstValidCourseVideos(coursesToShow),
              VideoSectionType.Courses,
              coursesToShow.length,
              VideoSectionTitles[VideoSectionType.Courses]
            )}
          {hasWelcomeVideos &&
            renderVideoSectionCard(
              memberContentQuery.data?.demo_videos,
              VideoSectionType.WelcomeVideos,
              memberContentQuery.data?.demo_videos.length,
              VideoSectionTitles[VideoSectionType.WelcomeVideos]
            )}
        </Stack>
        {carouselProps && (
          <Collapse timeout={videoCarouselAnimationMs} in={!!videoSectionShown} appear>
            <span>
              <ItemCarousel {...carouselProps} />
            </span>
          </Collapse>
        )}
      </Stack>
    );
  };

  const siteConfig = getStaticFullSiteConf();

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      height={`calc(100vh - ${isSmallScreen ? MOBILE_TOP_NAVBAR_HEIGHT : "0px"})`}
      alignItems="center"
    >
      <CircularProgress size={100} />
    </Box>
  ) : (
    <>
      {isToastmasters() && (
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: siteConfig?.colors?.primary ?? getDynamicColor("purple3"),
            px: 2,
            py: 1,
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              color: getDynamicColor("light1"),
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            Looking for advanced AI feedback? Visit{" "}
            <Link
              sx={{
                fontWeight: 700,
                color: getDynamicColor("bannerLinkColor"),
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              href="https://www.yoodli.ai"
              target="_blank"
            >
              www.yoodli.ai
            </Link>
          </Typography>
        </Stack>
      )}
      <Grid
        container
        spacing={0}
        sx={{
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          sm={12}
          sx={{
            backgroundColor: getDynamicColor("light1"),
            width: "100%",
            pb: 8,
          }}
        >
          {showOnboardingChecklist && onboardingChecklistDoc && (
            <Stack
              sx={{
                background: getDynamicColor("gradient.slantedLight"),
                height: isContentBelowChecklist ? "fit-content" : "100%",
                mb: isContentBelowChecklist ? 1 : 0,
              }}
            >
              {showOrgBrandLogo && (
                <img
                  src={defaultOrgPrimaryLogo}
                  style={{
                    maxWidth: "287px",
                    maxHeight: "88px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                />
              )}
              <Checklist
                data-logrocket-hidden
                displayName={displayName}
                onboardingChecklistDoc={onboardingChecklistDoc}
              />
            </Stack>
          )}
          {(!showOnboardingChecklist || showDailyStreak || showOrgBrandLogo) && (
            <HomepageContainer
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: { xs: "flex-start", md: "center" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              {!showOnboardingChecklist && (
                <Typography
                  data-logrocket-hidden
                  sx={{ fontWeight: 700, fontSize: 24, fontFamily: "poppins" }}
                >
                  {pageContentTitle()}
                </Typography>
              )}
              {showDailyStreak && (
                <Stack direction={{ xs: "column", md: "row" }} alignItems="flex-end">
                  <Tooltip
                    title={isMobile ? "" : "Use Yoodli everyday to build your streak!"}
                    placement="bottom-end"
                  >
                    <Stack
                      direction="row"
                      gap={0.5}
                      alignItems="center"
                      sx={{
                        cursor: "default",
                        ml: { xs: 0, sm: "auto" },
                        mr: { xs: 0, sm: 2 },
                        py: 0.5,
                      }}
                    >
                      <Typography sx={{ fontFamily: "poppins", fontSize: 14, fontWeight: 700 }}>
                        {dailyStreak} Day Streak
                      </Typography>
                      <BoltIcon
                        style={{
                          height: 22,
                          width: 22,
                        }}
                      />
                    </Stack>
                  </Tooltip>
                </Stack>
              )}

              {showOrgBrandLogo && (
                <img
                  src={defaultOrgPrimaryLogo}
                  style={{
                    maxWidth: "287px",
                    maxHeight: "88px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                />
              )}
            </HomepageContainer>
          )}
          {renderToastmastersVideos()}
          {/* dont show b2b nudge or programs carousel for TMI */}
          {!isToastmasters() && (
            <>
              <HomepageContainer
                sx={{
                  my: 0,
                  py: 0,
                }}
              >
                <B2BNudgeHomepageBanner sx={{ mb: 3, mt: 2 }} />
              </HomepageContainer>
              <HomepageContainer>
                <ProgramsCarousel programMemberViewQuery={programMemberViewQuery} />
              </HomepageContainer>
            </>
          )}
          <Collapse
            in={!!featureUpdate && featureUpdateVisibility !== FeatureUpdateVisibility.Hide}
            unmountOnExit
            appear
          >
            <span>
              <HomepageContainer>
                <FeatureUpdateBanner
                  featureUpdate={featureUpdate}
                  visibility={featureUpdateVisibility}
                  onClose={() => handleCloseFeatureUpdate(WhatsNewAnnouncementType.USER)}
                  type={WhatsNewAnnouncementType.USER}
                />
              </HomepageContainer>
            </span>
          </Collapse>
          {showReportCard && (
            <div
              style={{
                paddingBottom: "16px",
              }}
            >
              <ReportCard
                latestSpeeches={latestSpeechesQuery?.data ?? []}
                setLightboxURL={setLightboxURL}
                userInCoachOrg={userInCoachOrganization}
                userInEnterpriseOrg={userInEnterpriseOrganization}
                hubContentLoadingComplete={!memberContentQuery.isPending}
              />
            </div>
          )}
          {(showRecentSessions ||
            isToastmasters() ||
            showCoursesCarousel ||
            !showOnboardingChecklist) && (
            <HomepageContainer>
              {showRecentSessions && (
                <Box sx={HOME_PAGE_ITEM_PADDING}>
                  <RecentSessions latestSpeeches={latestSpeechesQuery?.data} />
                </Box>
              )}
              {renderCoursesAndWelcomeVideos()}

              {/* Always show zoodli card */}
            </HomepageContainer>
          )}
          {zoodliForUsersEnabled && (
            <HomepageContainer
              sx={{
                pt: isContentBelowChecklist ? 0 : 2,
              }}
            >
              <ZoodliCard />
            </HomepageContainer>
          )}
          {lightbox}
        </Grid>
      </Grid>
    </>
  );

  // #endregion
}

const HomepageContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
  return (
    <Container
      maxWidth="lg"
      className="homepage-"
      sx={{
        position: "relative",
        px: { xs: "16px !important", md: "32px !important" },
        mt: 2,
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
