import { db } from "lib-fullstack";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

// Components
import { Typography, Stack, Grid, Button, Skeleton } from "@mui/material";
import {
  DayRange,
  ReportCardFilterDropdown,
} from "components/Home/ReportCard/ReportCardFilterDropdown";
import { ReportCardItem } from "components/Home/ReportCard/ReportCardItem";

// Assets
import { ReactComponent as GreenThumbsUp } from "images/graphics/green-thumbs-up.svg";
import { ReactComponent as RedFlag } from "images/graphics/red-flag.svg";
import { ReactComponent as StreakThumbsUp } from "images/graphics/streak-thumbs-up.svg";

// Utils
import {
  AnalyticToCardText,
  determineReportCardResults,
  FormattedReportCardResult,
} from "./ReportCardCopyLogic";
import { requestReportCard } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ReportCardResponse } from "lib-fullstack/api/apiTypes";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { HomePageReportCardEvents } from "lib-fullstack/utils/productAnalyticEvents";

type ReportCardProps = {
  latestSpeeches: db.Doc<db.Speech>[];
  setLightboxURL: (url: string, title: string) => void;
  userInCoachOrg: boolean;
  userInEnterpriseOrg: boolean;
  hubContentLoadingComplete: boolean;
};

export const ReportCard = ({
  latestSpeeches,
  setLightboxURL,
  userInCoachOrg,
  userInEnterpriseOrg,
  hubContentLoadingComplete,
}: ReportCardProps): JSX.Element => {
  const navigate = useNavigate();

  const [dayRange, setDayRange] = React.useState<DayRange>(undefined);
  const [results, setResults] = React.useState<FormattedReportCardResult[]>(undefined);

  React.useEffect(() => {
    const dateNow = new Date();
    const today = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
    const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

    const speechesToday = latestSpeeches?.filter(
      (speech: db.Doc<db.Speech>) => new Date(speech.data.recordingStartDate) >= today
    );
    const speechesLast7Days = latestSpeeches?.filter(
      (speech) => new Date(speech.data.recordingStartDate) >= sevenDaysAgo
    );
    const speechesLast30Days = latestSpeeches?.filter(
      (speech) => new Date(speech.data.recordingStartDate) >= thirtyDaysAgo
    );

    if (speechesToday.length > 0) {
      if (speechesLast7Days.length > speechesToday.length) {
        setDayRange(DayRange.SevenDays);
      } else {
        setDayRange(DayRange.Today);
      }
    } else if (speechesLast7Days.length > 0) {
      setDayRange(DayRange.SevenDays);
    } else if (speechesLast30Days.length > 0) {
      setDayRange(DayRange.ThirtyDays);
    } else {
      setDayRange(DayRange.NinetyDays);
    }
  }, [latestSpeeches]);

  const determineRangeFromDayRange = (dayRange: string): [string, string] => {
    if (dayRange === DayRange.Today) {
      const today = new Date();
      const midnightThisMorning = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      return [midnightThisMorning.toISOString(), today.toISOString()];
    } else if (dayRange === DayRange.SevenDays) {
      const today = new Date();
      const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      return [sevenDaysAgo.toISOString(), today.toISOString()];
    } else if (dayRange === DayRange.ThirtyDays) {
      const today = new Date();
      const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
      return [thirtyDaysAgo.toISOString(), today.toISOString()];
    } else {
      const today = new Date();
      const ninetyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90);
      return [ninetyDaysAgo.toISOString(), today.toISOString()];
    }
  };
  React.useEffect(() => {
    const fetchData = async () => {
      const [startDate, endDate] = determineRangeFromDayRange(dayRange);
      setResults(undefined); // undefined is loading state.
      const response = await requestReportCard(startDate, endDate);
      if (response && Object.keys(response).length > 0) {
        const [positiveResults, negativeResults] = determineReportCardResults(
          response as ReportCardResponse,
          dayRange
        );
        const totalResults = positiveResults.concat(negativeResults);
        setResults(totalResults);
      } else {
        setResults([]);
      }
    };

    if (dayRange) {
      fetchData().catch((err) => {
        setResults([]);
        console.error(`Error fetching report card: ${err}`);
      });
    }
  }, [dayRange]);

  const emptyStatePracticeNowButtonClicked = () => {
    Instrumentation.logAmplitudeEvent(
      HomePageReportCardEvents.REPORT_CARD_EMPTY_PRACTICE_BUTTON_CLICKED
    );
    navigate(WebServerExternalPath.PRACTICE_SPEECH);
  };

  const renderIcon = (isPositive: boolean, isOccurrenceCount: boolean) => {
    if (isOccurrenceCount) {
      return <StreakThumbsUp />;
    }
    if (isPositive) {
      return <GreenThumbsUp />;
    } else {
      return <RedFlag />;
    }
  };

  return (
    <Stack
      sx={{ backgroundColor: getDynamicColor("purple1", 0.4), p: { xs: 3, md: 4 }, minHeight: 300 }}
    >
      <Stack gap={{ xs: 3, md: 5 }} sx={{ maxWidth: 900, mx: "auto", width: "fill-available" }}>
        <Stack
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}
          gap={2}
        >
          <Stack direction="row" sx={{ alignItems: "center" }} gap={1.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontFamily: "poppins",
                fontSize: 14,
                color: getDynamicColor("purple3"),
              }}
            >
              Your coaching report from the last
            </Typography>
            {dayRange && (
              <ReportCardFilterDropdown
                selectedValue={dayRange}
                handleSetSelectedValue={setDayRange}
              />
            )}
          </Stack>

          <Link to={WebServerExternalPath.DASHBOARD}>
            <Button
              variant="text"
              sx={{
                fontSize: 14,
                textDecoration: "none",
                color: getDynamicColor("primary"),
                whiteSpace: "nowrap",
              }}
            >
              See dashboard
            </Button>
          </Link>
        </Stack>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          sx={{
            maxWidth: 1200,
            mx: { sm: "auto" },
            position: "relative",
            left: { xs: 0, sm: -24 },
          }}
        >
          {results?.length > 0 &&
            results.map((result) => (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  maxWidth: { xs: "none", md: 460 },
                }}
                key={result.description}
              >
                <ReportCardItem
                  icon={renderIcon(result.isPositive, result.isOccurrenceCount)}
                  analytic={result.analytic as db.AggregateAnalyticEnum}
                  description={result.description}
                  learningResourceButtonCopy={
                    hubContentLoadingComplete &&
                    !result.isPositive &&
                    !userInCoachOrg &&
                    !userInEnterpriseOrg &&
                    AnalyticToCardText[result.analytic].learningResourceButtonCopy
                  }
                  setLightboxURL={setLightboxURL}
                />
              </Grid>
            ))}
          {results === undefined &&
            [1, 2, 3, 4].map((r) => (
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  maxWidth: { xs: "none", md: 460 },
                }}
                key={r}
              >
                <Skeleton sx={{ transform: "none" }} animation="wave" height="90px" width="100%" />
              </Grid>
            ))}
        </Grid>
        {results?.length === 0 && (
          <Stack sx={{ textAlign: "center", alignItems: "center", width: "100%", py: 6 }} gap={2}>
            <Typography sx={{ fontFamily: "poppins", fontWeight: 700 }}>No data yet!</Typography>
            <Typography sx={{ fontFamily: "poppins", maxWidth: 650, fontSize: 12 }}>
              We don’t have enough data to show coaching feedback from the past {dayRange}. Keep
              practicing and check back later!
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 1, fontSize: 14 }}
              onClick={emptyStatePracticeNowButtonClicked}
            >
              Practice now
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
