import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// Utils
import qs from "qs";

// TODO: This currently uses window.location instead of useLocation() which was not
// updating properly, this resulted in queryParams being wrongly overridden. Michael to
// investigate further.
export const useQueryParamState = (
  query: string,
  defaultQueryVal?: string
): [string, (qParam: string) => void] => {
  const navigate = useNavigate();

  const setQuery = useCallback(
    (value) => {
      const existingQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      // Don't update the query params in the url if we're setting it to the default
      const queryString = qs.stringify(
        { ...existingQueries, [query]: value !== defaultQueryVal ? value : null },
        { skipNulls: true }
      );

      const basePath = window.location.pathname.split("?")[0];
      const newUrl = `${basePath}${queryString ? `?${queryString}` : ""}`;

      // First update the URL using history API
      window.history.replaceState({}, "", newUrl);

      // Then force a router update using navigate with replace
      navigate(newUrl, { replace: true });
    },
    [navigate, query, defaultQueryVal]
  );

  // If there's no query parameter set, set it to the default value
  return [
    qs.parse(window.location.search, { ignoreQueryPrefix: true })[query]?.toString() ??
      defaultQueryVal,
    setQuery,
  ];
};
