import React from "react";

// Components
import { Close as CloseIcon } from "@mui/icons-material";
import { Card, Stack, IconButton, Typography, Button, SxProps, Collapse } from "@mui/material";

// Assets
import { ReactComponent as GroupsIcon } from "images/icons/groups-gradient.svg";

// Utils
import { useShowB2BNudgeBanner } from "hooks/useShowB2BNudgeBanner";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

export const B2BNudgeHomepageBanner = ({ sx }: { sx?: SxProps }): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const { showBanner, talkToSalesPressed, onTalkToSalesPressed, onDismiss } =
    useShowB2BNudgeBanner();

  return (
    <Collapse in={showBanner} unmountOnExit enter={false} appear={false}>
      <Card
        sx={{
          fontFamily: "poppins",
          p: { xs: 2, md: 3 },
          borderRadius: "12px",
          border: `1px solid ${getDynamicColor("purple2")}`,
          boxShadow: Y_SHADOWS.box_shadow_1,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1.5, md: 3 },
          alignItems: "center",
          fontSize: 14,
          position: "relative",
          minHeight: { xs: 108, sm: "unset" },
          ...sx,
        }}
      >
        {!isSmallScreen && (
          <GroupsIcon
            style={{
              minHeight: 30,
              minWidth: 30,
            }}
          />
        )}
        <Stack
          sx={{
            flewGrow: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              pr: { xs: 2, sm: "unset" },
            }}
          >
            {talkToSalesPressed
              ? "Someone will be in touch soon  ✔️"
              : "Bring Yoodli to your team!"}
          </Typography>
          <Typography>
            {talkToSalesPressed
              ? "We’ll reach you at the email associated with this account. Looking forward to chatting!"
              : "Click “Talk to sales” and someone from our team will reach out to you"}
          </Typography>
        </Stack>
        <Stack
          gap={{ xs: 2, md: 3 }}
          direction="row"
          sx={{
            ml: "auto",
            mr: { xs: "auto", sm: "unset" },
          }}
        >
          {!talkToSalesPressed && (
            <Button
              variant="gradient"
              onClick={() => onTalkToSalesPressed("homepage")}
              sx={{
                fontSize: 14,
                whiteSpace: "nowrap",
                mr: { xs: 0, sm: 3, md: 5 },
                height: 40,
              }}
            >
              Talk to sales 💬
            </Button>
          )}
          <IconButton
            onClick={onDismiss}
            sx={{
              alignSelf: "flex-start",
              position: "absolute",
              top: 12,
              right: 12,
              p: 0.5,
            }}
          >
            <CloseIcon
              sx={{
                color: getDynamicColor("primary"),
                stroke: getDynamicColor("primary"),
                strokeWidth: 1,
                height: 18,
                width: 18,
              }}
            />
          </IconButton>
        </Stack>
      </Card>
    </Collapse>
  );
};
