import { Stack, Typography } from "@mui/material";

import { SECTION_TITLE_STYLES } from "../MemberDashboard";

export const WidgetWrapper = ({
  children,
  title,
  cta,
}: {
  title: string;
  cta?: React.ReactNode;
} & React.PropsWithChildren): JSX.Element => {
  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={SECTION_TITLE_STYLES}>{title}</Typography>
        {cta}
      </Stack>
      {children}
    </Stack>
  );
};
