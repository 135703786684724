import React from "react";
import { useLocation, useNavigate } from "react-router";

// Components
import { Stack, Button } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgSettingsQueryParams } from "lib-fullstack/utils/queryParams";
import { OrgSettingsTabLabel, OrgSettingsTabs } from "lib-frontend/utils/orgUtils";

type OrgSettingsTabsProps = {
  selectedTab: OrgSettingsTabs;
  setSelectedTab: (tab: OrgSettingsTabs) => void;
  shouldBlockNav: boolean;
  setShouldBlockNav: (val: boolean) => void;
  coachBotEnabled?: boolean;
};

export default function OrgManageContentTabs({
  selectedTab,
  setSelectedTab,
  shouldBlockNav,
  setShouldBlockNav,
  coachBotEnabled,
}: OrgSettingsTabsProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const qp = new URLSearchParams(location.search);

  const handleTabChange = (tab: OrgSettingsTabs) => {
    if (
      !shouldBlockNav ||
      (shouldBlockNav &&
        window.confirm("Are you sure you want to change tabs? your changes might not be saved!"))
    ) {
      setSelectedTab(tab);
      setShouldBlockNav(false);
      qp.set(OrgSettingsQueryParams.TAB, tab);
      navigate({ search: qp.toString() });
    }
  };

  return (
    <Stack
      direction="row"
      gap={{ xs: 2, sm: 3, md: 4 }}
      px={{ xs: 2, md: 3, lg: 4, xl: 5 }}
      sx={{
        width: "100%",
        backgroundColor: getDynamicColor("purple3"),
        overflowX: "auto",
      }}
    >
      {Object.entries(OrgSettingsTabs).map(([tabKey, tabValue]) => {
        if (tabValue === OrgSettingsTabs.COACHBOT && !coachBotEnabled) {
          return null;
        }
        return (
          <Button
            key={tabKey}
            onClick={() => handleTabChange(tabValue)}
            sx={{
              py: 0.5,
              px: 1,
              textAlign: "left",
              color: getDynamicColor("light1"),
              borderRadius: 0,
              borderBottom: "3px solid",
              borderColor:
                selectedTab === OrgSettingsTabs[tabKey] ? getDynamicColor("light1") : "transparent",
              whiteSpace: "nowrap",
              fontWeight: 400,
              fontFamily: "Poppins",
              transition: "none",
              fontSize: 14,
              // prevent changing the fontWeight from causing the button width to change, shifting it's neighbors position
              "&::after": {
                position: "absolute",
                display: "block",
                content: `"${OrgSettingsTabLabel[tabKey]}"`,
                fontWeight: 500,
                height: 1,
                py: 0.5,
                px: 1,
                color: getDynamicColor("light1"),
                overflow: "hidden",
                visibility: selectedTab === OrgSettingsTabs[tabKey] ? "visible" : "hidden",
              },
            }}
          >
            {OrgSettingsTabLabel[tabKey]}
          </Button>
        );
      })}
    </Stack>
  );
}
