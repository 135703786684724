import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { parseHubRole } from "lib-frontend/utils/orgUtils";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { WebServerInternalPath } from "utils/paths";

type MembershipSettingsProps = {
  user: OrgMemberResponse;
};

const SHARED_STYLES = {
  display: "flex",
  flexBasis: "50%",
};
const HEADER_STYLES = {
  ...SHARED_STYLES,
  fontWeight: 700,
  fontSize: 12,
  color: getDynamicColor("dark5"),
};
const ROW_STYLES = {
  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
  px: 3,
  py: 1.5,
};

export const MembershipSettings = ({ user }: MembershipSettingsProps): JSX.Element => {
  return (
    <Box
      sx={{
        border: `1px solid ${getDynamicColor("dark2")}`,
        borderRadius: "4px",
      }}
    >
      <Stack direction="row" sx={{ ...ROW_STYLES, py: 2 }}>
        <Typography sx={HEADER_STYLES}>Groups</Typography>

        <Typography sx={HEADER_STYLES}>Roles</Typography>
      </Stack>
      {user?.hubs?.map((hub, i) => (
        <Stack
          key={hub.hub_id}
          direction="row"
          sx={{
            ...ROW_STYLES,
            borderBottom: i === user?.hubs?.length - 1 ? "none" : ROW_STYLES.borderBottom,
          }}
        >
          <Typography
            sx={{
              ...SHARED_STYLES,
              a: {
                fontWeight: 600,
                fontSize: 14,
                color: getDynamicColor("purple3"),
                textDecoration: "none",
              },
            }}
          >
            <Link to={`${WebServerInternalPath.ORG_GROUPS}?groupId=${hub.hub_id}`}>{hub.name}</Link>
          </Typography>
          <Typography
            sx={{
              ...SHARED_STYLES,
              fontWeight: 400,
              fontSize: 12,
              color: getDynamicColor("purple3"),
            }}
          >
            {parseHubRole(hub.role)}
          </Typography>
        </Stack>
      ))}
    </Box>
  );
};
