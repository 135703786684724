import React from "react";

// Components
import {
  InfoOutlined as InfoIcon,
  WarningAmberRounded as WarningAmberRoundedIcon,
} from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grow, LinearProgress, Stack, Typography } from "@mui/material";
import { EmailBank } from "components/Orgs/BulkInviteByCSVWizard/EmailBank";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";

// Utils
import { HubsListSpan } from "../HubsListSpan";
import { BulkInviteRoleOption } from "./BulkInviteByCSVWizard";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type SendInvitationsProps = {
  selectedHubs: HubV2Response[];
  selectedRole: BulkInviteRoleOption;
  emails: string[];
  error: string;
  sendEmailInvite: boolean;
  setSendEmailInvite: (sendEmailInvite: boolean) => void;
  pctInvitesSent: number;
};

const BOLD_STYLES = {
  fontWeight: 700,
  color: getDynamicColor("primary"),
};

export const SendInvitations = ({
  selectedHubs,
  selectedRole,
  emails,
  error,
  sendEmailInvite,
  setSendEmailInvite,
  pctInvitesSent,
}: SendInvitationsProps): JSX.Element => {
  return (
    <Stack gap={{ xs: 2, md: 3 }}>
      <Stack
        gap={1}
        sx={{
          fontFamily: "poppins",
          maxWidth: 600,
        }}
      >
        <WizardTitle
          icon={
            error && (
              <WarningAmberRoundedIcon
                sx={{
                  color: getDynamicColor("redError"),
                  height: 20,
                  width: 20,
                }}
              />
            )
          }
        >
          Review and send invitations
        </WizardTitle>
        {error ? (
          <Typography color={getDynamicColor("redError")} fontSize="14px" fontWeight={700}>
            {error}
          </Typography>
        ) : (
          <WizardSubTitle>
            You're inviting <span style={BOLD_STYLES}>{emails.length}</span> user
            {emails.length === 1 ? "" : "s"} as{" "}
            <span style={BOLD_STYLES}>
              {selectedRole.label}
              {emails.length === 1 ? "" : "s"}
            </span>{" "}
            to the <HubsListSpan hubsList={selectedHubs.map((hub) => hub.name)} /> group
            {selectedHubs.length === 1 ? "" : "s"}. If everything looks good, hit send!
          </WizardSubTitle>
        )}
      </Stack>
      {!error && (
        <Grow unmountOnExit in={pctInvitesSent > 0}>
          <LinearProgress value={pctInvitesSent} variant="determinate" />
        </Grow>
      )}
      <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={sendEmailInvite}
              onChange={(e) => setSendEmailInvite(e.target.checked)}
            />
          }
          label={
            <Stack direction="row" sx={{ gap: 1, alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: 12, md: 13 },
                  fontWeight: 600,
                  fontFamily: "poppins",
                  lineHeight: 1.4,
                  color: getDynamicColor("dark5"),
                  textAlign: "left",
                }}
              >
                Send invitations to new users via email
              </Typography>
              <YoodliTooltip
                placement="bottom-start"
                title="Send invitations to new users via email. (Existing users will always receive an email notification)"
              >
                <InfoIcon
                  sx={{
                    height: 20,
                    width: 20,
                    color: getDynamicColor("purple3"),
                  }}
                />
              </YoodliTooltip>
            </Stack>
          }
        />
      </Stack>
      <EmailBank emails={emails} title="Email addresses" isError={!!error} />
    </Stack>
  );
};
