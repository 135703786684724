import React from "react";
import parse from "html-react-parser";

import Slider from "react-slick";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Close as CloseIcon, ChevronRight, ChevronLeft } from "@mui/icons-material";
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";

import { FeatureUpdateResponse } from "lib-frontend/api/wordpress";
import {
  WhatsNewAnnouncementEvents,
  WhatsNewAnnouncementType,
} from "lib-fullstack/utils/productAnalyticEvents";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import {
  splitContentIntoArticles,
  DEFAULT_SLIDER_SETTINGS,
  findAndStripImgTags,
} from "./FeatureUpdateModal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SLICK_DOTS_STYLES } from "utils/reactSlickUtils";
import { FeatureUpdateVisibility } from "lib-fullstack/db";
import { Blobs } from "lib-frontend/components/Blobs/Blobs";

export const FeatureUpdateBanner = ({
  featureUpdate,
  visibility,
  onClose,
  type,
}: {
  featureUpdate: FeatureUpdateResponse;
  onClose: () => Promise<void>;
  type: WhatsNewAnnouncementType;
  visibility?: FeatureUpdateVisibility;
}): JSX.Element => {
  const sliderRef = React.useRef<Slider>(null);

  React.useEffect(() => {
    if (visibility === FeatureUpdateVisibility.Banner) {
      Instrumentation.logAmplitudeEvent(WhatsNewAnnouncementEvents.BANNER_VIEWED, {
        type,
      });
    }
  }, [visibility]);

  const updates = React.useMemo(
    () => splitContentIntoArticles(featureUpdate?.content ?? ""),
    [featureUpdate?.content]
  );
  const settings = {
    ...DEFAULT_SLIDER_SETTINGS,
    autoplay: visibility === FeatureUpdateVisibility.Banner, // dont autoplay if it;s sitting behind a popup
    appendDots: (dots) => (
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="center"
        sx={{
          button: {
            color: getDynamicColor("dark3"),
            opacity: 1,
            p: 0,
            transition: "all 0.15s ease",
            "&:hover": {
              color: getDynamicColor("dark5"),
            },
          },
        }}
      >
        <IconButton
          onClick={() => sliderRef.current?.slickPrev()}
          sx={{
            mr: 3,
          }}
        >
          <ChevronLeft />
        </IconButton>
        {dots}
        <IconButton onClick={() => sliderRef.current?.slickNext()} sx={{ ml: 3 }}>
          <ChevronRight />
        </IconButton>
      </Stack>
    ),
  };
  return (
    <Stack
      gap={2}
      sx={{
        fontFamily: "poppins",
        ".slick-list": {
          // target all child divs
          "& div": {
            outline: "none !important",
          },
        },
        ...SLICK_DOTS_STYLES,
        ".slick-track": {
          ...SLICK_DOTS_STYLES[".slick-track"],
          display: "flex",
        },
        ".slick-dots": {
          ...SLICK_DOTS_STYLES[".slick-dots"],
          bottom: -8,
        },
        ".slick-slider": {
          // add padding to bottom to allow for dots and arrows
          pb: 5,
        },
        ".slick-slide": {
          minHeight: "100%",
          height: "auto",
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
          // target the first child div, which react-slick creates
          "& > div": {
            height: "100%",
            overflow: "hidden",
            px: 1,
          },
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "relative",
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontSize: { xs: 18, md: 20 },
              fontWeight: 700,
            }}
          >
            What's new?
          </Typography>
          <Stack
            direction="row"
            gap={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="center"
            alignItems="center"
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: { xs: 10, md: 12 },
              fontWeight: 600,
              "& p": {
                whiteSpace: "nowrap",
              },
            }}
          >
            <Typography>Updates and Improvements</Typography>
            <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
              <CalendarIcon
                style={{
                  color: getDynamicColor("dark4"),
                }}
              />
              <Typography>{getHumanReadableDate(featureUpdate?.date)}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          onClick={async () => await onClose()}
          sx={{
            position: "absolute",
            zIndex: 3,
            bottom: { xs: "unset", md: -8 },
            top: { xs: -8, md: "unset" },
            right: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Slider ref={sliderRef} {...settings}>
        {updates?.map(({ title, content }) => {
          const parsedContent = parse(content);
          const { images, contentWithoutImages } = findAndStripImgTags(parsedContent);
          return (
            <Stack
              key={title}
              direction={{ xs: "column-reverse", md: "row" }}
              alignItems="flex-start"
              justifyContent="flex-start"
              gap={{ xs: 3, md: 4 }}
              sx={{
                color: getDynamicColor("purple3"),
                flexGrow: 1,
                height: "100%",
                display: "flex !important",
                fontFamily: "Poppins",
                p: 3,
                pl: { xs: 2, md: 4 },
                pr: { xs: 2, md: 3 },
                border: `1px solid ${getDynamicColor("purple2")}`,
                borderRadius: "12px",
                overflow: "hidden",
                position: "relative",
                "& p": {
                  my: 0,
                  fontSize: { xs: 12, md: 14, xl: 16 },
                  lineHeight: 1.4,
                  textAlign: { xs: "center", md: "left" },
                },
                "& h1": {
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: 600,
                  textAlign: { xs: "center", md: "left" },
                },
              }}
            >
              <Stack
                gap={3}
                sx={{
                  height: "100%",
                  width: "100%",
                  flexGrow: 1,
                  position: "relative",
                  zIndex: 2,
                }}
                justifyContent="stretch"
                alignItems="stretch"
              >
                <Stack
                  alignItems={{ xs: "center", md: "flex-start" }}
                  justifyContent="flex-start"
                  gap={{ xs: 0.5, md: 1 }}
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  {contentWithoutImages}
                </Stack>
                <Button
                  variant="gradient"
                  onClick={async () => {
                    Instrumentation.logAmplitudeEvent(WhatsNewAnnouncementEvents.BANNER_CLICKED, {
                      type,
                    });
                    window.open(featureUpdate?.featureAnnouncementUrl, "_blank");
                    await onClose();
                  }}
                  size="xlarge"
                  sx={{
                    mr: "auto",
                    ml: { xs: "auto", md: "unset" },
                    fontSize: 14,
                  }}
                >
                  Learn More
                </Button>
              </Stack>
              {images.map((img, index) => (
                <Box
                  key={`${img}-${index}`}
                  sx={{
                    alignSelf: "center",
                    display: "flex !important",
                    minWidth: "33%",
                    maxWidth: "min(40%, 280px)",
                    maxHeight: "min(100%, 220px)",
                    height: { xs: "unset", md: "220px" },
                    position: "relative",
                    zIndex: 2,
                    mr: { xs: 0, sm: 2, md: 4, lg: 6 },
                  }}
                >
                  <Box
                    component="img"
                    src={img}
                    alt={`Feature Update ${index + 1}`}
                    sx={{
                      mx: "auto",
                      objectFit: "contain", // This ensures aspect ratio is maintained
                      verticalAlign: "top",
                      height: "auto",
                      width: "auto",
                      maxHeight: "100%",
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              ))}
              <Blobs
                overrideSx={{
                  zIndex: 1,
                  bottom: -100,
                  right: { xs: 0, md: -100 },
                  top: "unset",
                  opacity: 0.15,
                }}
                sizeMultiplier={0.5}
                overrideColors={{
                  blob1: getDynamicColor("blobGreen"),
                  blob2: getDynamicColor("primary"),
                  blob3: getDynamicColor("primary"),
                }}
              />
            </Stack>
          );
        })}
      </Slider>
    </Stack>
  );
};
