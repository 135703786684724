import React from "react";

// Components
import { Box, Stack, Typography } from "@mui/material";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

// Utils
import { CoachBotInfoRow, COACHBOT_INFO } from "../CoachBotInfoRow";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useWindowSize } from "utils/WindowSizeHook";

type CoachBotGetStartedProps = {
  entityName: string;
};

export const CoachBotGetStarted = ({ entityName }: CoachBotGetStartedProps): JSX.Element => {
  const infoRowsRef = React.useRef<HTMLDivElement>(null);
  const [leftColumnHeight, setLeftColumnHeight] = React.useState<number>(0);
  const windowSize = useWindowSize();
  React.useEffect(() => {
    setLeftColumnHeight(infoRowsRef.current?.clientHeight);
  }, [windowSize]);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      gap={{ xs: 3, md: 6 }}
      sx={{
        position: "relative",
        pt: { xs: 8, md: 16 },
        pb: { xs: 6, md: 10 },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexBasis: { xs: "unset", md: "50%" },
          pt: { xs: 2, md: 0 },
          height: { xs: "unset", md: leftColumnHeight },
        }}
      >
        <Box
          sx={{
            fontSize: 32,
            fontWeight: 800,
            lineHeight: 1.4,
            position: "relative",
            maxWidth: { xs: 460, md: "unset" },
            px: { xs: 3, md: 0 },
          }}
        >
          <Typography
            sx={{
              backgroundColor: getDynamicColor("greenSuccessLight"),
              color: getDynamicColor("greenSuccess"),
              borderRadius: "4px",
              p: 0.5,
              px: 1,
              fontSize: 10,
              width: "fit-content",
              position: "absolute",
              top: { xs: -24, md: -30 },
              left: { xs: 32, md: 0 },
            }}
          >
            NEW
          </Typography>
          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <AnimatedGradientText
              animationSpeedS={3}
              style={{
                fontFamily: "poppins",
              }}
            >
              Scale yourself with {entityName} Coach Bot!
            </AnimatedGradientText>
          </Box>
        </Box>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          flexBasis: { xs: "unset", md: "50%" },
        }}
      >
        <Stack
          direction="column"
          ref={infoRowsRef}
          gap={{ xs: 3, md: 6 }}
          sx={{
            px: { xs: 3, md: 0 },
          }}
        >
          {COACHBOT_INFO.getStarted.map((info, index) => (
            <CoachBotInfoRow key={index} icon={info.icon} copy={info.copy} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
