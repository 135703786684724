// Components
import { Box, Link, Stack, Typography } from "@mui/material";
import { LearnMoreType } from "components/Dashboard/DashboardTypes";
import videoLightbox from "ui/VideoLightbox";

// Assets
import { ReactComponent as LearnMoreIcon } from "images/icons/icon-learn-more.svg";

// Utils
import { DetailTitle } from "./AnalyticDetail";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSpeechSummaryPath } from "lib-frontend/utils/SpeechHandlers";
import { DashboardAnalyticId } from "lib-fullstack/db";

type LearnMoreProps = {
  learnMore: LearnMoreType;
  latestSlug: string;
  analyticId: DashboardAnalyticId;
};
const LearnMore = ({ learnMore, latestSlug, analyticId }: LearnMoreProps): JSX.Element => {
  const { lightbox, setLightboxURL } = videoLightbox("");
  let href = learnMore.url;
  if (learnMore.linkToLatestSpeech && latestSlug) {
    href = `${getSpeechSummaryPath(latestSlug, false)}?analytic=${analyticId}`;
  } else if (learnMore.lightboxUrl) {
    href = undefined;
  }

  const handleUrlClicked = () => {
    if (learnMore.lightboxUrl) {
      setLightboxURL(learnMore.lightboxUrl);
    }
  };

  return (
    <>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center">
          <Box
            sx={{
              width: 30,
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                height: 18,
                width: 18,
                backgroundColor: getDynamicColor("purple3"),
                borderRadius: "50%",
                svg: { fill: getDynamicColor("light1"), height: 10, width: 10 },
              }}
            >
              <LearnMoreIcon />
            </Stack>
          </Box>
          <DetailTitle title="Learn More" />
        </Stack>
        <Stack gap={1}>
          {learnMore.title && (
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: "12px",
              }}
            >
              {learnMore.title}
            </Typography>
          )}
          {learnMore.urlTitle && (
            <Link
              sx={{
                fontSize: 12,
                fontWeight: 700,
                textDecoration: "none",
                cursor: "pointer",
              }}
              href={href}
              onClick={handleUrlClicked}
              target={learnMore.url ? "_blank" : "_self"}
            >
              <p
                style={{
                  lineHeight: "17px",
                  margin: 0,
                }}
              >
                {learnMore.urlTitle}
              </p>
            </Link>
          )}
        </Stack>
      </Stack>
      {learnMore.lightboxUrl && lightbox}
    </>
  );
};

export default LearnMore;
