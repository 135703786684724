import { noop } from "lodash";
import React from "react";

// Components
import { Box, CircularProgress, Stack, Divider, Typography } from "@mui/material";
import { ReactComponent as AwardIcon } from "images/icons/award.svg";
import {
  DashboardGoal,
  FOCUS_ANALYTICS,
  HoistedAnalytic,
} from "components/Dashboard/DashboardTypes";
import AnalyticChart from "components/Dashboard/ReportSnapshot/AnalyticChart";
import AverageComparison from "components/Dashboard/ReportSnapshot/AnalyticDetail/AverageComparison";
import LearnMore from "components/Dashboard/ReportSnapshot/AnalyticDetail/LearnMore";
import NextSteps from "components/Dashboard/ReportSnapshot/AnalyticDetail/NextSteps";
import TopUsed from "components/Dashboard/ReportSnapshot/AnalyticDetail/TopUsed";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { useIsMediumScreen } from "lib-frontend/utils/themeUtils";
import { DashboardSuccess } from "lib-fullstack/api/apiTypes";
import { GetProgramUserResponse, ProgramResponse } from "lib-fullstack/api/programApiTypes";
import { AggregateAnalyticEnum, DashboardAnalyticId, DefaultGoal } from "lib-fullstack/db";
import { DAY_IN_MS } from "lib-fullstack/utils/constants";
import { fetchAggAnalytics, mapAnalyticsToMetadata } from "utils/DashboardUtils";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { GoalKindDefinitions, GoalKindLabels } from "components/ConvoScenarios/convoScenarioUtils";
import { GoalDescriptions, GoalKinds, GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { getAllCustomGoals } from "lib-frontend/modules/AxiosInstance";
import { DetailTitle } from "components/Dashboard/ReportSnapshot/AnalyticDetail/AnalyticDetail";

const AGG_ANALYTICS_QUERY_KEY = "aggAnalytics";
const MAX_DAY_RANGE = 180;

type ProgramUserDashboardProps = {
  program: ProgramResponse;
  user: GetProgramUserResponse;
};

const customGoalsQueryKey = "dashboardCustomGoals";
export const ProgramUserDashboard = ({ program, user }: ProgramUserDashboardProps): JSX.Element => {
  const isMediumScreen = useIsMediumScreen();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const defaultGoals: DashboardGoal[] = Object.values(DefaultGoal).map((goal) => ({
    identifier: goal,
    label: GoalLabels[goal],
    definition: GoalDescriptions[goal],
  }));

  const defaultAnalytics = Object.values(FOCUS_ANALYTICS).map((analytic) => ({
    value: analytic.identifier,
    label: analytic.label,
  }));

  const customGoalsQuery = useApiQuery({
    queryKey: [customGoalsQueryKey, defaultOrg?.id],
    queryFn: async () => {
      const { goals } = await getAllCustomGoals(defaultOrg?.id);
      return (
        (goals ?? [])
          // filter out custom goals that haven't been practiced at all
          .filter((x) => x.usage > 0)
          .map((goal) => ({
            identifier: goal.id,
            label: goal.name,
            definition: goal.userDescription,
            goalKind: goal.goalKind,
          }))
      );
    },
    enabled: !!defaultOrg?.id,
  });

  const combinedGoals = React.useMemo(
    () => [...defaultGoals, ...(customGoalsQuery?.data ?? [])],
    [defaultGoals, customGoalsQuery?.data]
  );

  const [analyticsWithMetadata, setAnalyticsWithMetadata] = React.useState<HoistedAnalytic[]>([]);

  const [selectedAnalytic, setSelectedAnalytic] = React.useState<string>(
    AggregateAnalyticEnum.FILLER
  );

  // Calculate the day range to fetch analytics
  // Set the end time to the user's completion date if it exists, otherwise set it to the current date
  // Set the start time to the program's created date, with a max range of 180 days
  const endTime = user.completion_date ? new Date(user.completion_date) : new Date();
  let startTime = new Date(program.created_date);
  let daysDifference = Math.floor((endTime.getTime() - startTime.getTime()) / DAY_IN_MS);
  if (daysDifference > MAX_DAY_RANGE) {
    startTime = new Date(endTime.getTime() - MAX_DAY_RANGE * DAY_IN_MS);
    daysDifference = MAX_DAY_RANGE;
  }

  const dashboardQuery = useApiQuery({
    queryKey: [AGG_ANALYTICS_QUERY_KEY, selectedAnalytic, user.user_id],
    queryFn: async () => {
      const analytics = await fetchAggAnalytics(
        [selectedAnalytic] as DashboardAnalyticId[],
        startTime.toISOString(),
        endTime.toISOString(),
        user.user_id
      );
      return analytics as DashboardSuccess;
    },
    enabled: !!selectedAnalytic,
  });

  const analyticOptions = React.useMemo(
    () =>
      combinedGoals
        .map((goal) => ({
          value: goal.identifier,
          label: goal.label,
        }))
        .concat(defaultAnalytics),
    [combinedGoals]
  );

  React.useEffect(() => {
    const mapAnalytics = async () => {
      const newAnalytic = (
        await mapAnalyticsToMetadata(dashboardQuery.data?.analytics, defaultOrg?.id)
      )?.[0];
      if (
        newAnalytic &&
        !analyticsWithMetadata.find((x) => x.identifier === newAnalytic?.identifier)
      ) {
        setAnalyticsWithMetadata([...analyticsWithMetadata, newAnalytic]);
      }
    };
    if (
      dashboardQuery.data?.analytics &&
      !analyticsWithMetadata.find(
        (x) => x.identifier === Object.keys(dashboardQuery.data?.analytics)[0]
      )
    ) {
      mapAnalytics().catch((er) => {
        console.error(`Error mapping analytics to metadata in ProgramUserDashboard: ${er}`);
      });
    }
  }, [analyticsWithMetadata, dashboardQuery.data?.analytics, defaultOrg?.id]);

  const activeAnalytic = React.useMemo(() => {
    return analyticsWithMetadata.find((x) => x.identifier === selectedAnalytic) as HoistedAnalytic;
  }, [analyticsWithMetadata, selectedAnalytic]);

  const latestSlug = React.useMemo((): string => {
    if (activeAnalytic?.data?.length || !activeAnalytic?.data?.[0]?.slugs?.length) {
      return "";
    }
    return activeAnalytic.data[0].slugs[0];
  }, [activeAnalytic]);

  // if it has data, but it's not okay, return null
  if (dashboardQuery?.data && !dashboardQuery?.data?.ok) {
    return;
  }

  const minDataHeightSx = { xs: "270px", lg: "400px" };

  const loadingData = dashboardQuery.isLoading || customGoalsQuery.isLoading;

  const renderSelect = () => {
    if (!selectedAnalytic) {
      return null;
    }
    return (
      <YoodliSelect
        options={analyticOptions}
        value={selectedAnalytic || "placeholder"}
        placeholder="Select an analytic or goal"
        onChange={(e) => setSelectedAnalytic(e.target.value as string)}
        sx={{
          height: "50px",
        }}
      />
    );
  };

  return (
    <Stack gap={2}>
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 700,
        }}
      >
        Skill progress during program
      </Typography>
      {isMediumScreen && renderSelect()}
      <Stack
        sx={{
          borderRadius: "4px",
          border: `1px solid ${getDynamicColor("dark3")}`,
          px: { xs: 3, lg: 5 },
          py: { xs: 3, lg: 4 },
          mb: { xs: 3, lg: 0 },
        }}
      >
        <Stack
          gap={2}
          direction={{ xs: "column-reverse", lg: "row" }}
          sx={{ justifyContent: "space-between" }}
        >
          <Stack gap={3}>
            {!isMediumScreen && renderSelect()}
            {!loadingData && (
              <Stack gap={2}>
                {activeAnalytic?.data.length > 0 && (
                  <AverageComparison analytic={activeAnalytic} numDays={daysDifference} />
                )}
                {activeAnalytic?.nextSteps && (
                  <>
                    <Divider />
                    <NextSteps
                      nextSteps={activeAnalytic.nextSteps}
                      value={activeAnalytic.currAvg}
                      analyticThreshold={activeAnalytic.threshold}
                    />
                  </>
                )}
                {(GoalKinds[activeAnalytic?.identifier] || activeAnalytic?.goalKind) && (
                  <>
                    <Divider />
                    <Stack gap={1}>
                      <Stack direction="row" alignItems="center">
                        <Box
                          sx={{
                            width: 30,
                          }}
                        >
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              height: 18,
                              width: 18,
                              backgroundColor: getDynamicColor("purple3"),
                              borderRadius: "50%",
                              svg: { fill: getDynamicColor("light1"), height: 10, width: 10 },
                            }}
                          >
                            <AwardIcon />
                          </Stack>
                        </Box>
                        <DetailTitle
                          title={
                            GoalKindLabels[
                              GoalKinds[activeAnalytic?.identifier] ?? activeAnalytic?.goalKind
                            ]
                          }
                        />
                      </Stack>
                      <Stack direction="row" gap={2}>
                        <Typography
                          sx={{
                            fontSize: 12,
                          }}
                        >
                          {
                            GoalKindDefinitions[
                              GoalKinds[activeAnalytic?.identifier] ?? activeAnalytic?.goalKind
                            ]
                          }
                        </Typography>
                      </Stack>
                    </Stack>
                  </>
                )}
                {(activeAnalytic?.topUsedWords || activeAnalytic?.learnMore) && <Divider />}
                {activeAnalytic?.topUsedWords && (
                  <TopUsed topUsedWords={activeAnalytic.topUsedWords} numDays={daysDifference} />
                )}
                {activeAnalytic?.learnMore && (
                  <LearnMore
                    learnMore={activeAnalytic.learnMore}
                    latestSlug={latestSlug}
                    analyticId={activeAnalytic?.identifier}
                  />
                )}
              </Stack>
            )}
          </Stack>
          {loadingData || !activeAnalytic?.data ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: minDataHeightSx,
              }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <>
              {activeAnalytic?.data.length ? (
                <Box sx={{ height: minDataHeightSx, width: "100%" }}>
                  <AnalyticChart
                    activeAnalytic={activeAnalytic}
                    setHoveringThresholdLegend={noop}
                  />
                </Box>
              ) : (
                <Typography
                  sx={{
                    m: "auto",
                    color: getDynamicColor("purple3"),
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "poppins",
                    height: minDataHeightSx,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: `1px solid ${getDynamicColor("dark2")}`,
                    backgroundColor: getDynamicColor("dark1"),
                    borderRadius: "8px",
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  No data available
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
