import { FALLBACK_SUBDOMAIN } from "../utils/constants";
import { inBrowser, inElectronRenderer } from "../globalEnv";

/**
 * Determines the active `subdomain` and (optional) `prSlug` from the full
 * hostname. If there is no match, it defaults to FALLBACK_SUBDOMAIN (i.e, "app").
 * See test file for examples.
 */
export function getSubdomainFromHostname(hostname?: string): {
  subdomain: string;
  prSlug: string | null;
} {
  let prSlug: string | null = null;

  hostname = hostname ?? (inBrowser() ? window.location.hostname : "");
  hostname = hostname.toLowerCase();

  const match = hostname.match(/^([^.]*)\.([^.]*)\.?yoodli\.ai$|^localhost(?::\d+)?$/);
  let subdomain = match?.[1] ?? FALLBACK_SUBDOMAIN;

  if (!match && hostname !== "yoodli.ai") {
    console.log(`getSubdomainFromHostname: no match on ${hostname}`);
    return { subdomain, prSlug };
  }

  if (["dev", "staging"].includes(subdomain)) {
    subdomain = FALLBACK_SUBDOMAIN;
  }

  // If we are working with local HTTPS server, it always assumes fallback sub domain.
  if (!inElectronRenderer() && hostname === process.env.LOCAL_HTTPS) {
    subdomain = FALLBACK_SUBDOMAIN;
  }

  if (hostname.endsWith(".pr.yoodli.ai")) {
    // PR subdomains are in the format `<prSlug>_<subdomain>.pr.yoodli.ai`
    // where prSlug may include underscores, but subdomain may not.
    const split = subdomain.split("_");
    if (split.length === 1) {
      throw new Error(`${hostname} does not match an expected PR site format`);
    } else {
      prSlug = split.slice(0, -1).join("_");
      subdomain = split[split.length - 1];
    }
  }

  return { subdomain, prSlug };
}
