import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  OutlinedInput,
  Pagination,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// Components
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import { adminGetOrgList } from "lib-frontend/modules/AxiosInstance";
import React from "react";
import { AdminGetOrgListResponse } from "lib-fullstack/api/adminApiTypes";
import { WebServerInternalPath } from "utils/paths";
import { useNavigate } from "react-router-dom";
import { CreateOrgModal } from "./CreateOrgModal";
import { AxiosError } from "axios";

export type OrgTableRow = {
  id: string;
  name: string;
  ownerEmails: Array<string>;
  adminEmails: Array<string>;
  searchText: Array<string>;
};

const headerTextStyles = {
  borderLeft: "2px solid lightgrey",
  textAlign: "left",
  pl: 2,
  fontWeight: "500",
  fontSize: "14px",
  my: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: getDynamicColor("purple3"),
  fontFamily: "Poppins",
};

const ORG_LIST_QUERY_LIMIT = 50;
const ORG_LIST_BATCH_SIZE = 5;

export const AdminConfigOrgs = (): JSX.Element => {
  const navigate = useNavigate();

  const [tableData, setTableData] = React.useState<OrgTableRow[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const [showCreationModal, setShowCreationModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getAllOrgs().catch((e) => {
      console.log("failed to fetch orgs", e);
      // If not authorized, redirect to home page (which presents as 404).
      if ((e as AxiosError)?.response?.status === 403) {
        navigate(WebServerInternalPath.HOME);
      }
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    table.getColumn("searchText").setFilterValue(searchText);
  }, [searchText]);

  const getAllOrgs = async () => {
    setLoading(true);
    const allOrgs = [];
    let start = 0;
    let hasMore = true;

    while (hasMore) {
      const batchPromises = [];
      for (let i = 0; i < ORG_LIST_BATCH_SIZE && hasMore; i++) {
        batchPromises.push(
          adminGetOrgList({ start: start + i * ORG_LIST_QUERY_LIMIT, limit: ORG_LIST_QUERY_LIMIT })
        );
      }
      const batchResponses = await Promise.all(batchPromises);
      for (const response of batchResponses) {
        if (response.orgs && response.orgs.length > 0) {
          allOrgs.push(...addOrgsToList(response));
          start += ORG_LIST_QUERY_LIMIT;
        } else {
          hasMore = false;
          break;
        }
      }
    }
    setTableData(allOrgs);
    setLoading(false);
  };

  const addOrgsToList = (response: AdminGetOrgListResponse) => {
    if (response.orgs) {
      const newOrgs: OrgTableRow[] = response.orgs.map((org) => {
        return {
          id: org.id,
          name: org.name,
          ownerEmails: [...org.owner_emails],
          adminEmails: [...org.admin_emails],
          searchText: [org.id, org.name, ...org.owner_emails, ...org.admin_emails],
        };
      });
      return newOrgs;
    }
    return [];
  };

  const handleOrgClick = async (orgId: string) => {
    navigate(`${WebServerInternalPath.ADMIN_CONFIG}/${orgId}`);
  };

  const handleOrgAuxClick = async (orgId: string) => {
    const newWindow = window.open(`${WebServerInternalPath.ADMIN_CONFIG}/${orgId}`, "_blank");
    if (newWindow) {
      // Note: This is supposed to move the focus to the new tab, but it does not seem to work.
      newWindow.focus();
    }
  };

  const renderSearchFilter = (
    setSearchText: (val: string) => void,
    searchText: string,
    searchLabel: string
  ) => (
    <FormControl sx={{ backgroundColor: "white", mr: 1 }} variant="outlined" size="small">
      <InputLabel htmlFor="overall-input-search" margin="dense">
        Search {searchLabel}
      </InputLabel>
      <OutlinedInput
        id="overall-input-search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        endAdornment={
          // search icon turns into 'X' for clearing text when
          // there is text to be cleared.
          // Search is instantaneous.
          <InputAdornment position="end">
            <IconButton aria-label="Search" onClick={() => setSearchText("")} edge="end">
              {searchText.length > 0 ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
          </InputAdornment>
        }
        label={`Search ${searchLabel}`}
      />
    </FormControl>
  );

  const renderOrgTable = () => (
    <Stack gap={3}>
      <Stack display="flex" gap={3} width="100%" flexDirection="row" justifyContent="space-between">
        <Button
          onClick={() => setShowCreationModal(true)}
          startIcon={<AddCircleOutlineIcon sx={{ width: "20px", height: "20px" }} />}
          sx={{
            justifyContent: "flex-start",
            px: 2,
            color: getDynamicColor("primary"),
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          Create an organization
        </Button>
        <Box>{renderSearchFilter(setSearchText, searchText, "")}</Box>
      </Stack>
      <Box sx={{ height: "calc(100dvh - 250px)", overflow: "auto" }}>
        <table
          style={{
            borderCollapse: "collapse",
            backgroundColor: "white",
            borderRadius: "4px",
            border: "1px solid lightgrey",
            width: "100%",
            borderSpacing: "0px",
            background:
              "linear-gradient(71deg, rgba(29, 169, 230, 0.2) -9%, rgba(105, 102, 254, 0.2) 79%)",
            marginBottom: "24px",
            // width: "1000px",
          }}
        >
          <thead style={{ backgroundColor: getDynamicColor("light1") }}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{
                      borderBottom: "1px solid lightgrey",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: getDynamicColor("light1"),
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mr: 1 }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Stack>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Box
                  component="tr"
                  key={row.id}
                  sx={{
                    backgroundColor: getDynamicColor("light1"),
                    borderBottom: "1px solid lightgrey",
                    transition: "background-color 0.1s ease-out",
                    "&:hover": {
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    },
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        padding: 0,
                      }}
                    >
                      <Link
                        onClick={() => handleOrgClick(row.original.id)}
                        onAuxClick={() => handleOrgAuxClick(row.original.id)}
                        style={{
                          paddingRight: "20px",
                          textDecoration: "none",
                          color: "inherit",
                          lineHeight: "100%",
                          display: "inline-block",
                          width: "100%",
                        }}
                      >
                        <Box
                          // Outer box is expanded to fill the whole cell to make clickable link
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          display="flex"
                        >
                          <Box
                            // Inner box is centered vertically and start-aligned
                            style={{
                              height: "120px",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                            display="flex"
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Box>
                        </Box>
                      </Link>
                    </td>
                  ))}
                </Box>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Stack>
  );

  const columnHelper = createColumnHelper<OrgTableRow>();

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Tooltip title={"Organization name"}>
          <Typography sx={{ ...headerTextStyles, borderLeft: "unset" }}>
            Organization Name
          </Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <Typography
            sx={{
              pr: "10px",
              pl: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {info.getValue()}
          </Typography>
        </Stack>
      ),
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Tooltip title={"Org ID"}>
          <Typography sx={{ ...headerTextStyles }}>Org ID</Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <Typography
            sx={{
              pr: "10px",
              pl: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {info.getValue()}
          </Typography>
        </Stack>
      ),
    }),
    columnHelper.accessor("ownerEmails", {
      id: "ownerEmails",
      header: () => (
        <Tooltip title={"Owner Emails"}>
          <Typography sx={{ ...headerTextStyles }}>Owner Emails</Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <List sx={{ maxHeight: "100px" }}>
            {info.getValue().map((entry, i) => (
              <ListItem key={i} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                {entry}
              </ListItem>
            ))}
          </List>
        </Stack>
      ),
      filterFn: "includesString",
    }),
    columnHelper.accessor("adminEmails", {
      id: "adminEmails",
      header: () => (
        <Tooltip title={"Admin Emails"}>
          <Typography sx={{ ...headerTextStyles }}>Admin Emails</Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <List sx={{ overflow: "auto", maxHeight: "100px" }}>
            {info.getValue().map((entry, i) => (
              <ListItem key={i}>{entry}</ListItem>
            ))}
          </List>
        </Stack>
      ),
      filterFn: "includesString",
    }),
    columnHelper.accessor("searchText", {
      id: "searchText",
      header: () => (
        <Tooltip title={"Search Text"}>
          <Typography sx={{ ...headerTextStyles }}>Admin Emails</Typography>
        </Tooltip>
      ),
      cell: (info) => (
        <Stack direction="row">
          <List sx={{ overflow: "auto", maxHeight: "100px" }}>
            {info.getValue().map((entry, i) => (
              <ListItem key={i}>{entry}</ListItem>
            ))}
          </List>
        </Stack>
      ),
      filterFn: "includesString",
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
      columnVisibility: {
        searchText: false,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const firstItem =
    table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
  const lastItem = Math.min(
    firstItem + table.getState().pagination.pageSize - 1,
    table.getRowCount()
  );

  return (
    <Stack
      spacing={2}
      sx={{
        p: 4,
        overflow: "auto",
      }}
    >
      <Typography
        component="h1"
        sx={{
          position: "relative",
          fontWeight: 600,
          fontSize: 20,
          color: getDynamicColor("purple3"),
          pt: 3,
          fontFamily: "poppins",
          zIndex: 2,
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        Organizations
      </Typography>
      {loading ? (
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        renderOrgTable()
      )}
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        <Pagination
          count={table.getPageCount()}
          showFirstButton
          showLastButton
          page={table.getState().pagination.pageIndex + 1}
          onChange={(_, page) => table.setPageIndex(Math.max(page - 1, 0))}
        />
        <Typography
          sx={{
            mt: 0.5,
            color: getDynamicColor("dark4"),
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          {`Displaying items ${firstItem} - ${lastItem} of ${table.getRowCount()}`}
        </Typography>
      </Stack>
      <CreateOrgModal
        open={showCreationModal}
        close={() => setShowCreationModal(false)}
        orgList={tableData}
      />
    </Stack>
  );
};
