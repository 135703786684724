// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";
import { Record, Static, String, Boolean } from "runtypes";

/**
 * Unique identifier for the embed message
 */
export enum EmbedMessageTypeEnum {
  Scorm = "scorm",
}
export const EmbedMessageType = RTStringEnum(EmbedMessageTypeEnum);

/**
 * Data for SCORM messages
 * name and data are passed to the LMS using LMSSetValue, matching the SCORM 1.2 specification
 * If closeSession is true, calls LMSFinish after setting the value to end communication with LMS
 * See https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/?utm_source=google&utm_medium=natural_search#section-2
 */
export const ScormMessageData = Record({
  name: String,
  data: String,
  closeSession: Boolean,
});
export type ScormMessageData = Static<typeof ScormMessageData>;

/**
 * Message types for communication between the embedded activity and the parent window
 */
export const EmbedMessage = Record({
  messageType: EmbedMessageType,
  data: ScormMessageData,
});
export type EmbedMessage = Static<typeof EmbedMessage>;

/**
 * Unique identifiers for SCORM message names, following the SCORM 1.2 data model
 */
export enum ScormEventTypeEnum {
  ScoreRaw = "cmi.core.score.raw",
  LessonStatus = "cmi.core.lesson_status",
}
export const ScormEventType = RTStringEnum(ScormEventTypeEnum);
