import React from "react";

// Components
import { Box, Stack } from "@mui/material";

// Utils
import { INTERVIEW_BANKS_QUERY_KEY } from "../ManageContent/OrgManageContent";
import { OrgLoading } from "../OrgLoading";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  getHubContentAdminViewV2,
  listHubInterviewBanks,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { AvailableContentTabEnum, AvailableContentTabLabels } from "lib-frontend/utils/orgUtils";
import {
  CustomizePracticeTabSelector,
  TabSelectorType,
} from "../ManageContent/CustomizePractice/CustomizePracticeTabSelector";
import { VideosAndCoursesTab } from "./VideosAndCoursesTab";
import { InterviewBanksTab } from "./InterviewBanksTab";
import { ScenariosTab } from "./ScenariosTab";

export const VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY = "videosAndCourses";

export default function AvailableContent({ hubId }: { hubId: string }): JSX.Element {
  const userOrgContext = React.useContext(UserOrgContext);

  const tabs = Object.values(AvailableContentTabEnum);

  const [activeTab, setActiveTab] = React.useState<TabSelectorType>(
    AvailableContentTabEnum.RoleplayScenarios
  );

  const videosAndCoursesQueryResult = useApiQuery({
    queryKey: [VIDEOS_AND_COURSES_MEMBERS_QUERY_KEY, userOrgContext.orgId, hubId],
    queryFn: async () => getHubContentAdminViewV2(userOrgContext.orgId, hubId),
    enabled: !userOrgContext.defaultOrgLoading,
  });

  const interviewBanksQueryResult = useApiQuery({
    queryKey: [INTERVIEW_BANKS_QUERY_KEY, hubId],
    queryFn: async () => listHubInterviewBanks(hubId),
    enabled: !userOrgContext.defaultOrgLoading,
  });

  const loading = videosAndCoursesQueryResult.isPending || interviewBanksQueryResult.isPending;

  if (loading) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: getDynamicColor("light1"),
        }}
      >
        <OrgLoading prompts={["Loading group content..."]} bgColor={getDynamicColor("light1")} />;
      </Stack>
    );
  }

  const renderCoursesAndVideosTab = () => (
    <VideosAndCoursesTab videosAndCourses={videosAndCoursesQueryResult.data} />
  );

  const renderScenariosTab = (
    tab: AvailableContentTabEnum.RoleplayScenarios | AvailableContentTabEnum.InterviewScenarios
  ) => <ScenariosTab groupId={hubId} scenarioTabType={tab} />;

  const renderInterviewBanksTab = () => (
    <InterviewBanksTab interviewBanks={interviewBanksQueryResult.data} />
  );

  return (
    <>
      <Stack
        gap={4}
        sx={{
          p: { xs: 3, md: 4, lg: 6 },
        }}
      >
        <CustomizePracticeTabSelector
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabLabels={AvailableContentTabLabels as Record<TabSelectorType, string>}
        />
        {tabs.map((tab) => {
          return (
            <Box
              key={tab}
              sx={{
                display: activeTab === tab ? "initial" : "none",
              }}
            >
              {tab === AvailableContentTabEnum.CoursesAndVideos && renderCoursesAndVideosTab()}
              {tab === AvailableContentTabEnum.RoleplayScenarios &&
                renderScenariosTab(AvailableContentTabEnum.RoleplayScenarios)}
              {tab === AvailableContentTabEnum.InterviewScenarios &&
                renderScenariosTab(AvailableContentTabEnum.InterviewScenarios)}
              {tab === AvailableContentTabEnum.QuestionBanks && renderInterviewBanksTab()}
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
