import firebase from "firebase/app";
import { doLogout } from "modules/FirebaseModule";
import React from "react";
import { useLocation } from "react-router";

// Components
import { Button, Stack, Typography, Box } from "@mui/material";

// Assets
import YoodliLogoText from "../../../images/logos/yoodli_logo.svg";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { ORG_ACCESS_INFO_QUERY_KEY } from "auth/utils";
import { YoodliActivityContext } from "lib-frontend/contexts/YoodliActivityContext";
import { getOrgAccessInfo } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { UITestId } from "lib-fullstack/utils/enums";
import { AuthQueryParams } from "lib-fullstack/utils/queryParams";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";

const VERIFY_EMAIL_RELEASE_DATE = new Date("2023-02-28").toISOString();

type EmailSentProps = {
  sendVerificationEmail: React.MouseEventHandler<HTMLButtonElement>;
  emailSent: boolean;
  setEmailSent: (val: boolean) => void;
};

export default function EmailSent({
  sendVerificationEmail,
  emailSent,
  setEmailSent,
}: EmailSentProps): JSX.Element {
  const orgContext = React.useContext(UserOrgContext);
  const { activity } = React.useContext(YoodliActivityContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const siteConf = getStaticFullSiteConf();
  const userCreatedDate = new Date(firebase.auth().currentUser.metadata.creationTime).toISOString();
  const isNewUser = userCreatedDate > VERIFY_EMAIL_RELEASE_DATE;

  React.useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 3000);
    }
  }, [emailSent]);

  const orgId = activity?.orgId ?? orgContext.orgId;
  const hubId = queryParams.get(AuthQueryParams.HUB_ID);

  const orgAccessInfoQuery = useApiQuery({
    queryKey: [ORG_ACCESS_INFO_QUERY_KEY, orgId ?? hubId],
    queryFn: () => getOrgAccessInfo({ orgId: orgId, hubId: hubId }),
    enabled: !!orgId || !!hubId,
    refetchOnWindowFocus: false,
  });

  const handleDoLogout = () => {
    doLogout(orgContext.userInOrg, orgId, orgContext.logOutEverywhereEnforced).catch((e) => {
      console.error("Error logging out on verify email screen", e);
    });
  };
  const handleResendEmailClick = (e) => {
    sendVerificationEmail(e);
  };
  return (
    <Stack gap={2} alignItems="center">
      <img
        src={orgAccessInfoQuery.data?.org_logo_url ?? siteConf?.logo?.url ?? YoodliLogoText}
        width={100}
        alt="Yoodli logo"
      />
      <Typography
        fontSize="32px"
        fontWeight={500}
        textAlign="center"
        data-testid={UITestId.VerifyYourEmailLabel}
      >
        Verify your email
      </Typography>
      <Typography fontSize="16px" fontWeight={500} textAlign="center" maxWidth={485}>
        {isNewUser
          ? "You should receive an email to the following address shortly!"
          : "Confirm your email address to keep Yoodlin'!"}{" "}
        We recommend checking your spam folder just in case.
      </Typography>
      <Box
        sx={{
          backgroundColor: getDynamicColor("light2"),
          textAlign: "center",
          py: 2,
          px: 3,
          width: "min(100%, 340px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography data-logrocket-hidden fontWeight={700}>
          {firebase.auth().currentUser.email}
        </Typography>
      </Box>

      <Stack
        sx={{
          width: "100%",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{ mx: "auto", width: "min(100%, 300px)" }}
          onClick={handleResendEmailClick}
          data-testid={UITestId.ResendEmailButton}
        >
          {emailSent ? "Email sent!" : "Resend email"}
        </Button>
        <Button
          sx={{ mx: "auto", width: "min(100%, 300px)", py: 1 }}
          onClick={() => handleDoLogout()}
        >
          Sign out
        </Button>
      </Stack>
    </Stack>
  );
}
