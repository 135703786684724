import { GenerateQueryParams } from "lib-fullstack/api/scorm12ApiTypes";
import { getAxiosInstance, AuthorizationAPIHeaders } from "./AxiosInstance";

/**
 * Download a SCORM 1.2 scenario roleplay package.
 * @param params Query parameters.
 * @throws Error if the request fails.
 */
export async function generatePackage(params: GenerateQueryParams): Promise<void> {
  try {
    const headers = await AuthorizationAPIHeaders();
    const response = await getAxiosInstance().get(`/scorm/1.2/package`, {
      headers: headers,
      params: params,
      responseType: "blob",
    });

    // Extract file name from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    if (!contentDisposition) {
      throw Error("Missing Content-Disposition header");
    }

    const fileName = contentDisposition.match(/filename=(.*)$/)[1];
    const strippedFileName = fileName.replace(/^"(.*)"$/, "$1");
    console.log("fileName", strippedFileName);

    // Create a URL for the Blob and initiate download
    const blob = new Blob([response.data]);
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = strippedFileName; // Set the filename for download
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // Clean up
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (err) {
    console.error("generatePackage error", err);
    throw Error("Bad generatePackage request, status " + err.response?.status);
  }
}
