import React from "react";
import { FeatureUpdateResponse } from "lib-frontend/api/wordpress";
import { FeatureUpdateVisibility } from "lib-fullstack/db";
import { useFeatureUpdates } from "hooks/useFeatureUpdates";
import { WhatsNewAnnouncementType } from "lib-fullstack/utils/productAnalyticEvents";

interface FeatureUpdatesContextType {
  featureUpdate: FeatureUpdateResponse | null;
  featureUpdateVisibility: FeatureUpdateVisibility | null;
  handleCloseFeatureUpdate: (type?: WhatsNewAnnouncementType) => Promise<void>;
}

export const FeatureUpdatesContext = React.createContext<FeatureUpdatesContextType | null>(null);

export function FeatureUpdatesProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const featureUpdates = useFeatureUpdates();

  return (
    <FeatureUpdatesContext.Provider value={featureUpdates}>
      {children}
    </FeatureUpdatesContext.Provider>
  );
}

export function useFeatureUpdatesContext(): FeatureUpdatesContextType {
  const context = React.useContext(FeatureUpdatesContext);
  if (!context) {
    throw new Error("useFeatureUpdatesContext must be used within a FeatureUpdatesProvider");
  }
  return context;
}
