import React from "react";
import { useLocation } from "react-router";

// Components
import { Box, Button, Checkbox, FormControlLabel, Stack, SxProps } from "@mui/material";
import { WizardSubTitle, WizardTitle } from "components/Wizard/WizardTitles";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";

type HubOptionProps = {
  hubName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  sx?: SxProps;
};

export const HubOption = ({ hubName, checked, onChange, sx }: HubOptionProps): JSX.Element => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} disableRipple disableTouchRipple />}
      label={hubName}
      sx={{
        fontSize: { xs: 12, md: 14 },
        fontWeight: 600,
        color: getDynamicColor("dark5"),
        ...sx,
      }}
    />
  );
};

type SelectHubProps = {
  selectedHubIds: string[];
  setSelectedHubIds: (selectedHubIds: string[]) => void;
  allHubs: HubV2Response[];
  hubSelectorSx?: SxProps;
  skipHubSelection?: boolean;
  hideTitle?: boolean;
  selectCurrentHub?: boolean;
};

export const SelectHub = ({
  allHubs,
  selectedHubIds,
  setSelectedHubIds,
  hubSelectorSx,
  skipHubSelection,
  hideTitle,
  selectCurrentHub,
}: SelectHubProps): JSX.Element => {
  const location = useLocation();
  React.useEffect(() => {
    const qp = new URLSearchParams(location.search);
    const currHubId = qp.get(OrgHubsQueryParams.GROUP_ID);
    if (selectCurrentHub && currHubId) {
      setSelectedHubIds([currHubId]);
    }
  }, [selectCurrentHub]);
  if (skipHubSelection) {
    return null;
  }
  return (
    <Stack gap={{ xs: 2, md: 5 }}>
      {!hideTitle && (
        <Stack
          gap={1}
          sx={{
            fontFamily: "poppins",
          }}
        >
          <WizardTitle>Select a group</WizardTitle>
          <WizardSubTitle>Select at least one group to add all members to</WizardSubTitle>
        </Stack>
      )}
      <Box
        sx={{
          width: "min(540px, 100%)",
          border: `1px solid ${getDynamicColor("dark4")}`,
          borderRadius: "12px",
          overflow: "hidden",
          p: 3,
          ...hubSelectorSx,
        }}
      >
        <Button
          sx={{
            fontSize: 12,
            position: "relative",
            top: -8,
            left: -8,
          }}
          onClick={() => {
            if (selectedHubIds.length > 0) {
              setSelectedHubIds([]);
            } else {
              setSelectedHubIds(allHubs.map((hub) => hub.id));
            }
          }}
        >
          {selectedHubIds?.length === 0 ? "Select " : "Deselect "}all
        </Button>
        <Stack
          gap={2}
          sx={{
            fontFamily: "poppins",
            maxHeight: 400,
            overflow: "auto",
          }}
        >
          {allHubs.map((hub) => {
            const checked = selectedHubIds.includes(hub.id);
            return (
              <HubOption
                key={hub.id}
                hubName={hub.name}
                checked={checked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedHubIds([...selectedHubIds, hub.id]);
                  } else {
                    setSelectedHubIds(selectedHubIds.filter((id) => id !== hub.id));
                  }
                }}
              />
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
