import React from "react";
import { Button } from "@mui/material";

export const RephraseOrGenerateButton = ({
  lengthToCheck,
  onClick,
}: {
  lengthToCheck: number;
  onClick: () => void;
}): JSX.Element => {
  return (
    <Button sx={{ fontSize: 12, ml: "auto" }} variant="text" onClick={onClick}>
      {lengthToCheck > 0 ? "Rephrase" : "Generate"}
    </Button>
  );
};
