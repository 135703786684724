import React from "react";

// Components
import { TooltipProps, Tooltip, Box } from "@mui/material";

// Utils
import { usePrevious } from "lib-frontend/hooks";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";

export default function YoodliTooltip({
  children,
  ...props
}: React.PropsWithChildren<TooltipProps>): JSX.Element {
  // if the pathname ever changes, make sure to close any tooltips that are still open
  // NOTE: must use window.location.pathname as useLocation does not work for Poodli
  const prevPathname = usePrevious(window?.location?.pathname);
  React.useEffect(() => {
    const handleClose = () => {
      if (props.open) {
        props.onClose?.(
          new MouseEvent("mouseleave", {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        );
      }
    };
    if (prevPathname !== window?.location?.pathname) {
      handleClose();
    }
    window.addEventListener("scroll", handleClose);
    return () => window.removeEventListener("scroll", handleClose);
  }, [window?.location?.pathname]);
  return (
    <Tooltip
      {...props}
      PopperProps={{
        ...props.PopperProps,
        sx: {
          ...props.PopperProps?.sx,
          // so it appears over the nav drawer
          zIndex: 999999,
          "& .MuiTooltip-tooltip": {
            backgroundColor: getDynamicColor("light1"),
            boxShadow: Y_SHADOWS.box_shadow_1,
            color: getDynamicColor("purple3"),
            border: `1px solid ${getDynamicColor("dark3")}`,
            borderRadius: "12px",
            fontSize: "14px",
            fontFamily: "poppins",
            p: 2,
          },
        },
      }}
      sx={{
        color: getDynamicColor("purple3"),
        ...props.sx,
      }}
    >
      <Box style={{ display: "flex" }}>{children}</Box>
    </Tooltip>
  );
}
