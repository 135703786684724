import { db } from "lib-fullstack";
import React from "react";

// Components
import { Add as AddIcon, Remove as RemoveIcon, Check as CheckIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import {
  MAX_DND_INPUT_LENGTH,
  MAX_ONE_OFF_QUESTIONS,
} from "components/ConvoScenarios/convoScenarioUtils";

// Utils
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { useOnAll } from "@typesaurus/react";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { INTERVIEW_CATEGORIES } from "utils/interviewUtils";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type OneOffQuestionListProps = {
  questions: string[];
  handleUpdateQuestions: React.Dispatch<React.SetStateAction<string[]>>;
  handleQuestionSelectionChange: (question: string, action: "add" | "remove") => void;
  extraQuestionSuggestions: string[];
};

export const OneOffQuestionList = ({
  questions,
  handleUpdateQuestions,
  handleQuestionSelectionChange,
  extraQuestionSuggestions,
}: OneOffQuestionListProps): JSX.Element => {
  const [allPrompts, setAllPrompts] = React.useState<db.InterviewQuestion[]>([]);
  const [hoveringIconId, setHoveringIconId] = React.useState<string | null>(null);
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [dbInterviewPrompts, dbInterviewPromptsMeta] = useOnAll<db.InterviewQuestion>( // general interview prompts
    db.interviewPrompts
  );

  const maxQuestionsReached = questions.length >= MAX_ONE_OFF_QUESTIONS;

  React.useEffect(() => {
    if (dbInterviewPrompts) {
      setAllPrompts(
        dbInterviewPrompts
          .filter((p) => p.data.category === INTERVIEW_CATEGORIES.GENERAL.identifier)
          .sort((a) => (questions.includes(a.data.text) ? -1 : 1))
          .map((p) => ({ text: p.data.text, category: p.data.category }))
      );
    }
  }, [dbInterviewPromptsMeta.loading]);

  const promptTexts = React.useMemo(() => {
    return (extraQuestionSuggestions ?? []).concat(allPrompts.map((prompt) => prompt.text));
  }, [extraQuestionSuggestions, allPrompts]);

  const getIconBackgroundColor = (question: string, isSelected: boolean) => {
    if (hoveringIconId === question && isSelected && !blockIconHover[question]) {
      return getDynamicColor("dark4");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    if (maxQuestionsReached) {
      return getDynamicColor("dark4");
    }
    return getDynamicColor("primary");
  };

  const renderAddRemoveIcons = (personaId: string, isSelected: boolean) => {
    if (hoveringIconId === personaId && isSelected && !blockIconHover[personaId]) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        pt: 1,
        px: 2,
        width: "800px",
        maxWidth: "100%",
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      <WizardAddDraggableItems
        items={questions}
        handleUpdateItems={handleUpdateQuestions}
        inputPlaceholder="Enter a question and click 'Add'"
        maxItems={MAX_ONE_OFF_QUESTIONS}
        maxInputLength={MAX_DND_INPUT_LENGTH}
        itemLabel="One-off questions"
        scrollBehaviorOptions={{ inline: "nearest", block: "nearest" }}
        disallowDuplicates
      />
      <Stack sx={{ gap: 1 }}>
        <Typography sx={{ color: getDynamicColor("dark5"), fontSize: 12 }}>
          Other common questions
        </Typography>
        {promptTexts.map((text) => {
          const isSelected = questions?.includes(text);
          return (
            <Stack
              key={text}
              direction="row"
              sx={{
                gap: 2,
                alignItems: "center",
                cursor: "pointer",
                px: 2.5,
                py: 1.5,
                borderRadius: "4px",
                border: `1px solid ${getDynamicColor("dark2")}`,
              }}
              onClick={() => {
                if (isSelected) {
                  handleQuestionSelectionChange(text, "remove");
                } else if (!maxQuestionsReached) {
                  handleQuestionSelectionChange(text, "add");
                }
              }}
            >
              <YoodliTooltip
                title={
                  !isSelected &&
                  maxQuestionsReached &&
                  `You may only have a maximum of ${MAX_ONE_OFF_QUESTIONS} one-off questions`
                }
              >
                <Box
                  sx={{
                    height: 28,
                    width: 28,
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => {
                    setHoveringIconId(text);
                  }}
                  onMouseLeave={() => {
                    setHoveringIconId(null);
                    setBlockIconHover({ ...blockIconHover, [text]: false });
                  }}
                  onClick={() => {
                    if (isSelected) {
                      setBlockIconHover({ ...blockIconHover, [text]: false });
                    } else {
                      setBlockIconHover({ ...blockIconHover, [text]: true });
                    }
                  }}
                >
                  <Box
                    sx={{
                      height: 20,
                      width: 20,
                      left: 4,
                      top: 4,
                      borderRadius: "50%",
                      position: "relative",
                      backgroundColor: getIconBackgroundColor(text, isSelected),
                      color: getDynamicColor("light1"),
                      transition: "background-color 0.2s ease-out",
                      svg: {
                        position: "absolute",
                        display: "block",
                        top: 1,
                        left: 1,
                        height: 18,
                        width: 18,
                        strokeWidth: 0.5,
                        stroke: getDynamicColor("light1"),
                      },
                    }}
                  >
                    {renderAddRemoveIcons(text, isSelected)}
                  </Box>
                </Box>
              </YoodliTooltip>
              <Typography>{text}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
