import { Stack, Typography } from "@mui/material";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";

import { getTimeDifference } from "lib-frontend/utils/orgUtils";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { getDynamicColor } from "lib-frontend/utils/Colors";

type MemberBlurbProps = {
  user: OrgMemberResponse;
};

export const MemberBlurb = ({ user }: MemberBlurbProps): JSX.Element => {
  if (!user?.name) {
    return null;
  }
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <YoodliAvatar name={user.name} />
      <Stack direction="column">
        <Typography
          sx={{
            fontweight: 700,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {user.name}
        </Typography>
        <Stack direction="row" gap={0.5} alignItems="center">
          <CalendarIcon
            style={{
              height: 14,
              width: 14,
              color: getDynamicColor("purple3"),
            }}
          />
          <Typography
            sx={{
              fontSize: 10,
              fontWeight: 500,
            }}
          >
            Last active: {getTimeDifference(user.date_last_activity)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
