import React from "react";
import { useNavigate } from "react-router";

// Components
import { CheckCircleRounded as SuccessIcon } from "@mui/icons-material";
import { HighlightOffRounded as ErrorIcon } from "@mui/icons-material";
import { Button, CircularProgress, Stack, SxProps, Typography } from "@mui/material";
import { LabelledValue } from "components/VideoJournal/TableComponents/TableFilter";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import OrgSectionWrapper from "../OrgSectionWrapper";
import { OrgHubsSectionStatus, findUserFallbackHub } from "./OrgHubs";
import { useMutation } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { deleteHubV2 } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { getHubUserCount } from "lib-frontend/utils/orgUtils";

type DeleteHubWizardProps = {
  backCopy: string;
  hubId: string;
  setSectionStatus: (status: OrgHubsSectionStatus) => void;
  setSelectedHub: (hub: HubV2Response) => void;
};

enum DeletionState {
  SUCCESS = "success",
  ERROR = "error",
  UNSET = "unset",
}

enum DeleteOption {
  DELETE_WITH_MIGRATION = "deleteWithMigration",
  DELETE_AND_REMOVE = "deleteAndRemove",
}

const deleteOptions: {
  label: string;
  value: string;
  optionSx?: SxProps;
}[] = [
  { value: "placeholder", label: "Select one option", optionSx: { display: "none" } },
  {
    value: DeleteOption.DELETE_WITH_MIGRATION,
    label: "Delete the group, but move all members to the Default group",
  },
  {
    value: DeleteOption.DELETE_AND_REMOVE,
    label: "Delete the group without moving members to the Default group",
  },
];

const CONFIRMATION_WORD = "DELETE";

export default function DeleteHubWizard({
  backCopy,
  hubId,
  setSectionStatus,
  setSelectedHub,
}: DeleteHubWizardProps): JSX.Element {
  const navigate = useNavigate();
  const [confirmation, setConfirmation] = React.useState<string>("");
  const [selectedDeleteOption, setSelectedDeleteOption] = React.useState<LabelledValue>(undefined);
  const [deleteState, setDeleteState] = React.useState<DeletionState>(DeletionState.UNSET);
  const { orgId, defaultOrg, invalidateDefaultOrgQuery } = React.useContext(UserOrgContext);

  React.useEffect(() => {
    // skips the delete group options if there is only one user in the group
    const tryToSkipDeleteOption = async () => {
      const userCount = await getHubUserCount(orgId, hubId);
      // just the group admin
      if (userCount === 1) {
        setSelectedDeleteOption(deleteOptions[2]);
      }
    };
    tryToSkipDeleteOption().catch(() => {
      console.error("Failed to get hub user count");
    });
  }, []);

  const handleDeleteAndRemove = async () => {
    await deleteHubV2(orgId, hubId, false);
  };

  const handleDeleteWithMigration = async () => {
    await deleteHubV2(orgId, hubId, true);
  };

  const { mutate: handleDelete, isPending: loading } = useMutation({
    mutationFn: async () => {
      switch (selectedDeleteOption.value) {
        case DeleteOption.DELETE_WITH_MIGRATION:
          await handleDeleteWithMigration();
          break;
        case DeleteOption.DELETE_AND_REMOVE:
          await handleDeleteAndRemove();
          break;
      }
      await invalidateDefaultOrgQuery();
    },
    onSuccess: () => {
      setDeleteState(DeletionState.SUCCESS);
      const selectedHub = findUserFallbackHub(defaultOrg, undefined, navigate);
      setSelectedHub(selectedHub);
      setTimeout(() => {
        handleBack();
      }, 3000);
      Instrumentation.logOrgGroupDeleted(orgId);
    },
    onError: () => {
      setDeleteState(DeletionState.ERROR);
    },
  });

  const renderCtas = () => {
    if (deleteState !== DeletionState.UNSET) {
      return null;
    }
    return (
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-end",
          pl: { xs: 2, md: 8 },
          pr: { xs: 2, md: 6 },
          gap: 5,
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleBack}>Cancel</Button>
        <Button
          startIcon={
            loading && (
              <CircularProgress
                size={20}
                sx={{
                  color: getDynamicColor("dark1"),
                }}
              />
            )
          }
          sx={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            fontFamily: "poppins",
            fontWeight: 700,
            px: 4,
            background: getDynamicColor("redError"),
            color: getDynamicColor("light1"),
            "&:hover": {
              background: getDynamicColor("redErrorDark"),
            },
            "&:disabled": {
              background: getDynamicColor("dark4"),
              color: getDynamicColor("light1"),
            },
          }}
          onClick={() => handleDelete()}
          disabled={confirmation !== CONFIRMATION_WORD || loading}
        >
          Delete
        </Button>
      </Stack>
    );
  };

  const handleBack = () => {
    setSectionStatus(OrgHubsSectionStatus.Default);
  };

  const renderContent = () => {
    if (deleteState === DeletionState.SUCCESS) {
      return (
        <Stack
          sx={{
            m: "auto",
            gap: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: getDynamicColor("greenSuccess"),
          }}
        >
          <SuccessIcon />
          <Typography>Group deleted</Typography>
        </Stack>
      );
    }
    if (deleteState === DeletionState.ERROR) {
      return (
        <Stack
          sx={{
            m: "auto",
            gap: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: getDynamicColor("redError"),
          }}
        >
          <ErrorIcon />
          <Typography>Failed to delete group</Typography>
        </Stack>
      );
    }
    return (
      <Stack
        sx={{
          px: { xs: 4, lg: 8 },
          width: { sm: "100%", lg: "50%" },
          minWidth: "fit-content",
        }}
        gap={2}
      >
        <Typography component="h2" sx={{ fontWeight: 700, fontFamily: "Poppins" }}>
          Are you sure you want to delete this group?
        </Typography>
        <Typography>
          This will delete all data from the group and remove its group admins and members.
        </Typography>
        <YoodliSelect
          labelId="delete-option-select"
          value={selectedDeleteOption?.value || "placeholder"}
          onChange={(e) =>
            setSelectedDeleteOption(deleteOptions.find((option) => option.value === e.target.value))
          }
          renderValue={(value: string) => {
            if (value === "placeholder") {
              return <Typography>Select one option</Typography>;
            } else {
              return (
                <Typography sx={{ whiteSpace: "normal" }}>{selectedDeleteOption?.label}</Typography>
              );
            }
          }}
          options={deleteOptions}
        />
        {selectedDeleteOption?.value && (
          <Stack
            color={getDynamicColor("redError")}
            fontFamily="poppins"
            fontSize="16px"
            fontWeight={400}
            gap={2}
            mt={3}
          >
            {selectedDeleteOption.value === DeleteOption.DELETE_AND_REMOVE && (
              <>
                <Typography>
                  Group admin will not be deleted, only removed from the group.
                </Typography>
                <Typography>
                  Group members who are not part of another group will be removed from this
                  organization.
                </Typography>
              </>
            )}
            <Typography sx={{ fontWeight: 600 }}>
              Type “DELETE” to confirm deletion of this group. This action is not reversible.
            </Typography>
            <YoodliTextfield
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              sx={{
                width: "min(278px, 100%)",
                color: getDynamicColor("redError"),
                border: `1px solid ${getDynamicColor("redError")}`,
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              inputProps={{
                sx: {
                  color: getDynamicColor("redError"),
                },
              }}
            />
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <OrgSectionWrapper
      backCopy={backCopy}
      loading={loading}
      ctas={renderCtas()}
      handleBack={handleBack}
      showFooter={deleteState === DeletionState.UNSET}
    >
      {renderContent()}
    </OrgSectionWrapper>
  );
}
