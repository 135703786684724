import { Button } from "@mui/material";
import React from "react";

import { Container } from "@mui/material";

import { MemberBlurb } from "./MemberBlurb";
import OrgSectionWrapper from "../OrgSectionWrapper";

import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { OverallStats } from "./MemberDashboardWidgets/OverallStats";
import { GoalProgress } from "./MemberDashboardWidgets/GoalProgress";
// import { SharedWithOrg } from "./MemberDashboardWidgets/SharedWithOrg";
import { MembershipSettings } from "./MemberDashboardWidgets/MembershipSettings";
import { WidgetWrapper } from "./MemberDashboardWidgets/WidgetWrapper";

export const SECTION_TITLE_STYLES = {
  fontSize: 14,
  fontWeight: 700,
};

type MemberDashboardProps = {
  handleBack: () => void;
  handleOpenManageRole: (user: OrgMemberResponse, fromDashboard?: boolean) => void;
  user: OrgMemberResponse;
};

export const MemberDashboard = ({
  handleBack,
  handleOpenManageRole,
  user,
}: MemberDashboardProps): JSX.Element => {
  return (
    <OrgSectionWrapper
      showFooter={false}
      backCopy="Members"
      handleBack={handleBack}
      loading={false}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: 3, md: 6 },
          px: { xs: 2, md: 6 },
          mx: 0,
        }}
      >
        <MemberBlurb user={user} />
        <WidgetWrapper title="Overall stats">
          <OverallStats user={user} />
        </WidgetWrapper>
        <WidgetWrapper title="Goal progress">
          <GoalProgress user={user} />
        </WidgetWrapper>
        {/* <WidgetWrapper title="Recordings shared with organization">
          <SharedWithOrg />
        </WidgetWrapper> */}
        <WidgetWrapper
          title="Membership settings"
          cta={
            <Button
              variant="outlined"
              onClick={() => handleOpenManageRole(user, true)}
              sx={{
                px: 3,
                py: 0.5,
                fontSize: 14,
              }}
            >
              Manage
            </Button>
          }
        >
          <MembershipSettings user={user} />
        </WidgetWrapper>
      </Container>
    </OrgSectionWrapper>
  );
};
