import parse from "html-react-parser";
import firebase from "firebase/app";
import React from "react";
import { useNavigate } from "react-router";

// Components
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Collapse, Divider, IconButton, Stack, Typography } from "@mui/material";

// Utils
import { ProgramStateIndicator } from "./ProgramStateIndicator";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getSpeechSummaryPath } from "lib-frontend/utils/SpeechHandlers";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { ProgramScenarioStep, PlanStepResult } from "lib-fullstack/api/programApiTypes";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { PlanStepState, ProgramRecordState } from "lib-fullstack/utils/enums";

type ProgramStepProgressProps = {
  step: ProgramScenarioStep;
  stepResult: PlanStepResult;
  scenario: GetScenarioResponse;
  customGoals: CustomGoalItemWithUsage[];
  userId: string;
};

export const ProgramStepProgress = ({
  step,
  stepResult,
  scenario,
  customGoals,
  userId,
}: ProgramStepProgressProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const manualGradingChipIndex = stepResult.completion_human_evaluation
    ? stepResult.attempts?.map((attempt) => attempt.org_link_sharing).lastIndexOf(true)
    : -1;

  const getTarget = () => {
    let target = "";
    if (step.completion_score) {
      target += `${step.completion_score}%`;
    }
    if (step.completion_attempts && step.completion_score) {
      target += " / ";
    }
    if (step.completion_attempts) {
      target += `${step.completion_attempts} attempt${step.completion_attempts > 1 ? "s" : ""}`;
    }
    return target;
  };

  const getScenarioGoals = () => {
    return scenario.goalIds
      .map((goalId) => {
        if (Object.keys(GoalLabels).includes(goalId)) {
          return GoalLabels[goalId];
        } else {
          return customGoals.find((goal) => goal.id === goalId)?.name;
        }
      })
      .join(", ");
  };

  const maxScore = stepResult.attempts.reduce(
    (max, attempt) => (attempt.score > max ? attempt.score : max),
    0
  );

  const getAttemptDescription = (
    speechName: string,
    speechCollabs: string[],
    attemptNum: number
  ) => {
    let description = `Attempt ${attemptNum}`;
    if (
      userId === firebase.auth().currentUser.uid ||
      speechCollabs.includes(firebase.auth().currentUser.email)
    ) {
      description += `: ${speechName}`;
    }
    return description;
  };

  return (
    <Stack
      gap={4}
      sx={{
        borderRadius: "4px",
        border: `1px solid ${getDynamicColor("dark3")}`,
        px: { xs: 2, md: 3 },
        pt: 3,
        pb: expanded ? 3 : 0,
      }}
    >
      <Stack gap={2}>
        {isSmallScreen && (
          <Stack>
            <Stack
              direction="row"
              gap={1}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: getDynamicColor("dark4"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Scenario
              </Typography>
              <IconButton
                onClick={() => setExpanded((expanded) => !expanded)}
                sx={{ color: getDynamicColor("primary"), width: 40, height: 40 }}
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {scenario.title}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" gap={1} sx={{ justifyContent: "space-between" }}>
          {!isSmallScreen && (
            <Stack gap={0.5} sx={{ width: "220px" }}>
              <Typography
                sx={{
                  color: getDynamicColor("dark4"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Scenario
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {scenario.title}
              </Typography>
            </Stack>
          )}
          <Stack gap={1} sx={{ width: "85px" }}>
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Status
            </Typography>
            <ProgramStateIndicator state={stepResult.state as PlanStepState} />
          </Stack>
          <Stack gap={0.5} sx={{ width: "120px" }}>
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Target
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 700,
              }}
            >
              {getTarget()}
            </Typography>
          </Stack>
          <Stack gap={0.5} sx={{ width: "100px" }}>
            <Typography
              sx={{
                color: getDynamicColor("dark4"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Score achieved
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: { xs: "12px", md: "14px" },
                fontWeight: 700,
              }}
            >
              {maxScore > 0 ? `${maxScore}%` : "N/A"}
            </Typography>
          </Stack>
          {!isSmallScreen && (
            <IconButton
              onClick={() => setExpanded((expanded) => !expanded)}
              sx={{ color: getDynamicColor("primary"), width: 40, height: 40 }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
              }}
            >
              <strong>Default persona: </strong>
              {isSmallScreen && <br />}
              {scenario.persona.name}, {scenario.persona.job_title} ({scenario.persona.demeanor})
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
              }}
            >
              <strong>Goals: </strong>
              {isSmallScreen && <br />}
              {scenario.goalIds.length > 0 ? getScenarioGoals() : "None"}
            </Typography>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "14px",
              }}
            >
              <strong>Description: </strong>
              {isSmallScreen && <br />}
              {parse(scenario.description)}
            </Typography>
          </Stack>
          <Stack direction="column-reverse" gap={3}>
            {stepResult.attempts.map((attempt, index) => {
              return (
                <React.Fragment key={attempt.speech_id}>
                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ justifyContent: "space-between", alignItems: "center", pr: 5 }}
                  >
                    <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                      {attempt.org_link_sharing ? (
                        <>
                          <Typography
                            sx={{
                              color: getDynamicColor("primary"),
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(getSpeechSummaryPath(attempt.speech_slug, false));
                            }}
                          >
                            {getAttemptDescription(
                              attempt.speech_name,
                              attempt.speech_collabs,
                              index + 1
                            )}
                          </Typography>
                          {stepResult.completion_human_evaluation &&
                            !attempt.completed_by_this_speech &&
                            index === manualGradingChipIndex && (
                              <Button
                                sx={{
                                  backgroundColor: getDynamicColor("redErrorLight"),
                                  color: getDynamicColor("redError"),
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  "&:hover": {
                                    backgroundColor: getDynamicColor("redErrorLight"),
                                  },
                                }}
                                onClick={() => {
                                  navigate(getSpeechSummaryPath(attempt.speech_slug, false));
                                }}
                              >
                                Requires grading
                              </Button>
                            )}
                        </>
                      ) : (
                        <Typography
                          sx={{
                            color: getDynamicColor("purple3"),
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                          }}
                        >
                          {getAttemptDescription(
                            attempt.speech_name,
                            attempt.speech_collabs,
                            index + 1
                          )}
                        </Typography>
                      )}
                      {attempt.completed_by_this_speech && (
                        <ProgramStateIndicator state={ProgramRecordState.Completed} />
                      )}
                    </Stack>
                    <Stack gap={0.5}>
                      <Typography
                        sx={{
                          color: getDynamicColor("dark4"),
                          fontFamily: "poppins",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Score achieved
                      </Typography>
                      <Typography
                        sx={{
                          color: getDynamicColor("purple3"),
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {attempt.score ? `${attempt.score}%` : "N/A"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider sx={{ borderColor: getDynamicColor("dark2") }} />
                </React.Fragment>
              );
            })}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};
