import firebase from "firebase/app";
import React from "react";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createNudgeEntInbound, listUserScenarios } from "lib-frontend/modules/AxiosInstance";
import {
  getLiveUserDocMain,
  getLiveUserDocReadonly,
  updateThisUserDocMain,
} from "lib-frontend/utils/LiveUserDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isToastmasters } from "lib-frontend/utils/subdomain";
import { getEnabledFlag } from "lib-frontend/utils/unleash";
import { GeneralAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";

export type TalkToSalesSource = "homepage" | "builder" | "speech-summary" | "checklist";

const BUILDER_SCENARIO_QUERY_KEY = "builderScenarioQueryKey";

export const useShowB2BNudgeBanner = (): {
  showBanner: boolean;
  talkToSalesPressed: boolean;
  onTalkToSalesPressed: (source: TalkToSalesSource) => void;
  onDismiss: () => void;
} => {
  const { userInOrg } = React.useContext(UserOrgContext);
  const userDocMain = getLiveUserDocMain();
  const userDocReadonly = getLiveUserDocReadonly();
  const bannerEnabled = getEnabledFlag("b2b-nudge-banner");

  const [showBanner, setShowBanner] = React.useState(false);
  const [talkToSalesPressed, setTalkToSalesPressed] = React.useState(false);

  const builderScenarioExistsQuery = useApiQuery({
    queryKey: [BUILDER_SCENARIO_QUERY_KEY],
    queryFn: async () => {
      try {
        return (await listUserScenarios()).contentArray?.length > 0 ? true : false;
      } catch (error) {
        console.error("Error checking if builder scenario exists", error);
        return false;
      }
    },
    enabled: !isToastmasters() && !!firebase.auth().currentUser,
  });

  React.useEffect(() => {
    const speechRequirementMet =
      userDocReadonly?.usedSpeeches >= 3 || builderScenarioExistsQuery?.data;
    setShowBanner(
      !isToastmasters() &&
        !userInOrg &&
        speechRequirementMet &&
        bannerEnabled &&
        (!userDocMain?.b2bBannerDismissed || talkToSalesPressed)
    );
  }, [
    userInOrg,
    bannerEnabled,
    userDocReadonly?.usedSpeeches,
    userDocMain?.b2bBannerDismissed,
    builderScenarioExistsQuery?.data,
    talkToSalesPressed,
  ]);

  const onTalkToSalesPressed = (source: TalkToSalesSource) => {
    setTalkToSalesPressed(true);
    createNudgeEntInbound().catch((error) => {
      console.error("Error sending nudge banner enterprise inbound message to slack", error);
    });
    updateThisUserDocMain({ b2bBannerDismissed: true }).catch((error) => {
      console.error("Error updating user doc main", error);
    });
    Instrumentation.logAmplitudeEvent(GeneralAnalyticsEvents.TALK_TO_SALES_NUDGE_CLICKED, {
      source,
    });
  };

  const onDismiss = () => {
    setShowBanner(false);
    updateThisUserDocMain({ b2bBannerDismissed: true }).catch((error) => {
      console.error("Error updating user doc main", error);
    });
  };

  return {
    showBanner,
    talkToSalesPressed,
    onTalkToSalesPressed,
    onDismiss,
  };
};
