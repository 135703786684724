// Components
import { Card } from "@mui/material";
import { styled } from "@mui/system";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const VideoContainerHighlightWrapper = styled(Card)`
  border: 1px solid var(--border-light-gray);
  border-radius: 12px;
  box-shadow: none;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover {
    outline: 2px solid ${getDynamicColor("secondary")};
    cursor: pointer;
  }
  & .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
