// Use this file to store resusable styles and functions used for react-slick
// so all our sliders can have the same styles (a big problem currently)

import { getDynamicColor } from "lib-frontend/utils/Colors";

export const NORMAL_DOT_COLOR = getDynamicColor("dark3");
export const SELECTED_COLOR = getDynamicColor("purple3");
export const ARROW_COLOR = getDynamicColor("dark3");

export const SLICK_DOTS_STYLES = {
  ".slick-dots li": {
    margin: "0 2px 0 2px", // Spacing between dots
    top: 2,
    position: "relative",
  },
  ".slick-dots button": {
    p: "4px !important",
    borderRadius: "50%",
    transition: "all 0.15s ease",
  },
  ".slick-dots li button:before": {
    position: "relative",
    fontSize: "13px", // Dots size
    opacity: "1",
    color: NORMAL_DOT_COLOR,
    transition: "all 0.15s ease",
  },
  ".slick-dots li.slick-active button:before": {
    color: SELECTED_COLOR,
  },
  ".slick-dots li button:hover:before": {
    color: getDynamicColor("primary"),
  },
};
