export enum BuilderSection {
  Default = "Default",
  CreateScenario = "CreateScenario",
  CreatePersona = "CreatePersona",
}

export enum BuilderTab {
  RoleplayScenarios = "Roleplay Scenarios",
  InterviewScenarios = "Interview Scenarios",
  Personas = "Personas",
}

export enum BuilderQueryKeys {
  UserScenarios = "UserScenarios",
  OrgScenarios = "OrgScenarios",
  UserPersonas = "UserPersonas",
}
