// Components
import { Stack, Button, CircularProgress } from "@mui/material";

// Utils
import { useNavDrawerOffset } from "lib-frontend/hooks";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { WIZARD_FOOTER_HEIGHT } from "utils/Constants";

type WizardFormFooterProps = {
  disabled: boolean;
  saving?: boolean;
  handleSave: () => void;
};

export const WizardFormFooter = ({
  disabled,
  saving,
  handleSave,
}: WizardFormFooterProps): JSX.Element => {
  const { navDrawerOffset, modalStyles } = useNavDrawerOffset();

  return (
    <Stack
      justifyContent="center"
      sx={{
        height: WIZARD_FOOTER_HEIGHT,
        px: 3,
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: getDynamicColor("light1"),
        boxShadow: Y_SHADOWS.upShadowLine,
        left: navDrawerOffset,
        transition: modalStyles.transition,
      }}
    >
      <Button
        variant="contained"
        size="xlarge"
        disabled={disabled}
        sx={{
          ml: "auto",
          minWidth: 100,
        }}
        onClick={handleSave}
      >
        {saving ? <CircularProgress size={20} /> : "Save"}
      </Button>
    </Stack>
  );
};
