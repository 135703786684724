import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  Check as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import React from "react";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";
import { InterviewBankResponse } from "lib-fullstack/api/hubApiTypes";
import { MAX_NUM_QUESTION_BANKS } from "components/ConvoScenarios/convoScenarioUtils";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

type QuestionBankListProps = {
  allBanks: InterviewBankResponse[];
  selectedBankIds: string[];
  handleBankSelectionChange: (bankId: string, action: "add" | "remove") => void;
  handleEditBank: (bank: InterviewBankResponse) => void;
  showSearchBar?: boolean;
};

export const QuestionBankList = ({
  allBanks,
  selectedBankIds,
  handleEditBank,
  handleBankSelectionChange,
  showSearchBar,
}: QuestionBankListProps): JSX.Element => {
  const [hoveringIconId, setHoveringIconId] = React.useState<string | null>(null);
  const [blockIconHover, setBlockIconHover] = React.useState<Record<string, boolean>>({});
  const [searchText, setSearchText] = React.useState<string>("");

  const maxQuestionBanksReached = selectedBankIds.length >= MAX_NUM_QUESTION_BANKS;

  const getIconBackgroundColor = (bankId: string, isSelected: boolean) => {
    if (hoveringIconId === bankId && isSelected && !blockIconHover[bankId]) {
      return getDynamicColor("dark4");
    }
    if (isSelected) {
      return getDynamicColor("greenSuccess");
    }
    if (maxQuestionBanksReached) {
      return getDynamicColor("dark4");
    }
    return getDynamicColor("primary");
  };

  const renderAddRemoveIcons = (bankId: string, isSelected: boolean) => {
    if (hoveringIconId === bankId && isSelected && !blockIconHover[bankId]) {
      return <RemoveIcon />;
    }
    if (isSelected) {
      return <CheckIcon />;
    }
    return <AddIcon />;
  };

  const searchFilteredBanks = React.useMemo(() => {
    return allBanks.filter((bank) => {
      return bank.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [allBanks, searchText]);

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        pt: 1,
        gap: 2,
        width: "800px",
        maxWidth: "100%",
      }}
    >
      {showSearchBar && (
        <YoodliSearchBar
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearSearch={() => setSearchText("")}
          placeholder="Search and select from your question banks"
          InputProps={{
            sx: {
              height: "40px",
            },
          }}
          sx={{
            width: "100%",
            maxWidth: "unset",
            px: 1,
          }}
        />
      )}
      <Stack
        sx={{
          gap: 1.5,
          overflow: "auto",
          maxHeight: "500px",
          transform: "max-height 0.3s ease-out",
          pb: 2,
        }}
      >
        {searchFilteredBanks.map((bank, index) => {
          const isSelected = selectedBankIds?.includes(bank.id);
          return (
            <React.Fragment key={bank.id}>
              <Stack direction="column" sx={{ width: "100%", px: 2 }}>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    gap: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    px: 2,
                  }}
                  onClick={() => {
                    if (isSelected) {
                      handleBankSelectionChange(bank.id, "remove");
                    } else if (!maxQuestionBanksReached) {
                      handleBankSelectionChange(bank.id, "add");
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    sx={{
                      width: "100%",
                      gap: 2,
                      alignItems: "center",
                      overflow: "auto",
                    }}
                  >
                    <YoodliTooltip
                      title={
                        !isSelected &&
                        maxQuestionBanksReached &&
                        `You may only have a maximum of ${MAX_NUM_QUESTION_BANKS} question banks`
                      }
                    >
                      <Box
                        sx={{
                          height: 28,
                          width: 28,
                          cursor: "pointer",
                          alignSelf: "flex-start",
                        }}
                        onMouseEnter={() => {
                          setHoveringIconId(bank.id);
                        }}
                        onMouseLeave={() => {
                          setHoveringIconId(null);
                          setBlockIconHover({ ...blockIconHover, [bank.id]: false });
                        }}
                        onClick={() => {
                          if (isSelected) {
                            setBlockIconHover({ ...blockIconHover, [bank.id]: false });
                          } else {
                            setBlockIconHover({ ...blockIconHover, [bank.id]: true });
                          }
                        }}
                      >
                        <Box
                          sx={{
                            height: 20,
                            width: 20,
                            left: 4,
                            top: 4,
                            borderRadius: "50%",
                            position: "relative",
                            backgroundColor: getIconBackgroundColor(bank.id, isSelected),
                            color: getDynamicColor("light1"),
                            transition: "background-color 0.2s ease-out",
                            svg: {
                              position: "absolute",
                              display: "block",
                              top: 1,
                              left: 1,
                              height: 18,
                              width: 18,
                              strokeWidth: 0.5,
                              stroke: getDynamicColor("light1"),
                            },
                          }}
                        >
                          {renderAddRemoveIcons(bank.id, isSelected)}
                        </Box>
                      </Box>
                    </YoodliTooltip>
                    <Stack sx={{ gap: 0.5 }}>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: 600,
                          fontSize: 14,
                          color: getDynamicColor("purple3"),
                        }}
                      >
                        {bank.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "inter",
                          fontWeight: 400,
                          fontSize: 10,
                          color: getDynamicColor("purple3"),
                          textTransform: "uppercase",
                        }}
                      >
                        {bank.interview_questions.length} question
                        {bank.interview_questions.length > 1 ? "s" : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                  <IconButton
                    sx={{
                      color: getDynamicColor("primary"),
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditBank(bank);
                    }}
                  >
                    <EditIcon sx={{ width: 24, height: 24 }} />
                  </IconButton>
                </Stack>
              </Stack>
              {index < searchFilteredBanks.length - 1 && (
                <Divider sx={{ color: getDynamicColor("dark3"), mx: 4 }} />
              )}
            </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
};
