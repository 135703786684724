import React from "react";
import { Editor, EditorContent } from "@tiptap/react";
import { SxProps, Box, Fade, Stack, Typography } from "@mui/material";
import { WYSIWYGMenuBar } from "components/WYSIWYGMenuBar";
import { getDynamicColor } from "lib-frontend/utils/Colors";

interface WYSIWYGEditorProps {
  editor: Editor | null;
  characterLimit?: number;
  mini?: boolean;
  excludeHeaderStyles?: boolean;
  sx?: SxProps;
}

export const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  editor,
  characterLimit,
  mini = false,
  excludeHeaderStyles = false,
  sx,
}) => {
  if (!editor) return null;

  return (
    <Stack gap={1} sx={{ flexGrow: 1, ...sx }}>
      <WYSIWYGMenuBar mini={mini} editor={editor} excludeHeaderStyles={excludeHeaderStyles} />

      <Stack gap={0.5} sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            border: "none !important",
            borderRadius: "8px",
            height: "100%",
            outline: "none !important",
            flexGrow: 1,

            ".tiptap": {
              maxHeight: 300,
              wordBreak: "break-word",
              p: 2,
              border: `1px solid ${getDynamicColor("dark3")}`,
              borderRadius: "4px",
              backgroundColor: getDynamicColor("light1"),
              "&:focus": {
                outline: "none !important",
                border: `1px solid ${getDynamicColor("primary")}`,
              },
              "& p": {
                my: 0,
              },
            },
          }}
        >
          <EditorContent editor={editor} style={{ height: "100%", fontSize: mini ? 12 : 16 }} />
        </Box>

        {characterLimit && (
          <Fade in={editor.isFocused}>
            <Typography
              sx={{
                fontSize: 12,
                color: getDynamicColor(
                  editor.storage.characterCount.characters() >= characterLimit
                    ? "redError"
                    : "dark4"
                ),
                position: "relative",
                top: 0,
                right: 0,
              }}
            >
              {editor.storage.characterCount.characters()} / {characterLimit}
            </Typography>
          </Fade>
        )}
      </Stack>
    </Stack>
  );
};
