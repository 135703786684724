import chroma from "chroma-js";

// Assets
import { ReactComponent as LightbulbIcon } from "images/icons/discovery-gradient.svg";
import { ReactComponent as HeadsetIcon } from "images/icons/icon-headset.svg";
import { ReactComponent as InboundIcon } from "images/icons/icon-inbound.svg";
import { ReactComponent as MicIcon } from "images/icons/icon-mic.svg";
import { ReactComponent as NetworkingIcon } from "images/icons/icon-networking.svg";
import { ReactComponent as OutboundIcon } from "images/icons/icon-outbound.svg";
import { ReactComponent as SearchPersonIcon } from "images/icons/icon-searchperson.svg";
import { ReactComponent as SmallTalkIcon } from "images/icons/icon-small-talk.svg";
import { ReactComponent as TrophyIcon } from "images/icons/icon-trophy.svg";
import { ReactComponent as MegaphoneIcon } from "images/icons/megaphone.svg";
import { ReactComponent as SnowflakeIcon } from "images/icons/snowflake-gradient.svg";
import { ReactComponent as TechnicalInterviewIcon } from "images/icons/icon-technicalinterview.svg";
import { ReactComponent as BehavioralInterviewIcon } from "images/icons/icon-behavioralinterview.svg";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import {
  GetScenarioLimitedResponse,
  GetScenarioResponse,
  InterviewBankResponse,
} from "lib-fullstack/api/hubApiTypes";
import {
  CreateBinaryGoalRequest,
  CreateCompoundGoalRequest,
  CreateScoreGoalRequest,
  CustomGoalsMemberViewResponse,
  PersonaMemberViewResponse,
  PersonaResponse,
} from "lib-fullstack/api/scenarioApiTypes";
import {
  DemeanorEnum,
  ScenarioTemplateSubType,
  GoalKind,
  ScenarioTypeIdEnum,
} from "lib-fullstack/db";
import { AvailableContentTabEnum, CustomizePracticeTabEnum } from "lib-frontend/utils/orgUtils";
import { BuilderTab } from "components/Builder/BuilderTypes";
import { ScenarioType } from "lib-fullstack/utils/enums";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";

export enum FPRScenarioQueryKey {
  Scenario = "fprScenarios",
  Persona = "fprPersonas",
  CustomGoals = "fprCustomGoals",
}

export enum CustomizePracticeQueryKey {
  Scenarios = "customizePracticeScenarios",
  Personas = "customizePracticePersonas",
  InterviewBanks = "customizePracticeInterviewBanks",
  OrgProfilePictures = "customizeOrgPracticeProfilePictures",
  UserProfilePictures = "customizeUserPracticeProfilePictures",
  CustomGoals = "customizePracticeCustomGoals",
  Coachbots = "customizePracticeCoachbots",
}

export enum AvailableContentQueryKey {
  CoursesAndVideos = "availableContentCoursesAndVideos",
  Scenarios = "availableContentScenarios",
  Personas = "availableContentPersonas",
  InterviewBanks = "availableContentInterviewBanks",
}

export const MAX_TALKING_POINTS = 7;
export const MAX_CHAR_LENGTH_TALKING_POINT = 500;
export const MAX_AI_CONCERNS = 15;
// used for interview scenarios
export const MAX_ONE_OFF_QUESTIONS = 10;
export const MAX_NUM_QUESTION_BANKS = 3;

export const MAX_DND_INPUT_LENGTH = 200;

export const MAX_SCENARIO_DESCRIPTION_LENGTH = 1000;

export type WizardState = "edit" | "duplicate" | "create";

export const DEFAULT_PERSONA: PersonaResponse = {
  id: "",
  name: "",
  job_title: "",
  voice_id: "",
  additional_background: [],
  demeanor: DemeanorEnum.Friendly,
  is_active: true,
  is_template: false,
  is_user_persona: false,
  created_at: "",
  updated_at: "",
};

export const DEFAULT_INTERVIEW_BANK: InterviewBankResponse = {
  id: "",
  name: "",
  createdByEmail: "",
  description: null,
  interview_questions: [],
  org_name: "",
  available_hubs: [],
};

export const CUSTOM_GOAL_FLOW_LENGTH = 2;

export const CUSTOM_GOAL_NAME_MAX_CHARS = 100;

export const CUSTOM_GOAL_USER_DESC_MAX_CHARS = 300;

export const CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS = 1000;

export const CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS = 500;

export const CUSTOM_GOAL_MAX_SCORE = 10;

export const CUSTOM_GOAL_MIN_SCORE = 1;

export const CUSTOM_GOAL_TEXTFIELD_MIN_ROWS = 3;

export const CUSTOM_GOAL_DEFAULT_MAX_SCORE = 5;

export const binaryGoalBase: CreateBinaryGoalRequest = {
  goalKind: GoalKind.BinaryGoal,
  name: "",
  userDescription: null, // user facing
  aiDescription: "",
  lowScoreDescription: "",
  highScoreDescription: "",
};

export const ratedGoalBase: CreateScoreGoalRequest = {
  goalKind: GoalKind.ScoreGoal,
  name: "",
  userDescription: null, // user facing
  aiDescription: "",
  lowScoreDescription: "",
  highScoreDescription: "",
  maxScore: Number(localStorage.getItem("customGoalMaxScore") ?? CUSTOM_GOAL_DEFAULT_MAX_SCORE),
};

export const compoundGoalBase: CreateCompoundGoalRequest = {
  goalKind: GoalKind.CompoundGoal,
  name: "",
  userDescription: null, // user facing
  subGoals: [],
  maxScore: Number(localStorage.getItem("customGoalMaxScore") ?? CUSTOM_GOAL_DEFAULT_MAX_SCORE),
};

export const subGoalBase = {
  name: "",
  aiDescription: "",
  highScoreDescription: "",
  lowScoreDescription: "",
};

export const TEMPLATE_SUB_TYPE_ICON_STYLES = {
  minWidth: 36,
  minHeight: 24,
  height: 24,
  width: 36,
  svg: {
    width: 24,
  },
};

export enum CreateConvoScenarioStepId {
  JOB_DETAILS = "job_details",
  PROMPT = "prompt",
  PERSONA = "persona",
  GOALS = "goals",
  TALKING_POINTS = "talking_points",
  OBJECTIONS = "objections",
  ADDITIONAL_CONTEXT = "additional_context",
  ASSIGN_TO_HUBS = "assign_to_hubs",
  ASSIGN_COACHBOT = "assign_coachbot",
  REVIEW = "review",
  DONE = "done",
}

export type GoalTalkTime = {
  minutes: number;
  seconds: number;
};

export type ConvoScenarioStateProps = {
  convoScenarios: GetScenarioLimitedResponse[];
  setConvoScenarios: React.Dispatch<React.SetStateAction<GetScenarioLimitedResponse[]>>;
  selectedConvoScenario: GetScenarioLimitedResponse;
  setSelectedConvoScenario: React.Dispatch<React.SetStateAction<GetScenarioLimitedResponse>>;
  usingConvoScenario: boolean;
  setUsingConvoScenario: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};

export type ConvoPersonaStateProps = {
  scenarioPersonas: PersonaMemberViewResponse[];
  setScenarioPersonas: React.Dispatch<React.SetStateAction<PersonaMemberViewResponse[]>>;
  selectedScenarioPersona: PersonaMemberViewResponse;
  setSelectedScenarioPersona: React.Dispatch<React.SetStateAction<PersonaMemberViewResponse>>;
  loading: boolean;
};

export type CustomGoalProps = {
  data: CustomGoalsMemberViewResponse;
  loading: boolean;
};

export enum DefaultTemplateSubType {
  COLD_SALES_CALL = "cold_sales_call",
  INBOUND_DISCOVERY_CALL = "inbound_discovery_call",
  OUTBOUND_DISCOVERY_CALL = "outbound_discovery_call",
  MANAGER_PERF_REVIEW = "manager_perf_review",
  MANAGER_SKILLS_TRAINING = "manager_skills_training",
  CUSTOMER_SUPPORT = "customer_support",
  MEDIA_TRAINING = "media_training",
  GENERIC = "generic",
  SMALL_TALK = "small_talk",
  PATIENT_COUNSELING = "patient_counseling",
  NETWORKING = "networking",
  BEHAVIORAL_INTERVIEW = "behavioral_interview",
  TECHNICAL_INTERVIEW = "technical_interview",
}

export const TEMPLATE_SUB_TYPE_DATA = {
  [ScenarioTemplateSubType.COLD_CALL]: {
    icon: <SnowflakeIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Cold Sales Call",
    scenarioTypeLabel: "Sales Call",
    color: getDynamicColor("coldCall"),
  },
  [ScenarioTemplateSubType.INBOUND_DISCOVERY_CALL]: {
    icon: <InboundIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Inbound Discovery Call",
    scenarioTypeLabel: "Discovery Call",
    color: getDynamicColor("warmCall", 0.75),
  },
  [ScenarioTemplateSubType.OUTBOUND_DISCOVERY_CALL]: {
    icon: <OutboundIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Outbound Discovery Call",
    scenarioTypeLabel: "Discovery Call",
    color: getDynamicColor("secondaryLight"),
  },
  // Manager Training
  [ScenarioTemplateSubType.MANAGER_PERF_REVIEW]: {
    icon: <SearchPersonIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Manager Performance Review",
    scenarioTypeLabel: "Performance Review",
    color: getDynamicColor("chartPurpleLight", 0.2),
  },
  [ScenarioTemplateSubType.MANAGER_SKILLS_TRAINING]: {
    icon: <TrophyIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Manager Skills Training",
    scenarioTypeLabel: "Skills Training Session",
    color: getDynamicColor("focusArea.presence", 0.35),
  },
  // Other
  [ScenarioTemplateSubType.CUSTOMER_SUPPORT]: {
    icon: <HeadsetIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Customer Support Call",
    scenarioTypeLabel: "Customer Support Call",
    color: getDynamicColor("coldCall"),
  },
  [ScenarioTemplateSubType.MEDIA_TRAINING]: {
    icon: <MicIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Media Training",
    scenarioTypeLabel: "Media Training",
    color: getDynamicColor("coldCall"),
  },
  [ScenarioTemplateSubType.GENERIC]: {
    icon: <LightbulbIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Generic Scenario",
    scenarioTypeLabel: "Generic",
    color: getDynamicColor("purple1"),
  },
  [ScenarioTemplateSubType.PITCH]: {
    icon: <MegaphoneIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Pitch",
    scenarioTypeLabel: "Pitch",
    color: getDynamicColor("orangeWarningLight"),
  },
  [ScenarioTemplateSubType.SMALL_TALK]: {
    icon: <SmallTalkIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Small Talk",
    scenarioTypeLabel: "Small Talk",
    color: getDynamicColor("yellowWarningLight"),
  },
  [ScenarioTemplateSubType.PATIENT_COUNSELING]: {
    icon: <SearchPersonIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Patient Counseling",
    scenarioTypeLabel: "Patient Counseling",
    color: getDynamicColor("chartPurpleLight", 0.2),
  },
  [ScenarioTemplateSubType.NETWORKING]: {
    icon: <NetworkingIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Networking",
    scenarioTypeLabel: "Networking",
    color: getDynamicColor("orangeWarningLight"),
  },
  [ScenarioTemplateSubType.BEHAVIORAL_INTERVIEW]: {
    icon: <BehavioralInterviewIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Behavioral Interview",
    scenarioTypeLabel: "Behavioral Interview",
    color: getDynamicColor("pink", 0.5),
  },
  [ScenarioTemplateSubType.TECHNICAL_INTERVIEW]: {
    icon: <TechnicalInterviewIcon style={TEMPLATE_SUB_TYPE_ICON_STYLES} />,
    label: "Technical Interview",
    scenarioTypeLabel: "Technical Interview",
    color: getDynamicColor("pink", 0.5),
  },
};

export const DemeanorDescriptions: { [key in DemeanorEnum]: string } = {
  friendly: "Easily approachable and pleasant",
  critical: "Frequently points out flaws and areas of improvement",
  empathetic: "Highly sensitive to others' feelings and emotions",
  enthusiastic: "Shows intense excitement and eagerness",
  analytical: "Focuses on logic and detailed problem-solving",
  curious: "Eager to learn and ask questions",
  assertive: "Confident and self-assured",
  blunt: "Direct and to the point",
  skeptical: "Wary of new solutions and ideas",
  neutral: "Balanced and objective in communication style",
  casual: "Relaxed and conversational in tone",
};

export const GoalKindLabels: { [key in GoalKind]: string } = {
  [GoalKind.BinaryGoal]: "Binary Goal",
  [GoalKind.ScoreGoal]: "Rated Goal",
  [GoalKind.CompoundGoal]: "Compound Goal",
  [GoalKind.TPGoal]: "Talking Point Goal",
};

export const GoalKindDefinitions: { [key in GoalKind]: string } = {
  [GoalKind.BinaryGoal]: "Can be achieved / not achieved",
  [GoalKind.ScoreGoal]: "Scored on a scale of 1-5",
  [GoalKind.CompoundGoal]: "Consists of multiple sub-goals.",
  [GoalKind.TPGoal]: "Hit specific talking points.",
};

export const CustomGoalLabels: { [key in GoalKind]: string } = {
  [GoalKind.BinaryGoal]: "Binary Goal",
  [GoalKind.ScoreGoal]: "Rated Goal",
  [GoalKind.CompoundGoal]: "Compound Goal",
  [GoalKind.TPGoal]: "Talking Point Goal",
};

export const DEMEANOR_COLORS = chroma
  .scale([getDynamicColor("primary", 0.3), getDynamicColor("secondary", 0.2)])
  .mode("lch")
  .colors(10);

export const getDemeanorColor = (demeanor = "default"): string => {
  let hash = 0;
  for (let i = 0; i < demeanor.length; i++) {
    const char = demeanor.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  const index = Math.abs(hash) % DEMEANOR_COLORS.length;
  return DEMEANOR_COLORS[index];
};

export const convertGoalTalkTimeToS = (talkTime: GoalTalkTime): number => {
  return talkTime.minutes * 60 + talkTime.seconds;
};

// Returns array of percentages that sum to total weight, giving remainder to first element
export const distributeWeightEvenly = (
  arr: string[],
  totalWeight: number
): { [key: string]: number } => {
  const length = arr.length;
  if (length === 0) return undefined;

  const basePercentage = Math.floor(Math.max(totalWeight, 0) / length);
  const remainder = totalWeight % length;

  return arr.reduce((acc, element, index) => {
    acc[element] = index === 0 ? basePercentage + remainder : basePercentage;
    return acc;
  }, {} as Record<string, number>);
};

export const normalizeWeights = (weights: { [key: string]: number }): { [key: string]: number } => {
  const totalWeight = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
  const scaleFactor = 100 / totalWeight;

  const normalizedWeights: Record<string, number> = {};
  let remainingWeight = 100;
  const keys = Object.keys(weights);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      normalizedWeights[key] = remainingWeight;
    } else {
      const newWeight = Math.floor(weights[key] * scaleFactor);
      normalizedWeights[key] = newWeight;
      remainingWeight -= newWeight;
    }
  }
  return normalizedWeights;
};

export const filterAndMergeArr = (arr1: string[], arr2: string[]): string[] => {
  const uniqueArr2Elements = arr2.filter((item) => !arr1.includes(item));
  return arr1.filter((item) => arr2.includes(item)).concat(uniqueArr2Elements);
};

export const getScenarioTypeLabel = (
  type: AvailableContentTabEnum | CustomizePracticeTabEnum | BuilderTab | ScenarioType | string
): string => {
  switch (type) {
    case ScenarioType.Interview:
    case BuilderTab.InterviewScenarios:
    case AvailableContentTabEnum.InterviewScenarios:
      return ScenarioType.Interview;
    default:
    case ScenarioType.Roleplay:
    case BuilderTab.RoleplayScenarios:
    case AvailableContentTabEnum.RoleplayScenarios:
      return ScenarioType.Roleplay;
  }
};

export const getFilteredScenarioByType = <
  T extends GetScenarioResponse | GetScenarioLimitedResponse
>(
  scenarioTabType: AvailableContentTabEnum | CustomizePracticeTabEnum | BuilderTab | string,
  scenarioList: T[]
): T[] => {
  const scenarioTypeLabel = getScenarioTypeLabel(scenarioTabType);
  return scenarioList.filter(
    (scenario) => scenarioTypeMap[scenario.scenarioTypeId] === scenarioTypeLabel
  );
};

export type GoalHooks = {
  goalDescriptionLoading: boolean;
  setGoalDescriptionLoading: React.Dispatch<React.SetStateAction<boolean>>;
  goalHighScoreLoading: boolean;
  setGoalHighScoreLoading: React.Dispatch<React.SetStateAction<boolean>>;
  goalLowScoreLoading: boolean;
  setGoalLowScoreLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export enum TemplateHeading {
  SALES = "Sales",
  MANAGER_TRAINING = "Manager Training",
  OTHER = "Other",
  INTERVIEW = "Interview",
}

export enum TemplateFilter {
  SALES = "Sales",
  MANAGER_TRAINING = "Manager Training",
  OTHER = "Other",
  BEHAVIORAL_INTERVIEW = "Behavioral Interview",
  TECHNICAL_INTERVIEW = "Technical Interview",
}

export const templateHeadingMap = {
  [ScenarioTypeIdEnum.SALES_CALL]: TemplateHeading.SALES,
  [ScenarioTypeIdEnum.COLD_CALL]: TemplateHeading.SALES,
  [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: TemplateHeading.MANAGER_TRAINING,
  [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: TemplateHeading.MANAGER_TRAINING,
  [ScenarioTypeIdEnum.MEDIA_TRAINING]: TemplateHeading.OTHER,
  [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: TemplateHeading.OTHER,
  [ScenarioTypeIdEnum.SMALL_TALK]: TemplateHeading.OTHER,
  [ScenarioTypeIdEnum.PATIENT_COUNSELING]: TemplateHeading.OTHER,
  [ScenarioTypeIdEnum.NETWORKING]: TemplateHeading.OTHER,
  [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: TemplateHeading.INTERVIEW,
  [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: TemplateHeading.INTERVIEW,
};

export const templateFilterMap = {
  [ScenarioTypeIdEnum.SALES_CALL]: TemplateFilter.SALES,
  [ScenarioTypeIdEnum.COLD_CALL]: TemplateFilter.SALES,
  [ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: TemplateFilter.MANAGER_TRAINING,
  [ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: TemplateFilter.MANAGER_TRAINING,
  [ScenarioTypeIdEnum.MEDIA_TRAINING]: TemplateFilter.OTHER,
  [ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: TemplateFilter.OTHER,
  [ScenarioTypeIdEnum.SMALL_TALK]: TemplateFilter.OTHER,
  [ScenarioTypeIdEnum.PATIENT_COUNSELING]: TemplateFilter.OTHER,
  [ScenarioTypeIdEnum.NETWORKING]: TemplateFilter.OTHER,
  [ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: TemplateFilter.BEHAVIORAL_INTERVIEW,
  [ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: TemplateFilter.TECHNICAL_INTERVIEW,
};
