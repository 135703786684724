import { DateTime } from "luxon"; // This is the only Luxon import we need
import React from "react";
import {
  Button,
  CalendarCell,
  CalendarGrid,
  // DateInput,
  DateRangePicker,
  // DateSegment,
  Dialog,
  Group,
  Heading,
  // Label,
  Popover,
  RangeCalendar,
} from "react-aria-components";
import type { DateRangePickerProps, DateValue, ValidationResult } from "react-aria-components";
import { FieldError, Text } from "react-aria-components";

// Components
import {
  ArrowDropDownCircle as ArrowDropDownCircleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { Box, Stack, Button as MuiButton } from "@mui/material";

// Utils
import { getDynamicColor, Y_SHADOWS } from "../../utils/Colors";
import { useIsSmallScreen } from "../../utils/themeUtils";
import {
  DEFAULT_DATE_PICKER_WRAPPER_STYLES,
  DEFAULT_CALENDAR_STYLES,
} from "./YoodliDatePickerStyles";
import { parseDate } from "@internationalized/date";

interface MyDateRangePickerProps<T extends DateValue> extends DateRangePickerProps<T> {
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  dateRangeBank?: number[];
  onDateRangeSelected?: (numDays: number) => void;
}

function MyDateRangePicker<T extends DateValue>({
  description,
  errorMessage,
  dateRangeBank,
  onDateRangeSelected,
  ...props
}: MyDateRangePickerProps<T>) {
  const formatDate = (date: DateValue) => {
    const luxonDate = DateTime.fromISO(date.toString());
    return luxonDate.toFormat("dd MMM yy");
  };

  const isSmallScreen = useIsSmallScreen();

  return (
    <DateRangePicker {...props} aria-label="Date Range Picker">
      <Box
        sx={{
          color: getDynamicColor("purple3"),
          "& .control-button": {
            minWidth: "200px",
            border: `1px solid ${getDynamicColor("dark4")}`,
            borderRadius: "4px",
            px: 2,
            py: 1.5,
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            gap: "8px",
            cursor: "pointer",
            background: "white",
          },
        }}
      >
        <Group>
          <Button className="control-button" slot="trigger">
            <Stack direction="row" gap={1}>
              {props.value?.start && <span>{formatDate(props.value.start)}</span>}
              <span aria-hidden="true">–</span>
              {props.value?.end && <span>{formatDate(props.value.end)}</span>}
            </Stack>
            <ArrowDropDownCircleIcon
              sx={{
                color: getDynamicColor("primary"),
              }}
            />
          </Button>
        </Group>
      </Box>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover
        offset={8}
        placement="bottom end"
        style={{
          backgroundColor: getDynamicColor("light1"),
          borderRadius: "8px",
          boxShadow: Y_SHADOWS.date_picker_popover,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "16px",
          gap: "16px",
          height: 260,
        }}
      >
        {dateRangeBank?.length > 0 && (
          <Stack
            direction="column"
            gap={1}
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{
              height: "100%",
            }}
          >
            <Stack direction="column" gap={1} alignItems="flex-start" justifyContent="flex-start">
              {dateRangeBank?.map((date) => {
                const startDate = DateTime.fromObject({
                  year: props.value?.start?.year,
                  month: props.value?.start?.month,
                  day: props.value?.start?.day,
                });
                const endDate = DateTime.fromObject({
                  year: props.value?.end?.year,
                  month: props.value?.end?.month,
                  day: props.value?.end?.day,
                });

                const isSelected =
                  props.value?.start &&
                  props.value?.end &&
                  startDate.plus({ days: date }).equals(endDate);

                return (
                  <MuiButton
                    key={date}
                    onClick={() => {
                      onDateRangeSelected?.(date);
                      props.onChange?.({
                        start: DateTime.now().minus({ days: date }).toFormat("yyyy-MM-dd"),
                        end: DateTime.now().toFormat("yyyy-MM-dd"),
                      });
                    }}
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      backgroundColor: getDynamicColor("dark2"),
                      color: getDynamicColor("purple3"),
                      ...(isSelected && {
                        backgroundColor: getDynamicColor("secondaryLight"),
                        color: getDynamicColor("secondaryHover"),
                      }),
                      "&:hover": {
                        backgroundColor: getDynamicColor("dark3"),
                        ...(isSelected && {
                          backgroundColor: getDynamicColor("secondaryLight"),
                          color: getDynamicColor("secondaryHover"),
                        }),
                      },
                    }}
                  >
                    {isSmallScreen ? "" : "Last "}
                    {date} days
                  </MuiButton>
                );
              })}
            </Stack>
            <MuiButton
              variant="text"
              sx={{
                fontSize: 12,
              }}
              onClick={() => {
                props.onChange?.({
                  start: DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd"),
                  end: DateTime.now().toFormat("yyyy-MM-dd"),
                });
              }}
            >
              Reset
            </MuiButton>
          </Stack>
        )}
        <Dialog>
          <Box sx={DEFAULT_CALENDAR_STYLES}>
            <RangeCalendar
              className="range-calendar"
              maxValue={parseDate(DateTime.now().toFormat("yyyy-MM-dd"))}
              defaultFocusedValue={props.value?.end}
            >
              <header className="month-header">
                <Button slot="previous" className="month-button">
                  <ChevronLeftIcon />
                </Button>
                <Heading />
                <Button slot="next" className="month-button">
                  <ChevronRightIcon />
                </Button>
              </header>
              <CalendarGrid>
                {(date) => {
                  // TODO: currently come bugs with this but it looks fine as is, just needs some zhuzhing
                  // let isStart = false;
                  // let isEnd = false;
                  let isToday = false;
                  // Check if this is a selected date
                  // if (props.value?.start && props.value?.end) {
                  //   isStart = date.compare(props.value.start) === 0;
                  //   isEnd = date.compare(props.value.end) === 0;
                  // }
                  if (
                    date.day === DateTime.now().day &&
                    date.month === DateTime.now().month &&
                    date.year === DateTime.now().year
                  ) {
                    isToday = true;
                  }
                  return (
                    <CalendarCell
                      date={date}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        ...(isToday && {
                          color: getDynamicColor("secondary"),
                        }),
                      }}
                    />
                  );
                }}
              </CalendarGrid>
            </RangeCalendar>
          </Box>
        </Dialog>
      </Popover>
    </DateRangePicker>
  );
}

type YoodliDatePickerProps = {
  startDate: string;
  endDate: string;
  onChange: (startDate: string, endDate: string) => void;
  dateRangeBank?: number[];
  onDateRangeSelected?: (numDays: number) => void;
};

export const YoodliDatePicker = ({
  startDate,
  endDate,
  onChange,
  dateRangeBank,
  onDateRangeSelected,
}: YoodliDatePickerProps): JSX.Element => {
  const [localStartDate, setLocalStartDate] = React.useState(startDate);
  const [localEndDate, setLocalEndDate] = React.useState(endDate);
  React.useEffect(() => {
    if (localStartDate && localEndDate) {
      onChange(localStartDate, localEndDate);
    }
  }, [localStartDate, localEndDate]);
  return (
    <Box sx={DEFAULT_DATE_PICKER_WRAPPER_STYLES}>
      <MyDateRangePicker
        value={{
          start: parseDate(localStartDate),
          end: parseDate(localEndDate),
        }}
        onChange={(value) => {
          setLocalStartDate(value.start.toString());
          setLocalEndDate(value.end.toString());
        }}
        dateRangeBank={dateRangeBank}
        onDateRangeSelected={onDateRangeSelected}
      />
    </Box>
  );
};
