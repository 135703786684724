import React from "react";

// Components
import { Box, Button, Stack } from "@mui/material";

// Utils
import { TabLabels } from "./CustomizePracticeTab";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { AvailableContentTabEnum, CustomizePracticeTabEnum } from "lib-frontend/utils/orgUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";

export type TabSelectorType = CustomizePracticeTabEnum | AvailableContentTabEnum;

type CustomizePracticeTabSelectorProps = {
  tabs: TabSelectorType[];
  activeTab: TabSelectorType;
  setActiveTab: (tab: TabSelectorType) => void;
  tabLabels?: Record<TabSelectorType, string>;
};

export const CustomizePracticeTabSelector = ({
  tabs,
  activeTab,
  setActiveTab,
  tabLabels,
}: CustomizePracticeTabSelectorProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  const tabStyles = {
    [3]: {
      containerWidth: "min(570px, calc(100% - 16px))",
      shadowWidth: "min(33.333%, 196px)",
    },
    [4]: {
      containerWidth: "min(680px, calc(100% - 16px))",
      shadowWidth: "min(25%, 180px)",
    },
    [5]: {
      containerWidth: "min(850px, calc(100% - 16px))",
      shadowWidth: "min(20%, 180px)",
    },
  };

  if (isSmallScreen) {
    return (
      <Box sx={{ width: "100%", px: { xs: 1, sm: 2 }, mt: 2 }}>
        <YoodliSelect
          MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" } }}
          value={activeTab}
          onChange={(e) => {
            setActiveTab(e.target.value as TabSelectorType);
          }}
          options={tabs.map((tab) => {
            return { value: tab, label: tabLabels ? tabLabels[tab] : TabLabels[tab] };
          })}
          sx={{
            background: getDynamicColor("light1"),
            border: `1px solid ${getDynamicColor("primary")}`,
            minWidth: { xs: "fit-content", md: 200 },
          }}
          inputProps={{
            sx: {
              ".selected": {
                p: 0,
                fontWeight: 600,
                ".label": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
              p: 1.5,
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{
        boxShadow: Y_SHADOWS.dark_elevation,
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
        width: tabStyles[tabs.length].containerWidth,
        mx: { xs: 1, sm: "auto", md: 0 },
        mt: { xs: 2, md: 0 },
      }}
    >
      <Box
        sx={{
          width: tabStyles[tabs.length].shadowWidth,
          height: "100%",
          boxShadow: Y_SHADOWS.box_shadow_1,
          backgroundColor: getDynamicColor("light2"),
          borderRadius: "4px",
          position: "absolute",
          left: tabs.findIndex((tab) => tab === activeTab) * (100 / tabs.length) + "%",
          transition: "left 0.5s cubic-bezier(.47,1.64,.41,.8)",
        }}
      />
      {tabs.map((tab) => {
        return (
          <Button
            key={tab}
            onClick={() => setActiveTab(tab)}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              backgroundColor: "transparent",
              borderRadius: "4px",
              width: 196,
              minHeight: 48,
              py: { xs: 1, sm: 2 },
              fontWeight: activeTab === tab ? 700 : 500,
              fontSize: 14,
              lineHeight: 1.2,
              color: getDynamicColor(activeTab === tab ? "primary" : "purple3"),
              transition: "color 0.3s, font-weight 0s",
              "&:hover": {
                color: getDynamicColor("primary"),
                backgroundColor: "transparent",
              },
            }}
          >
            {tabLabels ? tabLabels[tab] : TabLabels[tab]}
          </Button>
        );
      })}
    </Stack>
  );
};
