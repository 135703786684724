import React, { useEffect } from "react";

// Components
import { VolumeOff, VolumeUp } from "@mui/icons-material";
import {
  IconButton,
  Button,
  Popover,
  MenuList,
  MenuItem,
  Slider,
  Box,
  Collapse,
} from "@mui/material";

// Utils
import { Y_SHADOWS } from "lib-frontend/utils/Colors";

const playbackSpeeds = {
  "0.5x": 0.5,
  "0.75x": 0.75,
  "1x": 1,
  "1.25x": 1.25,
  "1.5x": 1.5,
  "2x": 2,
  "3x": 3,
};

export const VolumeSlider = (props: {
  muted: boolean;
  isAudio: boolean;
  volume: number;
  setVolume: (event: Event) => void;
  controlsHovered: boolean;
  toggleMuted: React.MouseEventHandler<HTMLButtonElement>;
}): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (!props.controlsHovered) {
      handleMouseLeave();
    }
  }, [props.controlsHovered]);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <IconButton size="large" onClick={props.toggleMuted}>
        {!props.muted ? <VolumeUp /> : <VolumeOff />}
      </IconButton>
      <Collapse orientation="horizontal" in={open}>
        <Slider
          aria-label="Volume"
          sx={{
            width: { xs: "60px", lg: "80px", xl: "100px" },
            mx: 1.5,
            mt: 0.5,
            color: "white",
            ".MuiSlider-thumb": {
              boxShadow: 0,
            },
          }}
          orientation="horizontal"
          step={0.05}
          min={0}
          max={1}
          value={props.volume}
          onChange={props.setVolume}
          defaultValue={1}
          size="small"
        />
      </Collapse>
    </Box>
  );
};

export const PlaybackSpeedMenu = (props: {
  onClick: (value: number) => void;
  value: number;
  isSmall?: boolean;
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleChange = (val) => {
    props.onClick(playbackSpeeds[val]);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          color: "white",
          minWidth: !props.isSmall && "25px",
          width: props.isSmall && "fit-content",
          px: props.isSmall && "16px",
        }}
        variant="text"
        onClick={handleClick}
      >
        {props.value + "x"}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            maxWidth: "none",
            overflow: "visible",
            boxShadow: Y_SHADOWS.box_shadow_1,
            borderRadius: "8px",
            zIndex: 9001,
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          "&.MuiPopover-root": {
            zIndex: 2000,
          },
        }}
      >
        <MenuList
          sx={{
            backgroundColor: "white",
            paddingY: 0,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          {Object.keys(playbackSpeeds).map((speed, i) => {
            return (
              <MenuItem key={`playback-${i}`} onClick={() => handleChange(speed)}>
                {speed}
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </>
  );
};
