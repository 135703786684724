import {
  Lti11Integration,
  Lti13Integration,
  Scorm12Integration,
  TestIntegration,
} from "lib-fullstack/db/db_runtypes_integration";
import { IntegrationType } from "lib-fullstack/utils/enums/integrationType";
import { Record, Static, String, Array as RTArray, Optional, Boolean, Union } from "runtypes";

/**
 * Request to create a new integration for an organization.
 */
export const CreateOrgIntegrationRequest = Record({
  /**
   * Integration type.
   */
  type: IntegrationType,
  /**
   * Integration name.
   */
  name: String,
});
/**
 * Request to create a new integration for an organization.
 */
export type CreateOrgIntegrationRequest = Static<typeof CreateOrgIntegrationRequest>;

/**
 * Response to creating a new integration for an organization.
 */
export const CreateOrgIntegrationResponse = Record({
  /**
   * Integration ID.
   */
  id: String,
});
/**
 * Response to creating a new integration for an organization.
 */
export type CreateOrgIntegrationResponse = Static<typeof CreateOrgIntegrationResponse>;

/**
 * Record representing an item in the list of integrations for an organization.
 */
export const ListOrgIntegrationResponseItem = Record({
  /**
   * Integration ID.
   */
  id: String,
  /**
   * Integration type enum value.
   */
  type: IntegrationType,
  /**
   * Integration name.
   */
  name: String,
});
/**
 * Record representing an item in the list of integrations for an organization.
 */
export type ListOrgIntegrationResponseItem = Static<typeof ListOrgIntegrationResponseItem>;

/**
 * Response to getting a list of integrations for an organization.
 */
export const ListOrgIntegrationsResponse = Record({
  /**
   * List of integrations.
   */
  integrations: RTArray(ListOrgIntegrationResponseItem),
});

/**
 * Response to getting a list of integrations for an organization.
 */
export type ListOrgIntegrationsResponse = Static<typeof ListOrgIntegrationsResponse>;

/**
 * Extend the LTI 1.1 integration data with tool URLs.
 */
export const GetOrgIntegrationLti11Response = Lti11Integration.extend({
  toolUrls: Record({
    launchUrl: String,
  }),
});

/**
 * Extend the LTI 1.1 integration data with tool URLs.
 */
export type GetOrgIntegrationLti11Response = Static<typeof GetOrgIntegrationLti11Response>;

/**
 * Extend the LTI 1.3 integration data with tool URLs.
 */
export const GetOrgIntegrationLti13Response = Lti13Integration.extend({
  toolUrls: Record({
    loginUrl: String,
    keysetUrl: String,
    launchUrl: String,
  }),
});

/**
 * Extend the LTI 1.3 integration data with tool URLs.
 */
export type GetOrgIntegrationLti13Response = Static<typeof GetOrgIntegrationLti13Response>;

/**
 * Response to getting an organization's integration.
 */
export const GetOrgIntegrationResponse = Union(
  GetOrgIntegrationLti11Response,
  GetOrgIntegrationLti13Response,
  Scorm12Integration,
  TestIntegration
);

/**
 * Response to getting an organization's integration.
 */
export type GetOrgIntegrationResponse = Static<typeof GetOrgIntegrationResponse>;

/**
 * Request to update an organization's integration.
 */
export const UpdateOrgIntegrationRequest = Record({
  /**
   * Integration name.
   */
  name: Optional(String),
  /**
   * If true, enable debug-level logging for the integration. This can be used to build
   * the mock test data or enable troubleshooting live operations.
   */
  debugLogging: Optional(Boolean),
  /**
   * Key value used for indexing in the "orgIntegrations" collection group.
   */
  externalId: Optional(String),
});
/**
 * Request to update an organization's integration.
 */
export type UpdateOrgIntegrationRequest = Static<typeof UpdateOrgIntegrationRequest>;

/**
 * Request to update an organization's test integration.
 */
export const UpdateOrgTestIntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Test integration-specific field.
   */
  testField: Optional(String),
});
/**
 * Request to update an organization's test integration.
 */
export type UpdateOrgTestIntegrationRequest = Static<typeof UpdateOrgTestIntegrationRequest>;

/**
 * Request to update an organization's LTI 1.1 integration.
 */
export const UpdateOrgLti11IntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Consumer instance GUID generated by the LMS.
   * Provided by the Yoodli admin configuring the integration.
   */
  consumerInstanceGuid: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  consumerHostName: Optional(String),
});

/**
 * Request to update an organization's LTI 1.1 integration.
 */
export type UpdateOrgLti11IntegrationRequest = Static<typeof UpdateOrgLti11IntegrationRequest>;

/**
 * Request to update an organization's SCORM 1.2 integration.
 */
export const UpdateOrgScorm12IntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration.
   */
  hostNames: Optional(RTArray(String)),
});
/**
 * Request to update an organization's SCORM 1.2 integration.
 */
export type UpdateOrgScorm12IntegrationRequest = Static<typeof UpdateOrgScorm12IntegrationRequest>;

/**
 * Request to update an organization's SCORM 1.2 integration.
 */
export const UpdateOrgLti13IntegrationRequest = UpdateOrgIntegrationRequest.extend({
  /**
   * Platform ID provided by the LMS. E.g., “https://yoodli-dev.moodlecloud.com”.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformId: Optional(String),
  /**
   * Client ID provided by the LMS. Uniquely identifies the tool to the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  clientId: Optional(String),
  /**
   * Deployment ID provided by the LMS. Indicates a specific deployment of the tool within the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  deploymentId: Optional(String),
  /**
   * Keyset URL for the public keys provided by the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformPublicKeysetUrl: Optional(String),
  /**
   * Access token URL used by the tool to generate access tokens for subsequent API calls to the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformAccessTokenUrl: Optional(String),
  /**
   * Authentication request URL used by the tool to respond to the login request from the platform.
   * Provided by the Yoodli admin configuring the integration.
   */
  platformAuthenticationRequestUrl: Optional(String),
  /**
   * Host name for the LMS server which will be calling into the Yoodli
   * integration. This is required so we can validate URLs provided by the LMS
   * server before calling them. This protects against SSRF.
   */
  platformHostName: Optional(String),
});
/**
 * Request to update an organization's LTI 1.3 integration.
 */
export type UpdateOrgLti13IntegrationRequest = Static<typeof UpdateOrgLti13IntegrationRequest>;
