import React from "react";
import parse from "html-react-parser";

import Slider from "react-slick";
import { Typography, Stack, Dialog, Button, Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ReactComponent as CalendarIcon } from "images/icons/icon-calendar.svg";
import { Blobs } from "lib-frontend/components/Blobs/Blobs";
import { AnimatedGradientText } from "lib-frontend/components/AnimatedGradientText";

import { useNavDrawerOffset } from "lib-frontend/hooks";
import { FeatureUpdateResponse } from "lib-frontend/api/wordpress";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import { WhatsNewAnnouncementEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { SLICK_DOTS_STYLES } from "utils/reactSlickUtils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const findAndStripImgTags = (
  element: React.ReactNode | React.ReactNode[]
): { images: string[]; contentWithoutImages: React.ReactNode } => {
  const images: string[] = [];

  const processElement = (el: React.ReactNode): React.ReactNode => {
    // If element is an array, process each item
    if (Array.isArray(el)) {
      return el.map((child) => processElement(child)).filter(Boolean);
    }

    // If element is a React element
    if (React.isValidElement(el)) {
      // If it's an img tag, collect the src and return null
      if (el.type === "img" && el.props?.src) {
        images.push(el.props.src);
        return null;
      }

      // If it has children, process them
      if (el.props?.children) {
        const newChildren = processElement(el.props.children);

        // Return null if there are no children after processing
        if (!newChildren || (Array.isArray(newChildren) && newChildren.length === 0)) {
          return null;
        }

        return React.cloneElement(el, { ...el.props, children: newChildren });
      }

      // If no children and no text content, return null
      if (!el.props?.children && !el.props?.dangerouslySetInnerHTML?.__html) {
        return null;
      }

      return el;
    }

    // If it's just text content, return it if non-empty
    if (typeof el === "string" && el.trim() === "") {
      return null;
    }

    return el;
  };

  const contentWithoutImages = processElement(element);

  return {
    images,
    contentWithoutImages,
  };
};

export const splitContentIntoArticles = (content: string): { title: string; content: string }[] => {
  // Split on opening h1 tags, but keep the h1 tag with the content that follows it
  const sections = content
    .split(/<h1/i)
    .filter(Boolean)
    .map((section, i) =>
      i === 0 && !content.trim().toLowerCase().startsWith("<h1") ? section : "<h1" + section
    );

  return sections.map((section) => {
    // Extract title from h1 tag if present
    const titleMatch = section.match(/<h1[^>]*>(.*?)<\/h1>/i);
    const title = titleMatch ? titleMatch[1] : `Slide ${sections.indexOf(section) + 1}`;

    return {
      title, // Will be used as key and uid
      content: section, // Keeps the full content including h1 tag
    };
  });
};

export const DEFAULT_SLIDER_SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  loop: true,
};

export const FeatureUpdateModal = ({
  featureUpdate,
  open,
  onClose,
}: {
  featureUpdate: FeatureUpdateResponse;
  open: boolean;
  onClose: () => Promise<void>;
}): JSX.Element => {
  React.useEffect(() => {
    if (open) {
      Instrumentation.logAmplitudeEvent(WhatsNewAnnouncementEvents.POPUP_VIEWED);
    }
  }, [open]);
  const { modalStyles } = useNavDrawerOffset();

  const updates = React.useMemo(
    () => splitContentIntoArticles(featureUpdate?.content ?? ""),
    [featureUpdate?.content]
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={async () => await onClose()}
      PaperProps={{
        sx: {
          position: "relative",
          overflow: "hidden",
          borderRadius: "12px",
          width: {
            xs: "100%",
            sm: "min(100%, 600px)",
            lg: "min(100%, 700px)",
          },
          maxWidth: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          maxHeight: { xs: "calc(100% - 24px) !important", md: "calc(100% - 48px) !important" },
          m: { xs: 1.5, md: 3 },
          ".slick-list": {
            // target all child divs
            "& div": {
              outline: "none !important",
            },
          },
          ...SLICK_DOTS_STYLES,
          ".slick-dots": {
            ...SLICK_DOTS_STYLES[".slick-dots"],
            bottom: -8,
          },
          ".slick-slide": {
            height: "auto",
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            mb: { xs: 2, md: 4 },
            // target the first child div, which react-slick creates
            "& > div": {
              height: "100%",
              overflow: "hidden",
            },
          },
          ".slick-track": {
            display: "flex",
          },
          ...modalStyles,
        },
      }}
    >
      <IconButton
        onClick={async () => {
          await onClose();
        }}
        sx={{
          position: "absolute",
          zIndex: 3,
          top: 12,
          right: 12,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack
        sx={{
          px: { xs: 3, md: 5 },
          py: { xs: 4, md: 6 },
          overflow: "auto",
          position: "relative",
          zIndex: 2,
        }}
        gap={2}
      >
        <Stack>
          <AnimatedGradientText
            animationSpeedS={12}
            sx={{
              fontSize: { xs: 20, md: 26 },
              fontWeight: 700,
              fontFamily: "Poppins",
              mx: "auto",
              textAlign: "center",
              background: getDynamicColor("gradient.secondaryLoop"),
            }}
          >
            What’s new in Yoodli?
          </AnimatedGradientText>
          <Stack
            direction="row"
            gap={{ xs: 2, md: 3 }}
            justifyContent="center"
            alignItems="center"
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: { xs: 10, md: 12 },
              fontWeight: 600,
              "& p": {
                whiteSpace: "nowrap",
              },
            }}
          >
            <Typography>Updates and Improvements</Typography>
            <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
              <CalendarIcon
                style={{
                  color: getDynamicColor("dark4"),
                }}
              />
              <Typography>{getHumanReadableDate(featureUpdate?.date)}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Slider {...{ ...DEFAULT_SLIDER_SETTINGS, arrows: false }}>
          {updates?.map(({ title, content }) => {
            const parsedContent = parse(content);
            const { images, contentWithoutImages } = findAndStripImgTags(parsedContent);
            return (
              <Stack
                key={title}
                alignItems="center"
                justifyContent="center"
                gap={{ xs: 2, md: 4 }}
                sx={{
                  color: getDynamicColor("purple3"),
                  flexGrow: 1,
                  height: "100%",
                  display: "flex !important",
                  fontFamily: "Poppins",
                  "& p": {
                    my: 0,
                    textAlign: "center",
                    fontSize: { xs: 12, md: 14 },
                    lineHeight: 1.4,
                  },
                  "& h1": {
                    fontSize: { xs: 16, md: 24 },
                    fontWeight: 700,
                    textAlign: "center",
                  },
                }}
              >
                {images.map((img, index) => (
                  <Box
                    component="img"
                    key={`${img}-${index}`}
                    src={img}
                    alt={`Feature Update ${index + 1}`}
                    sx={{
                      display: "flex",
                      maxWidth: "calc(100% - 32px)",
                      maxHeight: 350,
                      position: "relative",
                    }}
                  />
                ))}
                <Stack
                  gap={{ xs: 0.5, md: 1 }}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    px: { xs: 2, sm: 4, md: 8, lg: 10 },
                    minHeight: 100,
                  }}
                >
                  {contentWithoutImages}
                </Stack>
              </Stack>
            );
          })}
        </Slider>
        <Button
          variant="gradient"
          onClick={async () => {
            Instrumentation.logAmplitudeEvent(WhatsNewAnnouncementEvents.POPUP_CLICKED);
            window.open(featureUpdate?.featureAnnouncementUrl, "_blank");
            await onClose();
          }}
          sx={{
            mx: "auto",
            mt: updates?.length > 1 ? { xs: 2, md: 4 } : 0,
          }}
        >
          Learn More
        </Button>
      </Stack>
      <Blobs
        overrideSx={{
          zIndex: 1,
          bottom: { xs: -240, md: -300 },
          right: { xs: 0, md: -100 },
          top: "unset",
          opacity: 0.15,
        }}
        sizeMultiplier={0.75}
        overrideColors={{
          blob1: getDynamicColor("blobGreen"),
          blob2: getDynamicColor("primary"),
          blob3: getDynamicColor("primary"),
        }}
      />
    </Dialog>
  );
};
