import React from "react";

// Components
import { Box, SxProps } from "@mui/material";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";

const DEFAULT_BLOB_STYLES: SxProps = {
  mixBlendMode: "soft-light",
  filter: "blur(60px)",
  position: "absolute",
  borderRadius: "50%",
};

const BLOB_ANIMATIONS = {
  "@keyframes blob1": {
    "0%": {
      transform: "rotate(0deg) translate(0px, 0px) scale(1)",
    },
    "15%": {
      transform: "rotate(30deg) translate(-10px, 20px)",
    },
    "33%": {
      transform: "rotate(65deg) translate(-30px, 40px) scale(1.15)",
    },
    "66%": {
      transform: "rotate(45deg) translate(20px, 10px) scale(0.9)",
    },
    "80%": {
      transform: "rotate(15deg) translate(50px, -50px)",
    },
    "100%": {
      transform: "rotate(0deg) translate(0px, 0px) scale(1)",
    },
  },
  ".animateBlob": {
    animation: "blob1 8s infinite",
    animationTimingFunction: "ease",
    WebkitAnimationTimingFunction: "ease",
  },
  ".blob2": {
    animationDelay: "3s",
  },
  ".blob3": {
    animationDelay: "5.2s",
  },
};

export const Blobs = ({
  overrideSx = {},
  overrideColors,
  sizeMultiplier = 1,
}: {
  overrideSx?: SxProps;
  overrideColors?: {
    blob1?: string;
    blob2?: string;
    blob3?: string;
  };
  sizeMultiplier?: number;
} = {}): JSX.Element => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 40 * sizeMultiplier,
        left: "calc(50% + 50px)",
        zIndex: -1,
        opacity: 0.3,
        ...BLOB_ANIMATIONS,
        ...overrideSx,
      }}
    >
      <Box
        className="animateBlob"
        sx={{
          ...DEFAULT_BLOB_STYLES,
          left: -150 * sizeMultiplier,
          height: { xs: 300 * sizeMultiplier, md: 400 * sizeMultiplier },
          width: { xs: 400 * sizeMultiplier, md: 500 * sizeMultiplier },
          backgroundColor: overrideColors?.blob1 || getDynamicColor("secondary"),
        }}
      />

      <Box
        className="animateBlob blob3"
        sx={{
          ...DEFAULT_BLOB_STYLES,
          height: { xs: 200 * sizeMultiplier, md: 250 * sizeMultiplier },
          width: { xs: 200 * sizeMultiplier, md: 300 * sizeMultiplier },
          right: -100 * sizeMultiplier,
          top: -40 * sizeMultiplier,
          backgroundColor: overrideColors?.blob2 || getDynamicColor("chartPurple"),
        }}
      />
      <Box
        className="animateBlob blob2"
        sx={{
          ...DEFAULT_BLOB_STYLES,
          height: { xs: 250 * sizeMultiplier, md: 300 * sizeMultiplier },
          width: { xs: 300 * sizeMultiplier, md: 500 * sizeMultiplier },
          right: { xs: -80 * sizeMultiplier, md: -60 * sizeMultiplier },
          top: { xs: 120 * sizeMultiplier, md: 100 * sizeMultiplier },
          backgroundColor: overrideColors?.blob3 || getDynamicColor("primary"),
          zIndex: 2,
        }}
      />
    </Box>
  );
};
