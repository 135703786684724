import React from "react";
import { useNavigate, useLocation } from "react-router";

// Components
import {
  YoodliSelect,
  YoodliSelectVariant,
  YoodliSelectOption,
} from "lib-frontend/components/YoodliComponents/YoodliSelect";

// Utils
import { useMutation } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { patchUser } from "lib-frontend/modules/AxiosInstance";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { OrgHubsQueryParams } from "lib-fullstack/utils/queryParams";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";

type DefaultOrgSwitcherProps = {
  optionOverride?: YoodliSelectOption[];
  showConfirmation?: boolean;
  handleResetBulkActionList?: () => void;
};

export const DefaultOrgSwitcher = ({
  optionOverride,
  showConfirmation,
  handleResetBulkActionList,
}: DefaultOrgSwitcherProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    defaultOrg,
    defaultOrgLoading,
    adminOrgList,
    invalidateDefaultOrgQuery,
    invalidateUserOrgQuery,
    orgListLoading,
  } = React.useContext(UserOrgContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isOpen) return;

      if (e.key.length === 1) {
        setSearchText((prev) => prev + e.key);
      } else if (e.key === "Backspace") {
        setSearchText((prev) => prev.slice(0, -1));
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isOpen]);

  let options = [];
  if (adminOrgList && defaultOrg) {
    const allOptions = [
      { label: defaultOrg.name, value: defaultOrg.id },
      ...adminOrgList
        .filter((org) => org.id !== defaultOrg.id)
        .map((org) => ({ label: org.name, value: org.id })),
    ];

    if (searchText) {
      // Filter and sort options based on search text
      const searchLower = searchText.toLowerCase();
      options = allOptions.sort((a, b) => {
        const aStartsWith = a.label.toLowerCase().startsWith(searchLower);
        const bStartsWith = b.label.toLowerCase().startsWith(searchLower);
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        return 0;
      });
    } else {
      options = allOptions;
    }
  }

  const updateDefaultOrgMutation = useMutation({
    mutationFn: (orgId: string) =>
      patchUser({ default_org_id: orgId }).then((result) => result.toString()),
    onSuccess: async (orgId: string) => {
      if (handleResetBulkActionList) {
        handleResetBulkActionList();
      }
      Instrumentation.logDefaultOrgChanged(defaultOrg.id, orgId);
      const qp = new URLSearchParams(location.search);
      // if on the hubs page -- remove the hub id query param
      if (qp.get(OrgHubsQueryParams.GROUP_ID)) {
        qp.delete(OrgHubsQueryParams.GROUP_ID);
        navigate({ search: qp.toString() }, { replace: true });
      }
      await invalidateUserOrgQuery();
      return invalidateDefaultOrgQuery();
    },
  });

  const handleOrgSwitch = (e) => {
    setSearchText(""); // Clear search on selection
    if (showConfirmation) {
      if (
        window.confirm(
          "Are you sure you want to switch organziations? Your changes might not be saved!"
        )
      ) {
        updateDefaultOrgMutation.mutate(e.target.value as string);
      }
    } else {
      updateDefaultOrgMutation.mutate(e.target.value as string);
    }
  };

  return (
    <>
      {((!orgListLoading && adminOrgList.length > 1) || optionOverride) && (
        <YoodliSelect
          value={defaultOrg.id}
          onChange={(e) => handleOrgSwitch(e)}
          options={optionOverride ?? options}
          loading={updateDefaultOrgMutation.isPending || defaultOrgLoading}
          yoodliVariant={isSmallScreen ? YoodliSelectVariant.PRIMARY : YoodliSelectVariant.LIGHT}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsOpen(false);
            setSearchText("");
          }}
          inputProps={{
            sx: {
              px: { xs: 1, md: 2 },
              py: { xs: 0.5, md: 2 },
            },
          }}
        />
      )}
    </>
  );
};
