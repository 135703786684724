import { Box, Checkbox } from "@mui/material";
import { getDynamicColor } from "lib-frontend/utils/Colors";

export const headerRowStyles = {
  width: "100%",
  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
  backgroundColor: getDynamicColor("light1"),
  position: "sticky",
  top: 0,
  zIndex: 1,
};

export const defaultTableDataStyles = {
  fontFamily: "poppins",
  fontWeight: 400,
  fontSize: 12,
  color: getDynamicColor("dark5"),
};

export const tableHeaderStyles = {
  ...defaultTableDataStyles,
  fontWeight: 600,
  mr: 1,
};

export const dataRowStyles = {
  width: "max-content",
  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
  "&:last-of-type": {
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    borderBottom: "unset",
  },
};

export const CheckboxTableElement = ({
  component,
  checked,
  indeterminate,
  onChange,
}: {
  component: "th" | "td";
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: 50,
        px: 0.5,
      }}
      component={component}
    >
      <Box sx={{ display: "flex" }}>
        <Checkbox checked={checked} indeterminate={indeterminate} onChange={onChange} />
      </Box>
    </Box>
  );
};
