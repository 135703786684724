import firebase from "firebase/app";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import { Box, Stack, Typography } from "@mui/material";

// Assets
import yoodli_logo from "../../../images/logos/yoodli_logo.svg";

// Utils
import EmailSent from "./EmailSent";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { sendVerificationEmail } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { getStaticFullSiteConf } from "lib-frontend/utils/LiveSiteDocs";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { isWhiteLabel } from "lib-frontend/utils/Utilities";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { UITestId } from "lib-fullstack/utils/enums";
import { AuthAnalyticsEvents } from "lib-fullstack/utils/productAnalyticEvents";
import { SHOULD_REFRESH_FIREBASE_ID_TOKEN } from "utils/Constants";
import { WebServerInternalPath } from "utils/paths";
import {
  determineHomePagePath,
  mergeReturnPathAndSearchParameters,
  getReturnPath,
} from "utils/Utilities";

export default function VerifyEmail(): JSX.Element {
  const [error, setError] = React.useState<string>(undefined);
  const [emailSent, setEmailSent] = React.useState<boolean>(false);

  const { emailVerified } = React.useContext(UserOrgContext);

  const navigate = useNavigate();
  const location = useLocation();
  const siteConf = getStaticFullSiteConf();

  React.useEffect(() => {
    Instrumentation.logAmplitudeEvent(AuthAnalyticsEvents.EMAIL_VERIFICATION_PAGE_LOADED);
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid && user?.emailVerified) {
        return navigate(determineHomePagePath(), { replace: true });
      }
    });
    localStorage.setItem(SHOULD_REFRESH_FIREBASE_ID_TOKEN, "true");
  }, []);

  const handleSendVerificationEmail = () => {
    const defaultReturnPath = isWhiteLabel()
      ? WebServerInternalPath.LIBRARY
      : WebServerInternalPath.ONBOARDING;

    const currentReturnPath = getReturnPath(location);

    let returnPath =
      currentReturnPath && currentReturnPath !== WebServerInternalPath.HOME
        ? currentReturnPath
        : defaultReturnPath;

    const otAuthCode = new URLSearchParams(location?.search).get("ot-auth-code");
    if (otAuthCode) {
      returnPath = WebServerInternalPath.SETUP_DESKTOP_APP;
    }
    sendVerificationEmail(
      getClientEnvConfig().url.WEB_SERVER +
        mergeReturnPathAndSearchParameters(returnPath, window.location.search)
    )
      .then(() => {
        setEmailSent(true);
        setError(undefined);
      })
      .catch((error) => {
        if (error.message === "auth/internal-error") {
          console.log(error.message);
          setError("Give us a minute, and hit resend if you still haven't received an email!");
        } else {
          console.error(error.message);
          setError(error.message);
        }
      });
  };

  if (emailVerified) {
    navigate(getReturnPath(location) ?? determineHomePagePath(), { replace: true });
  }

  return (
    <>
      <Box position="absolute" sx={{ top: 0, left: 0 }} py={3} px={4}>
        <img
          src={siteConf?.logo?.url ?? yoodli_logo}
          style={{
            width: 150,
          }}
          alt="Yoodli logo"
        />
      </Box>
      <Stack width="100%" alignItems="center">
        <Stack
          justifyContent="center"
          sx={{
            width: { xs: "calc(100% - 32px)", md: "min(50%, 800px)" },
            border: `1px solid ${getDynamicColor("light2")}`,
            borderRadius: "12px",
            boxShadow: Y_SHADOWS.box_shadow_1,
            px: { xs: "24px", md: "84px" },
            py: 10,
            backgroundColor: getDynamicColor("light1"),
          }}
        >
          <EmailSent
            sendVerificationEmail={handleSendVerificationEmail}
            emailSent={emailSent}
            setEmailSent={setEmailSent}
          />
          {error && (
            <Typography
              color={getDynamicColor("redError")}
              sx={{
                margin: "auto",
                fontSize: 14,
              }}
              data-testid={UITestId.EmailSentError}
            >
              {error}
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
}
