import React from "react";

// Components
import { Stack } from "@mui/material";
import {
  MAX_SCENARIO_DESCRIPTION_LENGTH,
  TEMPLATE_SUB_TYPE_DATA,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import { WYSIWYGEditor } from "components/WYSIWYGEditor";

// Utils
import { useWYSIWYG } from "hooks/useWYSIWYG";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { ScenarioTemplateSubType } from "lib-fullstack/db";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

export type ScenarioDescriptors = {
  title: string;
  description: string;
};

type ScenarioPreviewProps = {
  templateSubType: ScenarioTemplateSubType;
  title: string;
  titleLabel?: string;
  description: string;
  descriptionLabel?: string;
  decriptionPlaceholder?: string;
  handleUpdateScenarioDetails: (key: keyof ScenarioDescriptors, value: string) => void;
};

// TODO: rename this to better represent the details that a user can change (title/desc) instead of just a purely preview page
export const ScenarioPreview = ({
  templateSubType,
  title,
  titleLabel,
  description,
  descriptionLabel,
  decriptionPlaceholder,
  handleUpdateScenarioDetails,
}: ScenarioPreviewProps): JSX.Element => {
  const { editor } = useWYSIWYG({
    content: description,
    placeholder: decriptionPlaceholder,
    characterLimit: MAX_SCENARIO_DESCRIPTION_LENGTH,
  });

  React.useEffect(() => {
    if (description !== editor?.getHTML()) {
      handleUpdateScenarioDetails("description", editor?.isEmpty ? "" : editor?.getHTML());
    }
  }, [description, editor?.isEmpty, editor?.getHTML()]);
  return (
    <Stack
      direction="column"
      gap={{ xs: 2, md: 3 }}
      sx={{
        background: getDynamicColor("gradient.slantedLight"),
        borderRadius: "12px",
        boxShadow: Y_SHADOWS.box_shadow_1,
        py: 3,
        px: 2.5,
      }}
    >
      <YoodliLabeledInput
        label={titleLabel || "Scenario name"}
        inputEl={
          <YoodliTextfield
            autoFocus
            inputProps={{
              className: "blockEnterToNavigate",
              sx: {
                fontWeight: 700,
              },
            }}
            maxChars={200}
            InputLabelProps={{ shrink: true }}
            placeholder={TEMPLATE_SUB_TYPE_DATA[templateSubType]?.label}
            value={title}
            onChange={(e) => handleUpdateScenarioDetails("title", e.target.value)}
            sx={{
              backgroundColor: getDynamicColor("light1"),
            }}
          />
        }
      />
      <YoodliLabeledInput
        label={descriptionLabel || "Member-facing Description"}
        inputEl={
          <WYSIWYGEditor
            editor={editor}
            excludeHeaderStyles
            characterLimit={MAX_SCENARIO_DESCRIPTION_LENGTH}
            sx={{
              ".tiptap": {
                maxHeight: 300,
              },
            }}
          />
        }
      />
    </Stack>
  );
};
