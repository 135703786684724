import { db } from "lib-fullstack";
import React from "react";

// Components
import { Stack, Autocomplete, Box, Button, Tooltip, TextField } from "@mui/material";
import { WIGGLE_ANIMATION } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as DiceIcon } from "images/icons/dice.svg";

// Utils
import { CARD_STYLES } from "./styles";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
import { INTERVIEW_CATEGORIES } from "utils/interviewUtils";
import { isValidCustomInterviewQuestion } from "utils/Utilities";

const INPUT_ID = "fpr-custom-questions-autocomplete-input";

export const MAX_INTERVIEW_QUESTION_LENGTH = 350;
type QuestionListProps = {
  addInterviewQuestion: () => void;
  autocompleteOpen: boolean;
  setAutocompleteOpen: (open: boolean) => void;
  inputtedInterviewOption: db.InterviewQuestion;
  setInputtedInterviewOption: (option: db.InterviewQuestion) => void;
  allPrompts: db.InterviewQuestion[];
  interviewQs: string[];
  maxInterviewQs: number;
  renderQuestions: () => JSX.Element | JSX.Element[];
  error: boolean;
  setError: (error: boolean) => void;
  shuffleThroughPrompts: () => void;
};

export function QuestionList({
  addInterviewQuestion,
  autocompleteOpen,
  setAutocompleteOpen,
  inputtedInterviewOption,
  setInputtedInterviewOption,
  allPrompts,
  interviewQs,
  maxInterviewQs,
  renderQuestions,
  error,
  setError,
  shuffleThroughPrompts,
}: QuestionListProps): JSX.Element {
  const optionBankRef = React.useRef<HTMLDivElement>(null);

  const [showTextLimit, setShowTextLimit] = React.useState(false); // for showing the text limit in the textarea
  const autocompleteOptions = React.useMemo(
    () =>
      (allPrompts ?? []).filter(
        (p) =>
          !interviewQs.includes(p.text) &&
          p.text.toLowerCase().trim().includes(inputtedInterviewOption?.text.toLowerCase().trim())
      ),
    [allPrompts, inputtedInterviewOption]
  );

  React.useEffect(() => {
    const height = document.getElementById(INPUT_ID)?.clientHeight ?? 0;
    setShowTextLimit(
      inputtedInterviewOption?.text.length > 0 &&
        (height > 71 || inputtedInterviewOption?.text.length > 100)
    );
  }, [inputtedInterviewOption]);

  React.useEffect(() => {
    if (!autocompleteOptions?.length) {
      setAutocompleteOpen(false);
    }
  }, [autocompleteOptions]);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    addInterviewQuestion();
  };

  const handleOnChange = (_, value, reason) => {
    let newInterviewOption: db.InterviewQuestion = {
      text: "",
      category: INTERVIEW_CATEGORIES.CUSTOM.identifier,
      type: "CUSTOM",
    };
    if (value && value.text) {
      newInterviewOption = { ...value, type: "CUSTOM" };
    } else if (reason === "clear") {
      setError(false);
    }
    setAutocompleteOpen(false);
    setInputtedInterviewOption(newInterviewOption);
  };

  const handleOnInputChange = (_, value, reason) => {
    if (!value || value.trim().length === 0) {
      setShowTextLimit(false);
    }
    if (reason === "reset") return;
    setInputtedInterviewOption({
      text: value,
      category: INTERVIEW_CATEGORIES.CUSTOM.identifier,
      type: "CUSTOM",
    });
    setError(false);
    setAutocompleteOpen(!!(value && autocompleteOptions?.length));
  };

  const reachedMaxNumQs = interviewQs.length === maxInterviewQs;
  const submitDisabled =
    !inputtedInterviewOption?.text ||
    !isValidCustomInterviewQuestion(inputtedInterviewOption?.text) ||
    reachedMaxNumQs;

  return (
    <Stack
      alignItems="stretch"
      alignSelf="center"
      gap={2}
      sx={{
        ...CARD_STYLES,
        maxWidth: { xs: "unset", xl: "min(700px, 50%)" },
        minWidth: { xs: "unset", xl: "min(700px, 50%)" },
        width: { xs: "100%", xl: "auto" },
      }}
    >
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <Autocomplete
          disablePortal
          freeSolo
          id={INPUT_ID}
          disabled={reachedMaxNumQs}
          open={autocompleteOpen}
          value={inputtedInterviewOption}
          options={autocompleteOptions}
          onChange={handleOnChange}
          onBlur={() => {
            setAutocompleteOpen(false);
          }}
          onFocus={() => {
            if (inputtedInterviewOption?.text && autocompleteOptions?.length > 1) {
              setAutocompleteOpen(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              handleOnSubmit(e);
            }
          }}
          onInputChange={handleOnInputChange}
          getOptionLabel={(option) => option?.text ?? ""}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.text}
                style={{
                  padding: "8px 16px",
                  borderBottom: `1px solid ${getDynamicColor("dark2")}`,
                }}
              >
                {option?.text}
              </li>
            );
          }}
          PaperComponent={(paperProps) => (
            <Box
              {...paperProps}
              sx={{
                maxHeight: { xs: 280, md: 320 },
                zIndex: "2 !important",
                backgroundColor: getDynamicColor("light1"),
                border: `1px solid ${getDynamicColor("dark2")}`,
                borderRadius: "8px",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: "calc(100% - 2px)",
                position: "relative",
                bottom: 6,
                left: 1,
                boxShadow: Y_SHADOWS.box_shadow_1,
              }}
            >
              {paperProps.children}
            </Box>
          )}
          sx={{
            zIndex: 3,
            position: "relative",
            "& .MuiInput-underline.MuiInputBase-root.Mui-error::before": {
              borderBottom: "1px solid red !important",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              fullWidth
              autoFocus
              name="question-input"
              error={!!error}
              variant="outlined"
              sx={{
                cursor: reachedMaxNumQs ? "not-allowed" : "pointer",
                zIndex: 2,
                position: "relative",
                flexGrow: 1,
                borderRadius: "8px",
                backgroundColor: getDynamicColor("light1"),
                "fieldset, .Mui-focused fieldset, &:hover fieldset": {
                  border: `1px solid ${getDynamicColor("dark2")} !important`,
                },
                ".MuiInputBase-root": {
                  borderRadius: "inherit",
                  fontSize: 14,
                  alignItems: "flex-start",
                  width: "100%",
                  gap: 1.5,
                  pr: "10px !important",
                  p: 1,
                  pl: 2,
                },
                ".MuiInputBase-input": {
                  alignSelf: "center",
                  p: 0,
                },
                outline: "none",
                ".Mui-disabled": {
                  cursor: "not-allowed",
                },
                ".Mui-focused": {
                  boxShadow: 0,
                  backgroundColor: "white",
                },
              }}
              inputProps={{
                ...params.inputProps,
                maxLength: MAX_INTERVIEW_QUESTION_LENGTH,
              }}
              InputProps={{
                ...params.InputProps,
                placeholder: "Add custom question",
                sx: {
                  alignSelf: "center !important",
                  color: getDynamicColor("purple3"),
                },
                endAdornment: (
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems="center"
                    gap={1}
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Tooltip title="Roll the dice to get a random prompt">
                      <Stack
                        aria-label="Shuffle through prompts"
                        alignItems="center"
                        justifyContent="center"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!reachedMaxNumQs) {
                            shuffleThroughPrompts();
                          }
                        }}
                        sx={{
                          cursor: interviewQs.length >= maxInterviewQs ? "inherit" : "pointer",
                          borderRadius: 20,
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                          fontSize: 12,
                          color: getDynamicColor("primary"),
                          height: 35,
                          width: 28,
                          transition: "all 0.3s ease-in-out",
                          ...(interviewQs.length < maxInterviewQs
                            ? {
                                "&:hover": {
                                  ...WIGGLE_ANIMATION,
                                  animation: "wiggle 1s ease-in-out 1",
                                },
                              }
                            : {}),
                        }}
                      >
                        <DiceIcon
                          style={{
                            fill: getDynamicColor(
                              interviewQs.length < maxInterviewQs ? "primary" : "dark4"
                            ),
                            transition: "all 0.3s ease-in-out",
                          }}
                        />
                      </Stack>
                    </Tooltip>
                    <Button
                      variant="contained"
                      disabled={submitDisabled}
                      onClick={() => addInterviewQuestion()}
                      sx={{
                        fontSize: 14,
                        height: 28,
                        px: 2.5,
                      }}
                    >
                      Add
                    </Button>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 12,
                        bottom: 8,
                        // fade the counter in only when the textarea is above 1 line
                        opacity: showTextLimit ? 1 : 0,
                        pointerEvents: "none",
                        transition: "opacity 0.2s ease-out",
                      }}
                    >
                      {inputtedInterviewOption?.text.length} / {MAX_INTERVIEW_QUESTION_LENGTH}
                    </Box>
                  </Stack>
                ),
              }}
            />
          )}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Stack sx={{ overflowY: "auto", height: "100%" }} gap={1} ref={optionBankRef}>
        {renderQuestions()}
      </Stack>
    </Stack>
  );
}
