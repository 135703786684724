import React from "react";

// Components
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Typography, Link } from "@mui/material";
import { MAX_EMAIL_INVITE_COUNT } from "components/Orgs/InviteMembers";
import { YoodliMultiEmail } from "lib-frontend/components/YoodliComponents/YoodliMultiEmail";
import { YoodliSelect } from "lib-frontend/components/YoodliComponents/YoodliSelect";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Utils
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { SupportPages } from "lib-frontend/utils/paths";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { HubV2Response } from "lib-fullstack/api/orgApiTypes";
import { EMAIL_BRANDING_MESSAGE_MAX_LENGTH } from "lib-fullstack/utils/constants";
import { HubRole } from "lib-fullstack/utils/enums";

type InviteMembersProps = {
  hub: HubV2Response;
  emailsToInvite: string[];
  setEmailsToInvite: (emails: string[]) => void;
  role: HubRole;
  setRole: (role: HubRole) => void;
  welcomeMessage: string;
  setWelcomeMessage: (message: string) => void;
};

export const InviteMembers = ({
  hub,
  emailsToInvite,
  setEmailsToInvite,
  role,
  setRole,
  welcomeMessage,
  setWelcomeMessage,
}: InviteMembersProps): JSX.Element => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <Stack gap={{ xs: 3, md: 4 }} sx={{ py: { xs: 4, md: 5 }, px: 2 }}>
      <Stack gap={0.5}>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Invite people to join {hub?.name}
        </Typography>
        <Typography
          sx={{
            color: getDynamicColor("purple3"),
            fontFamily: "poppins",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          Add up to {MAX_EMAIL_INVITE_COUNT} emails separated by commas, select one role for all new
          users, and add them to your group. Yoodli will send invitations to all new users and
          exclude any already-existing members. All new members will be automatically added to the
          organization.
        </Typography>
      </Stack>
      <Stack gap={3}>
        {emailsToInvite.length > MAX_EMAIL_INVITE_COUNT && (
          <Typography
            sx={{
              color: getDynamicColor("redError"),
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            You may only submit up to {MAX_EMAIL_INVITE_COUNT} invites at a time. Please remove some
            emails to continue.
          </Typography>
        )}
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 3, md: 5 }}
          sx={{ alignItems: "center" }}
        >
          <Stack gap={1} sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: getDynamicColor("purple3"),
                fontFamily: "poppins",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Email(s)
            </Typography>
            <YoodliMultiEmail
              pendingEmails={emailsToInvite}
              setPendingEmails={setEmailsToInvite}
              maxEmailCount={MAX_EMAIL_INVITE_COUNT}
            />
          </Stack>
          <Stack gap={1} sx={{ width: { xs: "100%", md: "auto" } }}>
            <Stack gap={0.5} direction="row" sx={{ alignItems: "center" }}>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Role
              </Typography>
              <YoodliTooltip
                title={
                  <Typography>
                    Group admin are given limited access to admin permissions and information like
                    members' usage.{" "}
                    <Link href={SupportPages.ORG_OVERVIEW_ROLES} target="_blank">
                      Learn more about roles
                    </Link>
                  </Typography>
                }
              >
                <InfoOutlinedIcon sx={{ width: "16px" }} />
              </YoodliTooltip>
            </Stack>
            <YoodliSelect
              value={role}
              onChange={(e) => setRole(e.target.value as HubRole)}
              options={[
                { value: HubRole.MEMBER, label: "Group member" },
                { value: HubRole.ADMIN, label: "Group admin" },
              ]}
              sx={{ width: { xs: "100%", md: "200px" }, height: "53px" }}
            />
          </Stack>
        </Stack>
        <YoodliTextfield
          value={welcomeMessage}
          onChange={(e) => setWelcomeMessage(e.target.value)}
          label="Optional message"
          multiline
          minRows={isSmallScreen ? 6 : 8}
          maxChars={EMAIL_BRANDING_MESSAGE_MAX_LENGTH}
        />
      </Stack>
    </Stack>
  );
};
