import firebase from "firebase/app";
import React from "react";
import { useLocation } from "react-router";

// Utils
import { UserOrgContext } from "./UserOrgContext";
import { useQuery as useApiQuery, useMutation } from "@tanstack/react-query";
import { getActivityById, patchActivity, patchUser } from "lib-frontend/modules/AxiosInstance";
import {
  EmbedMessage,
  EmbedMessageTypeEnum,
  ScormEventTypeEnum,
  ScormMessageData,
} from "lib-frontend/types/messageTypes";
import { ActivityData, ScenarioActivity } from "lib-fullstack/api/activityApiTypes";
import { ActivityTypeEnum } from "lib-fullstack/utils/enums/activityType";
import { ActivityViewModeEnum } from "lib-fullstack/utils/enums/activityViewMode";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { ActivityQueryParams, PracticeRecorderQueryParams } from "lib-fullstack/utils/queryParams";

/**
 * Expose context that can be fetched
 */
export interface IYoodliActivityContext {
  activity: ActivityData | null;
  activityErrorMessage: string | null;
  activityRedirectUrl: string | null;
  isFocusedActivity: boolean;
  isIFrame: boolean;
  activityLoading: boolean;
  publishScoreComplete: (score: number, scenarioId: string, recordingUrl: string) => void;
  publishUserDoneAndExit: () => void;
}

// Constant for SCORM lesson status, to be reported to the LMS when the user exits the session
const SCORM_LESSON_STATUS_COMPLETED = "completed";

// react-query keys for invalidation
const YOODLI_ACTIVITY_CONTEXT_QUERY_KEY = "yoodliActivityContext";
enum YoodliActivityContextSubQueryKeys {
  ACTIVITY = "activity",
}

/**
 * Context to use to get information on a user's activity state
 */
export const YoodliActivityContext = React.createContext<IYoodliActivityContext>({
  activity: null,
  activityErrorMessage: null,
  activityRedirectUrl: null,
  isFocusedActivity: false,
  isIFrame: false,
  activityLoading: true,
  publishScoreComplete: () => {
    // empty to provide a safe default to call
    return;
  },
  publishUserDoneAndExit: () => {
    // empty to provide a safe default to call
    return;
  },
});

/**
 * React element to provide the activity context in the tree
 */
export function YoodliActivityDataProvider({ children }: React.PropsWithChildren): JSX.Element {
  const location = useLocation();
  const qp = new URLSearchParams(location.search);
  const { defaultOrg, invalidateUserOrgQuery, emailVerified } = React.useContext(UserOrgContext);

  const [activityId, setActivityId] = React.useState<string | null>(null);
  const [activityErrorMessage, setActivityErrorMessage] = React.useState<string | null>(null);

  /**
   * On mount, parse the activity ID from the query params and store in state
   */
  React.useLayoutEffect(() => {
    if (qp.has(ActivityQueryParams.ACTIVITY_ID)) {
      console.log(
        `YoodliActivityContext: Set activity ID to ${qp.get(ActivityQueryParams.ACTIVITY_ID)}`
      );
      setActivityId(qp.get(ActivityQueryParams.ACTIVITY_ID));
    }
  }, []);

  React.useEffect(() => {
    if (activityQuery?.data && defaultOrg && activityQuery.data.orgId !== defaultOrg.id) {
      console.log(`YoodliActivityContext: Update default org to ${activityQuery.data.orgId}`);
      updateDefaultOrgMutation.mutate();
    }
  }, [activityId, defaultOrg]);

  const activityQuery = useApiQuery({
    queryKey: [
      YOODLI_ACTIVITY_CONTEXT_QUERY_KEY,
      YoodliActivityContextSubQueryKeys.ACTIVITY,
      activityId,
    ],
    queryFn: () =>
      getActivityById(activityId).catch((err) => {
        console.log("Error fetching activity", activityId);
        setActivityErrorMessage(err.message);
        return null;
      }),
    enabled: !!activityId && (emailVerified || !firebase.auth().currentUser),
    retry: false,
  });

  const assignUserToActivityMutation = useMutation({
    mutationFn: () => patchActivity(activityId, { userId: firebase.auth().currentUser?.uid }),
    onSuccess: () => void activityQuery.refetch(),
  });

  const updateDefaultOrgMutation = useMutation({
    mutationFn: () => patchUser({ default_org_id: activityQuery.data?.orgId }),
    onSuccess: () => invalidateUserOrgQuery(),
  });

  /**
   * On user login, assign the activity to the user if it is not already assigned.
   * If the activity is already assigned to a different user, the query will silently fail and we won't return the activity
   */
  firebase.auth().onAuthStateChanged((user) => {
    if (
      user?.uid &&
      activityQuery?.data &&
      !activityQuery.data?.userId &&
      assignUserToActivityMutation.isIdle // The firebase handler is called multiple times on login for unknown reason, ensure we only assign user to activity once
    ) {
      console.log(
        `YoodliActivityContext: Assigning activity ${activityId} to current user ${user.uid}`
      );
      assignUserToActivityMutation.mutate();
    }
  });

  const activityRedirectUrl = React.useMemo(() => {
    if (!activityQuery?.data) {
      return null;
    }

    switch (activityQuery.data.type) {
      case ActivityTypeEnum.Accenture_1_0_Scenario:
      case ActivityTypeEnum.LTI_1_1_Scenario:
      case ActivityTypeEnum.LTI_1_3_Scenario:
      case ActivityTypeEnum.SCORM_1_2_Scenario:
        return `${WebServerExternalPath.PRACTICE_CONVERSATION}?${
          PracticeRecorderQueryParams.SCENARIO
        }=${(activityQuery.data as ScenarioActivity).scenarioId}`;

      case ActivityTypeEnum.Accenture_1_0_SpeechSummary:
        return `${WebServerExternalPath.SHARE}${activityQuery.data.speechSlug}`;

      case ActivityTypeEnum.Test:
      default:
        return WebServerExternalPath.HOME_LOGGED_IN;
    }
  }, [activityQuery?.data]);

  /**
   * Helper functions to publish events to the LMS, depending on the activity type
   */
  const publishScoreComplete = React.useCallback(
    (score: number, scenarioId: string, recordingUrl: string) => {
      console.log(
        `YoodliActivityContext: publishScoreComplete ${score} ${scenarioId} ${recordingUrl}`
      );
      if (!activityQuery?.data) {
        console.log("YoodliActivityContext: activity not loaded for publishScoreComplete");
        return;
      }

      switch (activityQuery.data.type) {
        case ActivityTypeEnum.SCORM_1_2_Scenario: {
          console.log("YoodliActivityContext: publishScoreComplete SCORM_1_2_Scenario");
          const scormMessageData: ScormMessageData = {
            name: ScormEventTypeEnum.ScoreRaw,
            data: score.toString(),
            closeSession: false,
          };
          const embedMessage: EmbedMessage = {
            messageType: EmbedMessageTypeEnum.Scorm,
            data: scormMessageData,
          };
          window.parent.postMessage(embedMessage, "*");
          break;
        }
        default: {
          console.log(
            "YoodliActivityContext: activity type not applicable for publishScoreComplete"
          );
          break;
        }
      }
    },
    [activityQuery?.data]
  );

  const publishUserDoneAndExit = React.useCallback(() => {
    console.log("YoodliActivityContext: publishUserDone");
    if (!activityQuery?.data) {
      console.log("YoodliActivityContext: activity not loaded for publishUserDone");
      return;
    }

    // Send the appropriate message to the parent window, depending on the activity type
    switch (activityQuery.data.type) {
      case ActivityTypeEnum.SCORM_1_2_Scenario: {
        console.log("YoodliActivityContext: publishUserDone SCORM_1_2_Scenario");
        const scormMessageData: ScormMessageData = {
          name: ScormEventTypeEnum.LessonStatus,
          data: SCORM_LESSON_STATUS_COMPLETED,
          closeSession: true,
        };
        const embedMessage: EmbedMessage = {
          messageType: EmbedMessageTypeEnum.Scorm,
          data: scormMessageData,
        };
        window.parent.postMessage(embedMessage, "*");
        break;
      }
      default: {
        console.log("YoodliActivityContext: activity type not applicable for publishUserDone");
        break;
      }
    }

    // Close window or embedding
    console.log("YoodliActivityContext: Closing window");
    if (activityQuery.data.viewMode === ActivityViewModeEnum.Embedded) {
      window.location.href = "about:blank"; // Redirect to blank page to close session
    } else {
      window.close();
    }
  }, [activityQuery?.data]);

  // Construct the context value
  const activityContextValue: IYoodliActivityContext = {
    activity: activityQuery?.data ?? null,
    activityErrorMessage: activityErrorMessage,
    activityRedirectUrl: activityRedirectUrl,
    isFocusedActivity: !!activityId,
    isIFrame: activityQuery?.data?.viewMode === ActivityViewModeEnum.Embedded,
    activityLoading: qp.has(ActivityQueryParams.ACTIVITY_ID) && activityQuery.isPending,
    publishScoreComplete: publishScoreComplete,
    publishUserDoneAndExit: publishUserDoneAndExit,
  };

  return (
    <YoodliActivityContext.Provider value={activityContextValue}>
      {children}
    </YoodliActivityContext.Provider>
  );
}
