import { db } from "lib-fullstack";
import React from "react";

// Components
import { MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import {
  GoalTalkTime,
  MAX_CHAR_LENGTH_TALKING_POINT,
  MAX_TALKING_POINTS,
  distributeWeightEvenly,
  filterAndMergeArr,
  normalizeWeights,
} from "components/ConvoScenarios/convoScenarioUtils";
import { GoalDescriptions, GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { YoodliMenuItemType } from "lib-frontend/components/YoodliComponents/YoodliMenu";
import YoodliTooltip from "lib-frontend/components/YoodliComponents/YoodliTooltip";

// Assets
import { ReactComponent as RubricIcon } from "images/icons/icon-scale.svg";

// Utils
import { useScrollToTop } from "hooks/useScrollToTop";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomGoalItemWithUsage } from "lib-fullstack/api/scenarioApiTypes";
import { DefaultGoal, GoalKind, ScenarioTypeIdEnum } from "lib-fullstack/db";
import { GoalWeightSelector } from "./GoalWeightSelector";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { IOSSwitch } from "components/Settings/Settings";
import { isEqualDistribution } from "lib-fullstack/utils/isEqualWeightDistribution";
import { renderCreateGoalMenuItem } from "components/Orgs/ManageContent/CustomGoals/CustomGoalLibrary";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  StackedMenu,
  StackedMenuItem,
} from "components/Orgs/ManageContent/CustomGoals/StackedMenu";
import { GoalList } from "./GoalList";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";
import { WizardAddDraggableItems } from "./WizardAddDraggableItems";
import { WizardType } from "./CreateConvoScenarioWizard";
import { ExpandMoreRounded } from "@mui/icons-material";

const GOAL_OPTIONS = {
  [db.ScenarioTypeIdEnum.SALES_CALL]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.COLD_CALL]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
    DefaultGoal.BookFollowUp,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.IdentifyPainPoints,
    DefaultGoal.UseGROW,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_PERF_REVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.MANAGER_SKILLS_TRAINING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.UseGROW,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.CUSTOMER_ENABLEMENT]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.MEDIA_TRAINING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.GENERIC]: Object.values(DefaultGoal), // all goals
  [db.ScenarioTypeIdEnum.PITCH]: [DefaultGoal.HitTalkingPoints, DefaultGoal.HitTimeTarget],
  [db.ScenarioTypeIdEnum.SMALL_TALK]: [DefaultGoal.HitTalkingPoints, DefaultGoal.ActiveListening],
  [db.ScenarioTypeIdEnum.PATIENT_COUNSELING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.NETWORKING]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
  ],
  [db.ScenarioTypeIdEnum.BEHAVIORAL_INTERVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
    DefaultGoal.UseSTAR,
  ],
  [db.ScenarioTypeIdEnum.TECHNICAL_INTERVIEW]: [
    DefaultGoal.HitTalkingPoints,
    DefaultGoal.EngageSmallTalk,
    DefaultGoal.ActiveListening,
    DefaultGoal.UseSTAR,
  ],
};

type GoalSelectorProps = {
  goals: (DefaultGoal | string)[];
  handleUpdateGoals: React.Dispatch<React.SetStateAction<(DefaultGoal | string)[]>>;
  talkingPoints: string[];
  handleUpdateTalkingPoints: (talkingPoints: string[]) => void;
  handleUpdateCustomGoalType: (type: GoalKind) => void;
  goalTalkTime: GoalTalkTime;
  handleUpdateGoalTalkTime: (goalTalkTime: GoalTalkTime) => void;
  specialGoalIdSelected: string;
  setSpecialGoalIdSelected: (goalId: string) => void;
  scenarioTypeId: db.ScenarioTypeIdEnum;
  customGoals: CustomGoalItemWithUsage[];
  customGoalsLoading: boolean;
  setCurrStepIndex: React.Dispatch<React.SetStateAction<number>>;
  handleEditCustomGoal: (goal: CustomGoalItemWithUsage) => Promise<void>;
  goalWeights: Record<string, number>;
  goalOrder: (string | DefaultGoal)[];
  lockedWeightGoalIds: (string | DefaultGoal)[];
  handleUpdateLockedWeightGoalIds: React.Dispatch<React.SetStateAction<(string | DefaultGoal)[]>>;
  handleUpdateGoalOrder: React.Dispatch<React.SetStateAction<(DefaultGoal | string)[]>>;
  handleUpdateGoalWeights: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  wizardType: WizardType;
};

export type GoalItem = {
  name: string;
  id: DefaultGoal | string;
  goalKindDescription?: string;
  goalKind?: string;
  userDescription?: string;
};

const NUM_LOADING_SKELETON_GOALS = 4;

export const getCustomGoalKindDescription = (goal: CustomGoalItemWithUsage): string => {
  switch (goal.goalKind) {
    case GoalKind.BinaryGoal:
      return "Binary goal: Achieved/Missed";
    case GoalKind.ScoreGoal:
      return `Rated goal: Scored 1-${goal.maxScore}`;
    case GoalKind.CompoundGoal:
      return `Compound goal: Scored 1-${goal.maxScore}`;
  }
};

const GOAL_SUBTITLE_STYLES = {
  fontWeight: 500,
  fontSize: 12,
  color: getDynamicColor("dark4"),
  pl: 4,
  position: "relative",
  top: -4,
};

export const GoalSelector = ({
  goals,
  handleUpdateGoals,
  talkingPoints,
  handleUpdateTalkingPoints,
  handleUpdateCustomGoalType,
  goalTalkTime,
  handleUpdateGoalTalkTime,
  specialGoalIdSelected,
  setSpecialGoalIdSelected,
  scenarioTypeId,
  customGoals,
  customGoalsLoading,
  setCurrStepIndex,
  handleEditCustomGoal,
  goalWeights,
  lockedWeightGoalIds,
  handleUpdateLockedWeightGoalIds,
  handleUpdateGoalWeights,
  goalOrder,
  handleUpdateGoalOrder,
  wizardType,
}: GoalSelectorProps): JSX.Element => {
  useScrollToTop();
  const { defaultOrg } = React.useContext(UserOrgContext);

  const [myCustomGoals, setMyCustomGoals] = React.useState<CustomGoalItemWithUsage[]>([]);

  const [showGoalWeights, setShowGoalWeights] = React.useState<boolean>(
    !isEqualDistribution(goalWeights) || !goalWeights
  );
  // Talking points and Target Time goal state
  const [talkTimeMinutes, setTalkTimeMinutes] = React.useState(goalTalkTime.minutes);
  const [talkTimeSeconds, setTalkTimeSeconds] = React.useState(goalTalkTime.seconds);

  const defaultGoals = React.useMemo(() => {
    const goalArr =
      GOAL_OPTIONS[scenarioTypeId]?.map((goal: DefaultGoal) => {
        return { name: GoalLabels[goal], id: goal, userDescription: GoalDescriptions[goal] };
      }) ?? [];
    // move talking points to the front if it exists
    const talkingPointsInd = goalArr.findIndex((goal) => goal.id === DefaultGoal.HitTalkingPoints);
    if (talkingPointsInd > -1) {
      return [
        goalArr[talkingPointsInd],
        ...goalArr.filter((goal) => goal.id !== DefaultGoal.HitTalkingPoints),
      ];
    }
    return goalArr;
  }, [GOAL_OPTIONS]);

  const getGoalsExtraDetails = (inMenu: boolean) => {
    const goalsExtraDetails = {
      [DefaultGoal.HitTalkingPoints]: (
        <>
          {!talkingPoints.length && (
            <Typography sx={GOAL_SUBTITLE_STYLES}>
              Add at least one talking point below to add this goal
            </Typography>
          )}
          {/* prevent clicks inside the draggable items from closing the goal */}
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              zIndex: 3,
              cursor: "default",
              marginTop: "8px",
            }}
          >
            <WizardAddDraggableItems
              items={talkingPoints}
              handleUpdateItems={(talkingPoints) => {
                handleGoalSelectionChange(DefaultGoal.HitTalkingPoints, "add");
                handleUpdateTalkingPoints(talkingPoints);
              }}
              inputPlaceholder="e.g.  Used by Fortune 500 CEOs"
              maxItems={MAX_TALKING_POINTS}
              maxInputLength={MAX_CHAR_LENGTH_TALKING_POINT}
              itemLabel="Talking points"
              wrapperSx={{ pb: 0, gap: 0 }}
              itemListSx={{ maxHeight: 300 }}
              scrollBehaviorOptions={{ inline: "nearest", block: inMenu ? "nearest" : "start" }}
            />
          </div>
        </>
      ),
      [DefaultGoal.HitTimeTarget]: (
        <Stack gap={2}>
          <Typography sx={GOAL_SUBTITLE_STYLES}>
            {`Set how long the ${
              scenarioTypeId === ScenarioTypeIdEnum.PITCH ? "pitch" : "conversation"
            } should be (up to 60 minutes max)`}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              columnGap: 3,
              rowGap: 1,
            }}
          >
            <Stack direction="row" gap={1.5} alignItems="center">
              <YoodliTextfield
                type="number"
                autoFocus
                value={isNaN(talkTimeMinutes) ? "" : talkTimeMinutes}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  const value = restrictValueToRange(e.target.value, 0, 60);
                  setTalkTimeMinutes(parseInt(value.toString()));
                }}
                sx={{
                  width: 80,
                }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: getDynamicColor("purple3"),
                }}
              >
                minutes
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <YoodliTextfield
                type="number"
                value={isNaN(talkTimeSeconds) ? "" : talkTimeSeconds}
                onClick={(e) => e.stopPropagation()}
                inputProps={{ min: 0, max: 59 }}
                InputProps={{ inputProps: { min: 0, max: 59 } }}
                onChange={(e) => {
                  const value = restrictValueToRange(e.target.value, 0, 59);
                  setTalkTimeSeconds(parseInt(value.toString()));
                }}
                sx={{
                  width: 80,
                }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: getDynamicColor(
                    specialGoalIdSelected === DefaultGoal.HitTimeTarget ? "purple3" : "dark4"
                  ),
                }}
              >
                seconds
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ),
    };
    return goalsExtraDetails;
  };

  const goalsExtraTitleInfo = {
    [DefaultGoal.HitTalkingPoints]: (
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 2,
          color: getDynamicColor("dark4"),
        }}
      >
        {talkingPoints.length} added
      </Typography>
    ),
    [DefaultGoal.HitTimeTarget]: (
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 2,
          color: getDynamicColor("dark4"),
        }}
      >
        {talkTimeMinutes}M:{talkTimeSeconds}S
      </Typography>
    ),
  };

  React.useEffect(() => {
    if (!customGoalsLoading && customGoals) {
      const goals = customGoals.map((goal: CustomGoalItemWithUsage) => {
        return {
          name: goal.name,
          id: goal.id,
          goalKind: goal.goalKind,
          userDescription: goal.userDescription,
          usage: goal.usage,
        } as CustomGoalItemWithUsage;
      });
      const alphaSorted = goals.sort((a, b) =>
        a?.name.localeCompare(b?.name, undefined, { sensitivity: "base" })
      );
      setMyCustomGoals(alphaSorted);
    }
  }, [customGoalsLoading, customGoals]);

  React.useEffect(() => {
    updateGoalWeightingStates();
  }, [goals]);

  const updateGoalWeightingStates = (): {
    newGoalWeights: { [key: string]: number };
    newGoalOrder: string[];
  } => {
    // this is a new scenario, set good defaults
    let newGoalOrder = [...goals];
    let rebalancedGoalWeights = distributeWeightEvenly(goals, 100);
    if (!goalWeights || Object.keys(goalWeights).length === 0) {
      handleUpdateGoalWeights((_) => rebalancedGoalWeights);
      handleUpdateGoalOrder([...goals]);
      // new scenarios should have goal weights displayed by default
      setShowGoalWeights(true);
    }
    // this is an existing scenario with updated goals so weights, order, locks need to be updated
    else if (
      Object.keys(goalWeights).length !== goals.length ||
      !Object.keys(goalWeights).every((goalId) => goals.includes(goalId))
    ) {
      let newGoalWeights: { [key: string]: number } = {};
      goals.forEach((goal) => {
        if (!goalWeights[goal]) {
          newGoalWeights[goal] = 0;
        } else {
          newGoalWeights[goal] = goalWeights[goal] ?? 0;
        }
      });
      const currentLockedWeightIds = lockedWeightGoalIds.filter((id) => goals.includes(id));
      // a goal was removed and there's no unlocked goals to give the extra weight to
      if (
        Object.keys(goalWeights).length > goals.length &&
        currentLockedWeightIds.length === Object.keys(newGoalWeights).length
      ) {
        newGoalWeights = normalizeWeights(newGoalWeights);
      }
      const otherUnlockedWeights = [];
      let currRemainingWeight = 100;
      Object.keys(newGoalWeights).forEach((currGoalId) => {
        if (!currentLockedWeightIds.includes(currGoalId)) {
          otherUnlockedWeights.push(currGoalId);
        }
        // get remaining weight for even distribution amongst unlocked goals
        else {
          currRemainingWeight -= goalWeights[currGoalId];
        }
      });
      const evenlyDistributedUnlockedWeights = distributeWeightEvenly(
        otherUnlockedWeights,
        currRemainingWeight
      );
      rebalancedGoalWeights = {
        ...newGoalWeights,
        ...evenlyDistributedUnlockedWeights,
      };
      const totalSum = Object.entries(rebalancedGoalWeights).reduce(
        (sum, [, value]) => sum + value,
        0
      );
      if (totalSum !== 100) {
        rebalancedGoalWeights = normalizeWeights(rebalancedGoalWeights);
      }
      newGoalOrder = filterAndMergeArr(goalOrder, goals);
      handleUpdateGoalWeights(rebalancedGoalWeights);
      handleUpdateGoalOrder(newGoalOrder);
      handleUpdateLockedWeightGoalIds(currentLockedWeightIds);
    }

    return { newGoalWeights: rebalancedGoalWeights, newGoalOrder };
  };

  const restrictValueToRange = (value: string, min: number, max: number) => {
    if (value) {
      if (Number(value) < min) {
        return min;
      }
      if (Number(value) > max) {
        return max;
      }
      return value.split(".")[0];
    }
    return value;
  };

  const handleGoalSelectionChange = (goalId: string, action: "add" | "remove") => {
    if (action === "remove") {
      handleUpdateGoals((goals) => goals.filter((g) => g !== goalId));
      handleUpdateGoalOrder((order) => order.filter((g) => g !== goalId));
    } else if (!goals.includes(goalId)) {
      handleUpdateGoals((goals) => [...goals, goalId]);
      handleUpdateGoalOrder((order) => [...order, goalId]);
    }
  };

  const customGoalCreationMenuItems = [
    {
      title: renderCreateGoalMenuItem(GoalKind.BinaryGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.BinaryGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
    {
      title: renderCreateGoalMenuItem(GoalKind.ScoreGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.ScoreGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
    {
      title: renderCreateGoalMenuItem(GoalKind.CompoundGoal),
      onClick: () => {
        handleUpdateCustomGoalType(GoalKind.CompoundGoal);
        setCurrStepIndex((prev) => prev + 1);
      },
      type: YoodliMenuItemType.Default,
    },
  ];

  const nestedMenuItems = React.useMemo(() => {
    return [
      {
        title: "Default goal",
        subtitle: "Use a pre-built goal",
        subMenuContent: (
          <GoalList
            allGoals={defaultGoals}
            selectedGoals={goals}
            customGoals={customGoals}
            talkingPoints={talkingPoints}
            handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
            specialGoalIdSelected={specialGoalIdSelected}
            setSpecialGoalIdSelected={setSpecialGoalIdSelected}
            talkTimeMinutes={talkTimeMinutes}
            talkTimeSeconds={talkTimeSeconds}
            goalsExtraDetails={getGoalsExtraDetails(true)}
            goalsExtraTitleInfo={goalsExtraTitleInfo}
            handleGoalSelectionChange={handleGoalSelectionChange}
            setCurrStepIndex={setCurrStepIndex}
            handleEditCustomGoal={handleEditCustomGoal}
            handleUpdateCustomGoalType={handleUpdateCustomGoalType}
          />
        ),
      },
      defaultOrg?.custom_goal_enabled &&
        wizardType === WizardType.Org && {
          title: "From your library",
          subtitle: "Choose goals created by you or members of your organization",
          subMenuContent: (
            <GoalList
              showSearchBar
              allGoals={myCustomGoals}
              selectedGoals={goals}
              customGoals={customGoals}
              talkingPoints={talkingPoints}
              handleGoalSelectionChange={handleGoalSelectionChange}
              goalsExtraDetails={getGoalsExtraDetails(true)}
              goalsExtraTitleInfo={goalsExtraTitleInfo}
              setCurrStepIndex={setCurrStepIndex}
              handleEditCustomGoal={handleEditCustomGoal}
              handleUpdateCustomGoalType={handleUpdateCustomGoalType}
            />
          ),
        },
      defaultOrg?.custom_goal_enabled &&
        wizardType === WizardType.Org && {
          title: "Create a goal",
          subtitle: "Build a custom goal from scratch",
          subMenuContent: (
            <Stack
              width={"100%"}
              minWidth="150px"
              maxWidth="300px"
              py={1}
              gap={1}
              sx={{ background: getDynamicColor("light1") }}
            >
              {customGoalCreationMenuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={item.onClick}
                  sx={{
                    fontFamily: "Poppins",
                    width: "100%",
                    gap: 1,
                    color: getDynamicColor("purple3"),
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Stack>
          ),
        },
    ].filter(Boolean) as StackedMenuItem[];
  }, [goals, customGoals, talkingPoints, talkTimeMinutes, talkTimeSeconds, myCustomGoals]);

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ gap: 3, justifyContent: "space-between", alignItems: "center" }}>
        <StackedMenu
          menuItems={nestedMenuItems}
          buttonContent={
            <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
              <Typography>Add a goal</Typography>
              <ExpandMoreRounded />
            </Stack>
          }
          buttonSx={{
            alignItems: "center",
            justifyContent: "center",
            px: { xs: 1, md: 3 },
          }}
        />
      </Stack>
      {goals.length > 1 && (
        <Stack direction="row" sx={{ gap: 3, alignItems: "center" }}>
          <Stack direction="row" sx={{ gap: 0.5, alignItems: "center" }}>
            <RubricIcon />
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: 12,
                fontWeight: 500,
                color: getDynamicColor("purple3"),
              }}
            >
              Custom goal weighting
            </Typography>
            <YoodliTooltip title="Set a custom weight for each goal in your rubric, totaling 100%">
              <InfoOutlined sx={{ width: 16, height: 16 }} />
            </YoodliTooltip>
          </Stack>
          <IOSSwitch
            sizeVariant="small"
            checked={showGoalWeights}
            disabled={!!specialGoalIdSelected}
            onChange={(e) => {
              if (e.target.checked) {
                handleUpdateGoalWeights(distributeWeightEvenly(goals, 100));
                handleUpdateLockedWeightGoalIds([]);
              }
              setShowGoalWeights(e.target.checked);
            }}
          />
        </Stack>
      )}
      {customGoalsLoading &&
        Array(NUM_LOADING_SKELETON_GOALS)
          .fill(0)
          .map((_, ind) => (
            <Skeleton
              component="div"
              variant="rectangular"
              key={ind}
              sx={{
                borderRadius: "4px",
                backgroundColor: getDynamicColor("dark1"),
                p: 2,
                transition: "background-color 0.2s",
                position: "relative",
              }}
              height="62px"
              animation="wave"
            />
          ))}
      <GoalWeightSelector
        goals={goals}
        showGoalWeights={showGoalWeights && goals.length > 1}
        customGoals={customGoals}
        customGoalsLoading={customGoalsLoading}
        goalWeights={goalWeights}
        handleUpdateGoalWeights={handleUpdateGoalWeights}
        lockedWeightGoalIds={lockedWeightGoalIds}
        handleUpdateLockedWeightGoalIds={handleUpdateLockedWeightGoalIds}
        goalOrder={goalOrder}
        handleUpdateGoalOrder={handleUpdateGoalOrder}
        talkingPoints={talkingPoints}
        handleUpdateTalkingPoints={handleUpdateTalkingPoints}
        goalTalkTime={goalTalkTime}
        specialGoalIdSelected={specialGoalIdSelected}
        setSpecialGoalIdSelected={setSpecialGoalIdSelected}
        handleEditCustomGoal={handleEditCustomGoal}
        handleUpdateCustomGoalType={handleUpdateCustomGoalType}
        setCurrStepIndex={setCurrStepIndex}
        handleUpdateGoalTalkTime={handleUpdateGoalTalkTime}
        handleRemoveGoal={(goalId) => {
          handleGoalSelectionChange(goalId, "remove");
        }}
        talkTimeMinutes={talkTimeMinutes}
        talkTimeSeconds={talkTimeSeconds}
        setTalkTimeMinutes={setTalkTimeMinutes}
        setTalkTimeSeconds={setTalkTimeSeconds}
        goalsExtraDetails={getGoalsExtraDetails(false)}
        goalsExtraTitleInfo={goalsExtraTitleInfo}
      />
    </Stack>
  );
};
