import firebase from "firebase/app";
import React from "react";

// Components
import { Stack, Typography } from "@mui/material";
import {
  InterviewScenarioFilterOption,
  ScenarioFilterOption,
  SortFilterMenu,
  SortFilterType,
  SortOption,
} from "components/Builder/SortFilterMenu";
import {
  CustomizePracticeQueryKey,
  TemplateFilter,
  WizardState,
  getFilteredScenarioByType,
  getScenarioTypeLabel,
  templateFilterMap,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { TEMPLATE_SUB_TYPE_DATA } from "../../../../ConvoScenarios/convoScenarioUtils";
import { OrgScenarioTemplateGrid } from "./OrgScenarioTemplateGrid";
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { createScenario, listScenarios } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { CustomizePracticeTabEnum } from "lib-frontend/utils/orgUtils";
import { GetScenarioResponse } from "lib-fullstack/api/hubApiTypes";
import { OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { getArticle } from "utils/Utilities";
import { ScenarioType } from "lib-fullstack/utils/enums";

type SelectNewConvoScenarioTypeProps = {
  handleTemplateSelected: (scenario: GetScenarioResponse, scenarioId?: string) => void;
  selectedOrg: OrgV2Response;
  setWizardState: (state: WizardState) => void;
  scenarioTabType: CustomizePracticeTabEnum;
};

export const SelectNewConvoScenarioType = ({
  handleTemplateSelected,
  selectedOrg,
  setWizardState,
  scenarioTabType,
}: SelectNewConvoScenarioTypeProps): JSX.Element => {
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [typeFilter, setTypeFilter] = React.useState<SortOption>(undefined);

  const { defaultOrg, defaultOrgLoading } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [CustomizePracticeQueryKey.Scenarios, defaultOrg?.id],
    queryFn: async () => await listScenarios(defaultOrg?.id),
    enabled: !!firebase.auth().currentUser?.uid && !!defaultOrg && !defaultOrgLoading,
    refetchOnWindowFocus: false,
  });

  // segregating roleplay and interview scenarios
  const scenarioData = getFilteredScenarioByType(
    scenarioTabType,
    scenariosQuery.data?.contentArray ?? []
  );

  const templateFilterOptions = React.useMemo(() => {
    const scenarioTypeLabel = getScenarioTypeLabel(scenarioTabType);
    switch (scenarioTypeLabel) {
      case ScenarioType.Interview:
        return Object.keys(InterviewScenarioFilterOption).map(
          (key: InterviewScenarioFilterOption) => ({
            label: InterviewScenarioFilterOption[key] as string,
            value: key,
          })
        );
      default:
      case ScenarioType.Roleplay:
        return Object.keys(ScenarioFilterOption).map((key: ScenarioFilterOption) => ({
          label: ScenarioFilterOption[key] as string,
          value: key,
        }));
    }
  }, [scenarioTabType]);

  const templates = scenarioData.filter((scenario) => scenario.isTemplate) ?? [];
  const scenarioTypeLabel = getScenarioTypeLabel(scenarioTabType);

  const getFilteredAndSortedTemplates = React.useCallback(() => {
    const filteredScenarios = templates.filter((scenario) => {
      const scenarioType = templateFilterMap[scenario.scenarioTypeId] ?? TemplateFilter.OTHER;
      return (
        (!searchFilter || scenario.title.toLowerCase().includes(searchFilter.toLowerCase())) &&
        (!typeFilter || scenarioType === typeFilter.label)
      );
    });

    return filteredScenarios;
  }, [templates, searchFilter, typeFilter]);

  const hasScenarioIdTypeChecker = (res: unknown): res is { scenarioId: string } => {
    return Object.prototype.hasOwnProperty.call(res, "scenarioId");
  };

  const handleSelectAndCreateTemplate = async (scenario: GetScenarioResponse) => {
    const title = `My ${TEMPLATE_SUB_TYPE_DATA[scenario.templateSubType]?.label}`;
    const res = await createScenario(
      selectedOrg?.id,
      firebase.auth().currentUser?.email,
      title,
      scenario.id
    ).catch((er) => {
      console.error(`Error creating convo scenario: ${er}`);
    });

    if (!hasScenarioIdTypeChecker(res)) {
      console.error("Error creating convo scenario: no scenarioId was returned");
      return;
    }
    handleTemplateSelected(scenario, res?.scenarioId);
    setWizardState("create");
    // dont wait here since we want the UI to update to the wizard immediately
    void scenariosQuery.refetch();
  };

  const renderFilters = () => {
    return (
      <Stack
        direction="row"
        rowGap={1}
        justifyContent={"flex-end"}
        alignItems={{ xs: "flex-start", md: "center" }}
        sx={{
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={{ xs: 0, md: "20px" }}
          sx={{ alignItems: "center", justifyContent: "flex-end", flexGrow: 1 }}
        >
          <SortFilterMenu
            sortFilterType={SortFilterType.Filter}
            sortBy={typeFilter}
            setSortBy={setTypeFilter}
            sortOptions={[...templateFilterOptions]}
          />
          <YoodliSearchBar
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            clearSearch={() => setSearchFilter("")}
            placeholder="Search"
            InputProps={{
              sx: {
                width: { xs: 160, sm: 200 },
                height: "35px",
              },
            }}
            sx={{
              width: { xs: 160, sm: 200 },
              height: "35px",
            }}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack
      gap={1}
      sx={{
        p: { xs: 2, md: 0 },
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 22, md: 24 },
          textAlign: "center",
          fontFamily: "poppins",
          color: getDynamicColor("purple3"),
          fontWeight: 700,
          mx: "auto",
        }}
      >
        Create {getArticle(scenarioTypeLabel)} {scenarioTypeLabel} scenario
      </Typography>
      {renderFilters()}
      <Stack gap={3}>
        <OrgScenarioTemplateGrid
          scenarios={getFilteredAndSortedTemplates()}
          handleSelectTemplate={handleSelectAndCreateTemplate}
        />
      </Stack>
    </Stack>
  );
};
