import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Components
import { RephraseOrGenerateButton } from "../../RephraseOrGenerateButton";
import {
  DragIndicator as DragIndicatorIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  AddCircle as AddIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography, CircularProgress } from "@mui/material";
import {
  CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_NAME_MAX_CHARS,
  CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS,
  CUSTOM_GOAL_TEXTFIELD_MIN_ROWS,
  GoalHooks,
  subGoalBase,
} from "components/ConvoScenarios/convoScenarioUtils";
import { YoodliLabeledInput } from "lib-frontend/components/YoodliComponents/YoodliLabeledInput";
import YoodliTextfield from "lib-frontend/components/YoodliComponents/YoodliTextfield";

// Utils
import { ConvoScenarioStepWrapper } from "./ConvoScenarioStepWrapper";
import { Y_SHADOWS, getDynamicColor } from "lib-frontend/utils/Colors";
import { CreateCompoundGoalRequest } from "lib-fullstack/api/scenarioApiTypes";
import { reorder } from "utils/Utilities";
import {
  CtaButtonHandlers,
  YoodliCtaModal,
  YoodliCtaModalTheme,
} from "lib-frontend/components/YoodliComponents/YoodliCtaModal";
import { getGoalRephrase } from "utils/GPTUtils";

const MIN_COMPOUND_SUBGOALS_COUNT = 2;
const MAX_COMPOUND_SUBGOALS_COUNT = 6;

type SubGoal = {
  name: string;
  aiDescription: string;
  highScoreDescription: string;
  lowScoreDescription: string;
};

enum SectionStatus {
  Overview = "overview",
  CreateSubgoal = "createSubgoal",
}

const SUB_GOAL_STYLES = {
  display: "flex",
  flexDirection: "row",
  gap: 3,
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 1.5,
  backgroundColor: getDynamicColor("light1"),
  border: `1px solid ${getDynamicColor("dark4")}`,
  padding: 2,
  svg: {
    color: getDynamicColor("primary"),
    p: 0.1,
    height: "20px",
    width: "20px",
  },
  "> p": {
    width: "100%",
  },
  // use mb not gap here so the placeholder while dragging renders correctly
  mb: 1,
};

type CreateSubgoalViewProps = {
  minScore: number;
  maxScore: number;
  selectedSubGoal?: SubGoal;
  subGoals: SubGoal[];
  setSubGoals: React.Dispatch<React.SetStateAction<SubGoal[]>>;
  setSectionStatus: React.Dispatch<React.SetStateAction<SectionStatus>>;
  setSelectedSubGoal?: React.Dispatch<React.SetStateAction<SubGoal>>;
  goalHooks: GoalHooks;
};

const CreateSubgoalView = ({
  minScore,
  maxScore,
  subGoals,
  setSubGoals,
  selectedSubGoal,
  setSectionStatus,
  setSelectedSubGoal,
  goalHooks,
}: CreateSubgoalViewProps): JSX.Element => {
  const [subGoal, setSubGoal] = React.useState<SubGoal>(selectedSubGoal ?? subGoalBase);

  const handleUpdateSubGoal = (key: keyof SubGoal, val: string) => {
    setSubGoal((prev) => ({ ...prev, [key]: val }));
  };

  const handleSaveSubGoal = () => {
    if (selectedSubGoal) {
      const updatedSubGoals = subGoals?.map((subGoalItem) =>
        subGoalItem === selectedSubGoal ? subGoal : subGoalItem
      );
      setSubGoals(updatedSubGoals ?? []);
      setSelectedSubGoal(undefined);
    } else {
      setSubGoals([...(subGoals ?? []), subGoal]);
    }
    setSectionStatus(SectionStatus.Overview);
  };

  return (
    <Stack
      gap={4}
      sx={{
        borderRadius: "6px",
        position: "relative",
        p: 2,
        boxShadow: Y_SHADOWS.box_shadow_1,
        color: getDynamicColor("purple3"),
      }}
    >
      <Stack gap={1}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            color: getDynamicColor("purple3"),
            fontFamily: "Poppins",
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>Add a goal</Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "12px", color: getDynamicColor("dark4") }}>
            All fields are required
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: "500",
          }}
        >
          Explain the goal and scoring definition to the AI.
        </Typography>
      </Stack>
      <YoodliLabeledInput
        label="Goal name"
        labelSx={{ fontSize: "12px", fontWeight: 600, color: getDynamicColor("purple3") }}
        inputEl={
          <YoodliTextfield
            inputProps={{
              className: "blockEnterToNavigate",
            }}
            autoFocus
            maxChars={CUSTOM_GOAL_NAME_MAX_CHARS}
            placeholder="e.g. Reality"
            InputLabelProps={{ shrink: true }}
            value={subGoal.name}
            onChange={(e) => handleUpdateSubGoal("name", e.target.value)}
            sx={{
              backgroundColor: getDynamicColor("light1"),
            }}
          />
        }
      />
      <YoodliLabeledInput
        label={
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "poppins",
                color: getDynamicColor("dark5"),
                mb: 0.5,
              }}
            >
              Explanation
            </Typography>
            <RephraseOrGenerateButton
              lengthToCheck={subGoal.aiDescription.length}
              onClick={async () => {
                goalHooks.setGoalDescriptionLoading(true);
                const rephrase = await getGoalRephrase(
                  subGoal.aiDescription,
                  "description",
                  subGoal.name,
                  ""
                );
                handleUpdateSubGoal("aiDescription", rephrase);
                goalHooks.setGoalDescriptionLoading(false);
              }}
            />
          </Stack>
        }
        labelSx={{ fontSize: "12px", fontWeight: 600, color: getDynamicColor("purple3") }}
        inputEl={
          <Box sx={{ position: "relative" }}>
            {goalHooks.goalDescriptionLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <YoodliTextfield
              multiline
              minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              maxChars={CUSTOM_GOAL_OVERALL_AI_EXPLANATION_MAX_CHARS}
              placeholder="e.g. Active listening consists of asking questions, repeating back information, and leaving space for the other person to talk. This is important to showing understanding of client needs."
              InputLabelProps={{ shrink: true }}
              value={subGoal.aiDescription}
              onChange={(e) => handleUpdateSubGoal("aiDescription", e.target.value)}
              disabled={goalHooks.goalDescriptionLoading}
            />
          </Box>
        }
      />
      <YoodliLabeledInput
        label={
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "poppins",
                color: getDynamicColor("dark5"),
                mb: 0.5,
              }}
            >{`Define a score of ${minScore} (minimum score)`}</Typography>
            <RephraseOrGenerateButton
              lengthToCheck={subGoal.lowScoreDescription.length}
              onClick={async () => {
                goalHooks.setGoalLowScoreLoading(true);
                const rephrase = await getGoalRephrase(
                  subGoal.lowScoreDescription,
                  "low",
                  subGoal.name,
                  subGoal.aiDescription
                );
                handleUpdateSubGoal("lowScoreDescription", rephrase);
                goalHooks.setGoalLowScoreLoading(false);
              }}
            />
          </Stack>
        }
        labelSx={{ fontSize: "12px", fontWeight: 600, color: getDynamicColor("purple3") }}
        inputEl={
          <Box sx={{ position: "relative" }}>
            {goalHooks.goalLowScoreLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <YoodliTextfield
              multiline
              minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
              placeholder="e.g. The member did not ask any questions, misunderstood what the client was saying, and talked for more than half of the call."
              InputLabelProps={{ shrink: true }}
              value={subGoal.lowScoreDescription}
              onChange={(e) => handleUpdateSubGoal("lowScoreDescription", e.target.value)}
              disabled={goalHooks.goalLowScoreLoading}
            />
          </Box>
        }
      />
      <YoodliLabeledInput
        label={
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "poppins",
                color: getDynamicColor("dark5"),
                mb: 0.5,
              }}
            >{`Define a score of ${maxScore} (maximum score)`}</Typography>
            <RephraseOrGenerateButton
              lengthToCheck={subGoal.highScoreDescription.length}
              onClick={async () => {
                goalHooks.setGoalHighScoreLoading(true);
                const rephrase = await getGoalRephrase(
                  subGoal.highScoreDescription,
                  "high",
                  subGoal.name,
                  subGoal.aiDescription
                );
                handleUpdateSubGoal("highScoreDescription", rephrase);
                goalHooks.setGoalHighScoreLoading(false);
              }}
            />
          </Stack>
        }
        labelSx={{ fontSize: "12px", fontWeight: 600, color: getDynamicColor("purple3") }}
        inputEl={
          <Box sx={{ position: "relative" }}>
            {goalHooks.goalHighScoreLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <YoodliTextfield
              multiline
              minRows={CUSTOM_GOAL_TEXTFIELD_MIN_ROWS}
              inputProps={{
                className: "blockEnterToNavigate",
              }}
              maxChars={CUSTOM_GOAL_AI_EXPLANATION_MAX_CHARS}
              placeholder="e.g. The member asked good questions and left space for the client to respond."
              InputLabelProps={{ shrink: true }}
              value={subGoal.highScoreDescription}
              onChange={(e) => handleUpdateSubGoal("highScoreDescription", e.target.value)}
              disabled={goalHooks.goalHighScoreLoading}
            />
          </Box>
        }
      />
      <Stack direction="row" gap={4} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
        <Button
          onClick={() => {
            setSelectedSubGoal(undefined);
            setSubGoals([...subGoals]);
            setSectionStatus(SectionStatus.Overview);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveSubGoal}
          variant="outlined"
          disabled={
            subGoal.name.length === 0 ||
            subGoal.aiDescription.length === 0 ||
            subGoal.highScoreDescription.length === 0 ||
            subGoal.lowScoreDescription.length === 0 ||
            goalHooks.goalDescriptionLoading ||
            goalHooks.goalLowScoreLoading ||
            goalHooks.goalHighScoreLoading
          }
        >
          {selectedSubGoal ? "Update" : "Add"}
        </Button>
      </Stack>
    </Stack>
  );
};

type CreateCompoundGoalProps = {
  compoundGoal: CreateCompoundGoalRequest;
  setCompoundGoal: React.Dispatch<React.SetStateAction<CreateCompoundGoalRequest>>;
  goalEditId?: string;
  goalHooks: GoalHooks;
};

export const CreateCompoundGoalSubGoalStep = ({
  compoundGoal,
  setCompoundGoal,
  goalEditId,
  goalHooks,
}: CreateCompoundGoalProps): JSX.Element => {
  const [subGoals, setSubGoals] = React.useState<SubGoal[]>(compoundGoal.subGoals ?? []);
  const [selectedSubGoal, setSelectedSubGoal] = React.useState<SubGoal>(undefined);
  const [deleteSubGoalModalOpen, setDeleteSubGoalModalOpen] = React.useState<boolean>(false);
  const [idToDelete, setIdToDelete] = React.useState<number>(undefined);

  const [sectionStatus, setSectionStatus] = React.useState<SectionStatus>(SectionStatus.Overview);

  const handleUpdateSubGoals = (subGoals: SubGoal[]) => {
    setSubGoals(subGoals);
    setCompoundGoal((prev) => ({ ...prev, subGoals: subGoals }));
  };

  // Setting subgoals to undefined to disable wizard footer controls
  const handleSubgoalEdit = (subGoal?: SubGoal) => {
    setCompoundGoal((prev) => ({ ...prev, subGoals: undefined }));
    if (subGoal) {
      setSelectedSubGoal(subGoal);
    }
    setSectionStatus(SectionStatus.CreateSubgoal);
  };

  const handleSubgoalDrag = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedSubgoals = reorder(subGoals, result.source.index, result.destination.index);
    handleUpdateSubGoals(reorderedSubgoals);
  };

  const handleSubgoalDelete = (idx: number) => {
    const newItems = subGoals.filter((_, index) => index !== idx);
    handleUpdateSubGoals(newItems);
  };

  const renderOverviewSection = () => (
    <ConvoScenarioStepWrapper
      title={goalEditId ? "Update Compound Goal" : "Create a Compound Goal"}
      subTitle={
        <Typography component="span">
          Define {MIN_COMPOUND_SUBGOALS_COUNT} to {MAX_COMPOUND_SUBGOALS_COUNT} goals that comprise{" "}
          <Typography component="span" sx={{ fontWeight: 700 }}>
            {compoundGoal.name}
          </Typography>
          . Each goal will be scored on a{" "}
          <Typography component="span" sx={{ fontWeight: 700 }}>
            scale of 1-{compoundGoal.maxScore}
          </Typography>
          .
        </Typography>
      }
    >
      <Stack
        sx={{
          position: "relative",
        }}
      >
        <DragDropContext onDragEnd={handleSubgoalDrag}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef} direction="column">
                {subGoals.map((subgoal, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={index.toString()} // According to docs, this needs to be a string
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            ...SUB_GOAL_STYLES,
                          }}
                        >
                          {provided.placeholder}
                          <DragIndicatorIcon style={{ cursor: "grab" }} />
                          <Typography
                            sx={{
                              fontSize: 14,
                              textAlign: "left",
                              color: getDynamicColor("purple3"),
                              fontWeight: "600",
                            }}
                          >
                            {subgoal.name}
                          </Typography>
                          <Stack direction="row">
                            <IconButton onClick={() => handleSubgoalEdit(subgoal)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setIdToDelete(index);
                                setDeleteSubGoalModalOpen(true);
                              }}
                              size="medium"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        {subGoals.length < MAX_COMPOUND_SUBGOALS_COUNT &&
          Array(Math.max(MIN_COMPOUND_SUBGOALS_COUNT - subGoals.length, 1))
            .fill(0)
            .map((_, idx) => (
              <Box
                onClick={() => handleSubgoalEdit()}
                key={idx}
                sx={{
                  ...SUB_GOAL_STYLES,
                  svg: {
                    transition: "color 0.2s",
                  },
                  color: getDynamicColor("dark4"),
                  border: `1px dashed ${getDynamicColor("dark4")}`,
                  "&:hover": {
                    color: getDynamicColor("purple3"),
                    border: `1px solid ${getDynamicColor("dark4")}`,
                    svg: {
                      color: getDynamicColor("greenSuccess"),
                    },
                  },
                  transition: "all 0.2s",
                  cursor: "pointer",
                }}
              >
                <AddIcon sx={{ color: getDynamicColor("dark4") }} />
                <Typography
                  sx={{
                    fontSize: 14,
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                >
                  Add a goal
                </Typography>
              </Box>
            ))}
      </Stack>
      <YoodliCtaModal
        ctaBody={{
          title: "Delete this subgoal?",
          subtitle: "This action cannot be undone.",
        }}
        open={deleteSubGoalModalOpen}
        theme={YoodliCtaModalTheme.Danger}
        hideCloseButton={true}
        close={() => setDeleteSubGoalModalOpen(false)}
        buttons={
          {
            primary: {
              text: "Delete subgoal",
              handler: async () => {
                handleSubgoalDelete(idToDelete);
              },
            },
            secondary: { text: "Cancel", handler: () => setDeleteSubGoalModalOpen(false) },
          } as CtaButtonHandlers
        }
      />
    </ConvoScenarioStepWrapper>
  );

  const renderCreateSubgoalSection = () => (
    <ConvoScenarioStepWrapper
      title={goalEditId ? "Update Compound Goal" : "Create a Compound Goal"}
      subTitle={
        <Typography component="span">
          Define {MIN_COMPOUND_SUBGOALS_COUNT} to {MAX_COMPOUND_SUBGOALS_COUNT} goals that comprise{" "}
          <Typography component="span" sx={{ fontWeight: 700 }}>
            {compoundGoal.name}
          </Typography>
          . Each goal will be scored on a{" "}
          <Typography component="span" sx={{ fontWeight: 700 }}>
            scale of 1-{compoundGoal.maxScore}
          </Typography>
          .
        </Typography>
      }
    >
      <CreateSubgoalView
        minScore={1}
        maxScore={compoundGoal.maxScore}
        subGoals={subGoals}
        setSubGoals={handleUpdateSubGoals}
        setSectionStatus={setSectionStatus}
        selectedSubGoal={selectedSubGoal}
        setSelectedSubGoal={setSelectedSubGoal}
        goalHooks={goalHooks}
      />
    </ConvoScenarioStepWrapper>
  );

  switch (sectionStatus) {
    case SectionStatus.Overview:
      return renderOverviewSection();
    case SectionStatus.CreateSubgoal:
      return renderCreateSubgoalSection();
  }
};
