// Utils
import { getDynamicColor } from "./Colors";
import {
  getHubContentAdminViewV2,
  listHubUsersV2,
  listOrgInvitesV2,
  listOrgUsersV2,
} from "lib-frontend/modules/AxiosInstance";
import { HubV2Response, OrgV2Response } from "lib-fullstack/api/orgApiTypes";
import { ProgramMemberViewItem, ProgramUserItem } from "lib-fullstack/api/programApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { asyncMap } from "lib-fullstack/utils/asyncMap";
import { MONTH_IN_MS } from "lib-fullstack/utils/constants";
import { getHumanReadableDate } from "lib-fullstack/utils/dateUtils";
import {
  HubRole,
  EffectiveRole,
  OrgSubscriptionType,
  ScenarioType,
} from "lib-fullstack/utils/enums";
import { IntegrationTypeEnum } from "lib-fullstack/utils/enums/integrationType";
import { getWebServerExternalUrl, WebServerExternalPath } from "lib-fullstack/utils/paths";
import { OrgInviteQueryParams, PracticeRecorderQueryParams } from "lib-fullstack/utils/queryParams";

const LIST_HUB_CONCURRENCY = 3;
const MAX_LIST_HUB_RETRY = 3;

export enum CustomizePracticeTabEnum {
  RoleplayScenarios = "roleplay_scenarios",
  Personas = "personas",
  CustomGoals = "custom_goals",
  InterviewScenarios = "interview_scenarios",
  QuestionBanks = "question_banks",
}

export enum OrgSettingsTabs {
  CUSTOMIZE_PRACTICE = "customize-practice",
  COACHBOT = "coachbot",
  VIDEOS_COURSES = "welcome-videos-and-courses",
}

export enum OrgSettingsTabLabel {
  CUSTOMIZE_PRACTICE = "Customize Practice",
  COACHBOT = "Coach Bot",
  VIDEOS_COURSES = "Welcome videos & courses",
}

export enum AvailableContentTabEnum {
  RoleplayScenarios = "roleplay_scenarios",
  InterviewScenarios = "interview_scenarios",
  CoursesAndVideos = "courses_and_videos",
  QuestionBanks = "question_banks",
}

export enum OrgIntegrationQueryKeys {
  ORG_INTEGRATIONS = "orgIntegrations",
  ORG_INTEGRATION_DETAILS = "orgIntegrationDetails",
  ORG_INTEGRATION_SECRETS = "orgIntegrationSecrets",
  ORG_INTEGRATION_CONTEXTS = "orgIntegrationContexts",
}

export enum TableTabLabel {
  Members = "members",
  Invites = "invites",
}

export const AvailableContentTabLabels = {
  [AvailableContentTabEnum.CoursesAndVideos]: "Courses & Welcome Videos",
  [AvailableContentTabEnum.RoleplayScenarios]: "Roleplay Scenarios",
  [AvailableContentTabEnum.InterviewScenarios]: "Interview Scenarios",
  [AvailableContentTabEnum.QuestionBanks]: "Question Banks",
};

export const LtiIntegrations = [IntegrationTypeEnum.LTI_1_1, IntegrationTypeEnum.LTI_1_3];

export const getHubUserCount = async (orgId: string, hubId: string): Promise<number> => {
  return (
    await listHubUsersV2(orgId, hubId, {
      limit: "1",
    })
  ).total;
};

export const getHubInviteCount = async (orgId: string, hubId: string): Promise<number> => {
  return (await listOrgInvitesV2(orgId, { hub_id: hubId, limit: "1" })).total;
};

export const getOrgUserCount = async (orgId: string): Promise<number> => {
  return (await listOrgUsersV2(orgId, { limit: "1" })).total;
};

export const getOrgInviteCount = async (orgId: string): Promise<number> => {
  return (await listOrgInvitesV2(orgId, { limit: "1" })).total;
};

export type HubData = {
  id: string;
  name: string;
  isDefault: boolean;
  creationDate: string;
  numMembers: number;
  numCourses: number;
};

/**
 * Get hub data for an organization to display in org overview
 */
export const getHubDataForOrg = async (org: OrgV2Response): Promise<HubData[]> => {
  const response = [];
  await asyncMap(
    org.hubs,
    async (hub) => {
      // limit 1 because we just want the count
      const hubUsers = await listHubUsersV2(org.id, hub.id, { limit: "1" });
      const hubContent = await getHubContentAdminViewV2(org.id, hub.id);
      response.push({
        id: hub.id,
        name: hub.name,
        isDefault: hub.org_default,
        creationDate: hub.creation_date,
        numMembers: hubUsers.total,
        numCourses: hubContent.courses.length,
      });
    },
    LIST_HUB_CONCURRENCY,
    MAX_LIST_HUB_RETRY
  );

  return response;
};

export const isOrgTrialEnded = (org?: OrgV2Response): boolean => {
  return org?.license_count === 0 && org?.subscription_type === OrgSubscriptionType.PAYG;
};

// return true if org is flexible and seats within a month of contract expiring, meaning the seats should not
// be allowed to be edited any further
export const isOrgFlexibleAndSeatsUneditable = (org?: OrgV2Response): boolean => {
  return (
    org?.subscription_type === OrgSubscriptionType.FLEXIBLE &&
    new Date(org?.cancellation_date).getTime() - Date.now() < MONTH_IN_MS
  );
};

export const isOrgOwnerAdmin = (org?: OrgV2Response): boolean => {
  return (
    org?.effective_role === EffectiveRole.ORG_OWNER ||
    org?.effective_role === EffectiveRole.ORG_ADMIN
  );
};

export const isHubAdmin = (hub?: HubV2Response): boolean => {
  return hub.hub_role === HubRole.ADMIN;
};

export const parseOrgRole = (role?: EffectiveRole): string => {
  switch (role) {
    case EffectiveRole.ORG_OWNER:
      return "Org owner";
    case EffectiveRole.ORG_ADMIN:
      return "Org admin";
    case EffectiveRole.HUB_ADMIN:
      return "Hub admin";
    default:
      return "Member";
  }
};

export const parseHubRole = (role: HubRole): string => {
  switch (role) {
    case HubRole.ADMIN:
      return "Group admin";
    case HubRole.MEMBER:
    default:
      return "Member";
  }
};

export const parseProgramProgressDate = (item: ProgramUserItem | ProgramMemberViewItem): string => {
  if (item.completion_date) {
    return `Completed on ${getHumanReadableDate(item.completion_date)}`;
  } else if (item.last_progress_date) {
    return `Last progress ${getHumanReadableDate(item.last_progress_date)}`;
  } else {
    return "No progress";
  }
};

export const getScenarioPracticePath = (
  scenarioId: string,
  defaultOrgId: string | null,
  returnFullPath: boolean,
  scenarioType: ScenarioType = ScenarioType.Roleplay
): string => {
  let practiceLink = "";
  switch (scenarioType) {
    case ScenarioType.Roleplay:
      practiceLink = WebServerExternalPath.PRACTICE_CONVERSATION;
      break;
    case ScenarioType.Interview:
      practiceLink = WebServerExternalPath.PRACTICE_INTERVIEW;
      break;
  }
  if (returnFullPath) {
    practiceLink = getWebServerExternalUrl(
      getClientEnvConfig(),
      practiceLink as WebServerExternalPath
    );
  }

  let url = `${practiceLink}?${PracticeRecorderQueryParams.SCENARIO}=${scenarioId}`;
  if (defaultOrgId !== null) {
    url += `&${OrgInviteQueryParams.OVERRIDE_ORG_ID}=${defaultOrgId}`;
  }

  return url;
};

export const getInterviewQuestionBankPracticePath = (
  bankId: string,
  defaultOrgId: string | null,
  returnFullPath: boolean
): string => {
  let practiceLink = `${WebServerExternalPath.PRACTICE_INTERVIEW}`;

  if (returnFullPath) {
    practiceLink = getWebServerExternalUrl(
      getClientEnvConfig(),
      practiceLink as WebServerExternalPath
    );
  }

  let url = `${practiceLink}?${PracticeRecorderQueryParams.QUESTION_BANK}=${bankId}`;
  if (defaultOrgId !== null) {
    url += `&${OrgInviteQueryParams.OVERRIDE_ORG_ID}=${defaultOrgId}`;
  }

  return url;
};

// returns a time difference string representing the time difference between the given date and the current date
// in the format of "1 day ago" or "3 min ago"
export const getTimeDifference = (isoString: string): string => {
  if (!isoString) {
    return "No activity";
  }
  const givenDate = new Date(isoString);
  const currentDate = new Date();
  const diffInMs = currentDate.getTime() - givenDate.getTime();

  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInSeconds < 60) {
    return "less than a minute ago";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else if (diffInDays <= 100) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInMonths <= 11) {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  } else {
    return "Over a year ago";
  }
};

export const DraggableStyles = {
  display: "flex",
  flexDirection: "row",
  gap: 3,
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 1.5,
  backgroundColor: getDynamicColor("light1"),
  border: `1px solid ${getDynamicColor("dark4")}`,
  padding: 2,
  minHeight: 60,
  svg: {
    color: getDynamicColor("primary"),
  },
  "> p": {
    width: "100%",
  },
  // use mb not gap here so the placeholder while dragging renders correctly
  mb: 1,
};
