// Utils
import { RTStringEnum } from "lib-fullstack/utils/runtypesHelpers";

/**
 * Enum indicating the view mode of the activity.
 */
export enum ActivityViewModeEnum {
  /**
   * The activity is displayed in a browser window or tab.
   */
  Browser = "browser",
  /**
   * The activity is displayed in an iframe within a browser window or tab.
   */
  Embedded = "embedded",
}

/**
 * Enum indicating the view mode of the activity.
 */
export const ActivityViewMode = RTStringEnum(ActivityViewModeEnum);
