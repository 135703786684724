import React from "react";
import { DateTime } from "luxon";
// Components
import { ArrowDropDownCircle as ArrowDropDownCircleIcon } from "@mui/icons-material";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import {
  DashboardGoal,
  FOCUS_ANALYTICS,
  HoistedAnalytic,
} from "components/Dashboard/DashboardTypes";
import ReportSnapshot from "components/Dashboard/ReportSnapshot/ReportSnapshot";
import { StackedMenu } from "components/Orgs/ManageContent/CustomGoals/StackedMenu";
import { YoodliDatePicker } from "lib-frontend/components/YoodliComponents/YoodliDatePicker";
import { YoodliSearchBar } from "lib-frontend/components/YoodliComponents/YoodliSearchBar";

// Utils
import { useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { getAllCustomGoals } from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { DashboardError, DashboardSuccess } from "lib-fullstack/api/apiTypes";
import { OrgMemberResponse } from "lib-fullstack/api/orgApiTypes";
import { DefaultGoal, UnitCountAnalyticEnum } from "lib-fullstack/db";
import { GoalDescriptions } from "lib-fullstack/utils/defaultGoals";
import { GoalLabels } from "lib-fullstack/utils/defaultGoals";
import { fetchAggAnalytics, mapAnalyticsToMetadata } from "utils/DashboardUtils";

const GOAL_PROGRESS_ANALYTICS_QUERY_KEY = "goalProgressAnalytics";

const GOAL_PROGRESS_CUSTOM_GOALS_QUERY_KEY = "goalProgressCustomGoals";

const USAGE_METRICS = Object.values(UnitCountAnalyticEnum);

const analytics = Object.values(FOCUS_ANALYTICS).filter(
  (metric) => !USAGE_METRICS.includes(metric.identifier as (typeof USAGE_METRICS)[number])
);
const usageMetrics = Object.values(FOCUS_ANALYTICS).filter((metric) =>
  USAGE_METRICS.includes(metric.identifier as (typeof USAGE_METRICS)[number])
);
export const GoalProgress = ({ user }: { user: OrgMemberResponse }): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const menuRef = React.useRef(null);

  const [startDate, setStartDate] = React.useState(DateTime.now().minus({ days: 7 }));
  const [endDate, setEndDate] = React.useState(DateTime.now());

  const [selectedMetricId, setSelectedMetricId] = React.useState<string>(
    FOCUS_ANALYTICS["filler"].identifier
  );

  const [selectedMetricData, setSelectedMetricData] = React.useState<HoistedAnalytic>();

  const [searchQuery, setSearchQuery] = React.useState("");

  const defaultGoals: DashboardGoal[] = React.useMemo(
    () =>
      Object.values(DefaultGoal).map((goal) => ({
        identifier: goal,
        label: GoalLabels[goal],
        definition: GoalDescriptions[goal],
      })),
    [DefaultGoal]
  );

  const analyticsQuery = useApiQuery({
    queryKey: [
      GOAL_PROGRESS_ANALYTICS_QUERY_KEY,
      startDate.toISO(),
      endDate.toISO(),
      user?.user_id,
      selectedMetricId,
      defaultOrg?.id,
    ],
    queryFn: async () => {
      const response = await fetchAggAnalytics(
        [selectedMetricId],
        startDate.setZone("utc").startOf("day").toISO(),
        endDate.setZone("utc").endOf("day").toISO(),
        user?.user_id
      );
      let data: DashboardSuccess["analytics"] = {};
      // if response ok, map to sahsboard success type
      if (response.ok) {
        // map response to type DashboardSuccess
        data = (response as DashboardSuccess).analytics;
      } else {
        if ((response as DashboardError).error.includes("no data")) {
          setSelectedMetricData(null);
          console.log("Failed to fetch analytics :: ", response);
          throw new Error("Failed to fetch analytics");
        }
      }
      let selectedMetricData: HoistedAnalytic | DashboardGoal;
      if (FOCUS_ANALYTICS[selectedMetricId]) {
        selectedMetricData = FOCUS_ANALYTICS[selectedMetricId];
      } else {
        const defaultGoalData = defaultGoals.find((goal) => goal.identifier === selectedMetricId);
        if (defaultGoalData) {
          selectedMetricData = {
            ...defaultGoalData,
            type: "goal",
          } as HoistedAnalytic;
        } else {
          const customGoalMetadata = customGoalsQuery.data?.find(
            (goal) => goal.identifier === selectedMetricId
          );
          if (customGoalMetadata) {
            // else its a cusotm goal so just map the data to the custom goal
            selectedMetricData = {
              ...customGoalMetadata,
              data: data[selectedMetricId]?.graphData,
              counted: data[selectedMetricId]?.counted,
              relevantSlugs: data[selectedMetricId]?.relevantSlugs,
            } as HoistedAnalytic;
          }
        }
      }
      console.log("wtf m8, just fetched...?", selectedMetricData);
      if (selectedMetricData) {
        const mappedData = await mapAnalyticsToMetadata(data, defaultOrg?.id);
        setSelectedMetricData(mappedData[0]);
      } else {
        setSelectedMetricData(null);
      }
      return analytics;
    },
    enabled: !!selectedMetricId && !!user?.user_id && !!defaultOrg?.id,
    refetchOnWindowFocus: false,
  });

  // fetch a list of custom goals, data for which to be retreived from the analytics query
  const customGoalsQuery = useApiQuery({
    queryKey: [GOAL_PROGRESS_CUSTOM_GOALS_QUERY_KEY, defaultOrg?.id, user.user_id],
    queryFn: async () => {
      const customGoals = await getAllCustomGoals(defaultOrg?.id);
      const mappedGoals = customGoals?.goals?.map((goal) => ({
        identifier: goal.id,
        label: goal.name,
        definition: goal.userDescription,
        goalKind: goal.goalKind,
        type: "goal",
      }));
      return mappedGoals;
    },
    refetchOnWindowFocus: false,
  });

  const numDays = Math.ceil(endDate.diff(startDate, "days").days);
  const subMenuWrapperSx = {
    minWidth: { xs: 300, md: 375 },
    p: 1.5,
    py: "20px !important",
  };

  const handleMetricSelected = (metricId: string) => {
    setSelectedMetricId(metricId);
    menuRef.current?.closeMenu();
    setSearchQuery("");
  };

  const getMetricLabel = (metricId: string) => {
    if (FOCUS_ANALYTICS[metricId]) {
      return FOCUS_ANALYTICS[metricId].label;
    }
    // else check default goals
    const defaultGoalData = defaultGoals.find((goal) => goal.identifier === metricId);
    if (defaultGoalData) {
      return defaultGoalData.label;
    }
    // else check custom goals
    const customGoalData = customGoalsQuery.data?.find((goal) => goal.identifier === metricId);
    if (customGoalData) {
      return customGoalData.label;
    }
    return "Choose metric";
  };

  return (
    <Stack
      gap={{ xs: 1.5, sm: 2.5 }}
      sx={{
        width: "100%",
        border: `1px solid ${getDynamicColor("dark2")}`,
        borderRadius: "4px",
        p: 3,
        position: "relative",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        flexWrap="wrap"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <StackedMenu
          ref={menuRef}
          customAnchorElSx={{
            width: { xs: "100%", sm: "fit-content" },
          }}
          customAnchorEl={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
              sx={{
                borderRadius: "4px",
                border: `1px solid ${getDynamicColor("dark4")}`,
                px: 2,
                py: 1.5,
                // width: { xs: "100%", sm: "fit-content" },
                minWidth: 180,
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {getMetricLabel(selectedMetricId)}
              </Typography>
              <ArrowDropDownCircleIcon
                sx={{
                  color: getDynamicColor("primary"),
                }}
              />
            </Stack>
          }
          menuItems={[
            {
              title: "Custom Goals",
              subtitle: "Organization goals from roleplay scenarios",
              subMenuContent: (
                <OptionList
                  placeholder="Search custom goals"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleMetricSelected={handleMetricSelected}
                  options={customGoalsQuery.data ?? []}
                />
              ),
              closeOnSelect: true,
              subMenuWrapperSx,
            },
            {
              title: "Default Goals",
              subtitle: "Yoodli goals from roleplay scenarios",
              subMenuContent: (
                <OptionList
                  placeholder="Search default goals"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleMetricSelected={handleMetricSelected}
                  options={defaultGoals}
                />
              ),
              closeOnSelect: true,
              subMenuWrapperSx,
            },
            {
              title: "Analytics",
              subtitle: "AI generated feedback and metrics",
              closeOnSelect: true,
              subMenuContent: (
                <OptionList
                  placeholder="Search analytics"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleMetricSelected={handleMetricSelected}
                  options={analytics}
                />
              ),
              subMenuWrapperSx,
            },
            {
              title: "Usage",
              subtitle: "Comments, feedback given or received and, sharing ",
              closeOnSelect: true,
              subMenuContent: (
                <OptionList
                  placeholder="Search usage metrics"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleMetricSelected={handleMetricSelected}
                  options={usageMetrics}
                />
              ),
              subMenuWrapperSx,
            },
          ]}
        />
        <Box sx={{ width: { xs: "100%", sm: "fit-content" } }}>
          <YoodliDatePicker
            startDate={startDate.toFormat("yyyy-MM-dd")}
            endDate={endDate.toFormat("yyyy-MM-dd")}
            onChange={(startDate, endDate) => {
              setStartDate(DateTime.fromISO(startDate));
              setEndDate(DateTime.fromISO(endDate));
            }}
            dateRangeBank={[7, 30, 90]}
            onDateRangeSelected={(numDays) => {
              const newStartDate = DateTime.now().minus({ days: numDays });
              setStartDate(newStartDate);
              setEndDate(DateTime.now());
            }}
          />
        </Box>
      </Stack>
      <ReportSnapshot
        activeAnalytic={selectedMetricData}
        loadingData={analyticsQuery.isFetching}
        numDays={numDays}
        noData={{
          curr: !selectedMetricData?.data?.length,
          prev: !selectedMetricData?.data?.length,
        }}
        customNoDataCopy={{
          title: "No data found",
          description:
            "Please select another goal/analytic, or change the date range to see results!",
        }}
        noHoistedAnalytics={false}
        wrapperSx={{
          border: "none",
          boxShadow: "none",
        }}
        hideTitleRow
      />
    </Stack>
  );
};

const OptionList = ({
  searchQuery,
  setSearchQuery,
  placeholder,
  handleMetricSelected,
  options,
}: {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  placeholder: string;
  handleMetricSelected: (metricId: string) => void;
  options: { label: string; identifier: string }[];
}): JSX.Element => {
  const filteredOptions = options.filter((x) =>
    x.label.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <Stack>
      <YoodliSearchBar
        placeholder={placeholder}
        value={searchQuery}
        sx={{
          py: 0.5,
          px: 1,
          mt: 0.25,
          minHeight: 36,
          input: {
            py: 0.5,
            pl: 1.5,
          },
        }}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        clearSearch={() => {
          setSearchQuery("");
        }}
      />
      {searchQuery && filteredOptions.length === 0 && (
        <Typography sx={{ px: 2, py: 1, fontSize: 14, fontWeight: 600 }}>
          No results found for "{searchQuery}"
        </Typography>
      )}
      {filteredOptions.map((x) => (
        <MenuItem
          key={x.identifier}
          onClick={() => {
            handleMetricSelected(x.identifier);
          }}
          sx={{
            px: 2,
            py: 1,
            fontSize: 14,
            fontWeight: 600,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: getDynamicColor("light2"),
            },
          }}
        >
          {x.label}
        </MenuItem>
      ))}
    </Stack>
  );
};
