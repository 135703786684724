import React from "react";
import { useNavigate } from "react-router";

// Components
import { OrgOverviewSection } from "./OrgOverviewSection";
import AddIcon from "@mui/icons-material/Add";
import VideoCameraIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import { Box, Stack, Button, Typography, CircularProgress, Collapse } from "@mui/material";
import ZoodliCard from "components/Home/ZoodliCard";
import { YoodliAvatar } from "lib-frontend/components/YoodliComponents/YoodliAvatar";
import { defaultHeaderSx } from "lib-frontend/ui/Theme";

// Assets
import { ReactComponent as ClockIcon } from "images/icons/clock.svg";
import { ReactComponent as UpgradeIcon } from "images/icons/icon-upgrade.svg";

// Utils
import { DefaultOrgSwitcher } from "../DefaultOrgSwitcher";
import { OrgCtaBanner } from "../OrgCtaBanner";
import { OrgLoading } from "../OrgLoading";
import { TrialEnded } from "../TrialEnded";
import { TrialEndedBanner } from "../TrialEndedBanner";
import { UpdateOrgSeatsControls } from "../UpdateOrgSeatsControls";
import { HubDetailCard } from "./HubDetailCard";
import { SeatUtilization } from "./SeatUtilization";
import { useQuery as useApiQuery, useMutation } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import {
  listOrgUsersV2,
  listReports,
  updateOrgPaymentMethod,
  upsetOrgSubscription,
} from "lib-frontend/modules/AxiosInstance";
import { getDynamicColor } from "lib-frontend/utils/Colors";
import { getHubDataForOrg, getOrgInviteCount, isOrgTrialEnded } from "lib-frontend/utils/orgUtils";
import { useIsSmallScreen } from "lib-frontend/utils/themeUtils";
import { UpsetOrgSubscriptionResult } from "lib-fullstack/api/orgApiTypes";
import { GetOrgMemberListSortOption } from "lib-fullstack/api/orgApiTypes";
import { getClientEnvConfig } from "lib-fullstack/client_env";
import { DAY_IN_MS, MAX_ORG_SEATS } from "lib-fullstack/utils/constants";
import { getHumanReadableDate, isToday, isYesterday } from "lib-fullstack/utils/dateUtils";
import {
  DashboardType,
  EffectiveRole,
  OrgSubscriptionType,
  ReportState,
} from "lib-fullstack/utils/enums";
import { getLandingPageExternalUrl, LandingPageExternalPath } from "lib-fullstack/utils/paths";
import { WebServerInternalPath } from "utils/paths";
import { FeatureUpdateBanner } from "components/FeatureUpdate/FeatureUpdateBanner";
import { FeatureUpdateVisibility } from "lib-fullstack/db";
import { OrgContractExpirationBanner } from "../OrgContractExpirationBanner";
import { YoodliNotificationBannerVariant } from "lib-frontend/components/YoodliComponents/YoodliNotificationBanner";
import { useNotification } from "lib-frontend/contexts/useNotification";
import { useFeatureUpdatesContext } from "contexts/FeatureUpdatesContext";
import { WhatsNewAnnouncementType } from "lib-fullstack/utils/productAnalyticEvents";

export enum OrgOverviewQueryKeys {
  OrgUsers = "orgUsers",
  OrgInvites = "orgInvites",
  OrgOverallEngagement = "orgOverallEngagement",
  HubData = "hubData",
}

const NUM_ACTIVE_MEMBERS_TO_DISPLAY = 3;

export default function OrgOverview(): JSX.Element {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const { showNotificationBanner, notifAnchorRef } = useNotification();
  const [updatedOrgSeats, setUpdatedOrgSeats] = React.useState<number>(undefined);

  const { defaultOrg, defaultOrgLoading, invalidateDefaultOrgQuery } =
    React.useContext(UserOrgContext);

  const clientEnv = getClientEnvConfig();

  const { featureUpdate, featureUpdateVisibility, handleCloseFeatureUpdate } =
    useFeatureUpdatesContext();

  const orgUsersQuery = useApiQuery({
    queryKey: [OrgOverviewQueryKeys.OrgUsers, defaultOrg?.id],
    queryFn: () =>
      listOrgUsersV2(defaultOrg.id, {
        limit: NUM_ACTIVE_MEMBERS_TO_DISPLAY.toString(),
        sort: GetOrgMemberListSortOption.NUM_STARTED_SPEECHES_DESC,
        effective_roles: `${EffectiveRole.HUB_MEMBER},${EffectiveRole.HUB_ADMIN}`,
      }),
    enabled: !defaultOrgLoading,
  });

  const orgInvitesQuery = useApiQuery({
    queryKey: [OrgOverviewQueryKeys.OrgInvites, defaultOrg?.id],
    queryFn: () => getOrgInviteCount(defaultOrg.id),
  });

  const hubDataQuery = useApiQuery({
    queryKey: [OrgOverviewQueryKeys.HubData, defaultOrg?.id],
    queryFn: () => getHubDataForOrg(defaultOrg),
    enabled: !defaultOrgLoading,
  });

  const orgOverallEngagementQuery = useApiQuery({
    queryKey: [OrgOverviewQueryKeys.OrgOverallEngagement, defaultOrg?.id],
    queryFn: async () => {
      const params = {
        reportType: DashboardType.EngagementOverview,
        states: [ReportState.Rendered, ReportState.Expired],
      };
      const reports = await listReports(defaultOrg.id, params);
      return reports;
    },
    enabled: !defaultOrgLoading,
  });

  const parsedRecentReport = React.useMemo(() => {
    const reportWithData = orgOverallEngagementQuery?.data?.reports?.find((report) => report.data);
    if (!reportWithData) {
      return null;
    }
    return {
      ...reportWithData,
      data: JSON.parse(reportWithData?.data ?? ""),
    };
  }, [orgOverallEngagementQuery?.data]);

  const updateOrgSeatsMutation = useMutation({
    mutationFn: () => {
      const returnUrl = new URL(`${clientEnv.url.WEB_SERVER}${WebServerInternalPath.ORG_OVERVIEW}`);
      return upsetOrgSubscription(
        defaultOrg.id,
        updatedOrgSeats,
        returnUrl.toString(),
        returnUrl.toString()
      );
    },
    onSuccess: (result) => {
      if (result.result === UpsetOrgSubscriptionResult.REDIRECT) {
        window.location.href = result.redirect_url;
      } else if (result.result === UpsetOrgSubscriptionResult.SUBSCRIPTION_UPDATED) {
        return invalidateDefaultOrgQuery();
      } else if (
        result.result === UpsetOrgSubscriptionResult.FAIL_OTHER ||
        result.result === UpsetOrgSubscriptionResult.FAIL_NOT_ACCEPTABLE
      ) {
        showNotificationBanner(
          result.error_info ? result.error_info : "Failed to update seats. Please try again.",
          {
            variant: YoodliNotificationBannerVariant.Danger,
          }
        );
      }
    },
    onSettled: () => {
      setUpdatedOrgSeats(undefined);
    },
  });

  const updateOrgPaymentMethodMutation = useMutation({
    mutationFn: () => {
      const returnUrl = new URL(`${clientEnv.url.WEB_SERVER}${WebServerInternalPath.ORG_OVERVIEW}`);
      return updateOrgPaymentMethod(defaultOrg.id, returnUrl.toString(), returnUrl.toString());
    },
    onSuccess: (result) => {
      window.location.href = result;
    },
  });

  const renderUpdateSeatsCta = () => {
    if (updatedOrgSeats === defaultOrg.license_count) {
      return null;
    }
    return (
      <Button
        variant="contained"
        sx={{ whiteSpace: "nowrap" }}
        disabled={updateOrgSeatsMutation.isPending}
        onClick={() => updateOrgSeatsMutation.mutate()}
        startIcon={
          updateOrgSeatsMutation.isPending && (
            <CircularProgress
              size={12}
              sx={{
                color: getDynamicColor("dark1"),
              }}
            />
          )
        }
      >
        {updatedOrgSeats > defaultOrg.license_count ? "Add" : "Remove"}{" "}
        {Math.abs(updatedOrgSeats - defaultOrg.license_count)} seats
      </Button>
    );
  };

  const renderOverallEngagementTimeWindow = () => {
    return (
      <Typography
        sx={{
          color: getDynamicColor("purple3"),
          fontWeight: 600,
          fontSize: 12,
          fontFamily: "poppins",
        }}
      >
        Last 30 days
      </Typography>
    );
  };

  const renderSeatUtilizationCta = () => {
    if (updatedOrgSeats) {
      return (
        <Stack gap={3} direction="row">
          <Button onClick={() => setUpdatedOrgSeats(undefined)}>Cancel</Button>
          {!isSmallScreen && renderUpdateSeatsCta()}
        </Stack>
      );
    } else if (defaultOrg.is_trial && !defaultOrg.payment_method_added) {
      return (
        <Button
          disabled={updateOrgPaymentMethodMutation.isPending}
          onClick={() => updateOrgPaymentMethodMutation.mutate()}
          startIcon={
            <Stack sx={{ width: 17, height: 14 }} gap={0.5}>
              {updateOrgPaymentMethodMutation.isPending && <CircularProgress size={12} />}
              <UpgradeIcon />
            </Stack>
          }
          variant="gradient"
          sx={{ fontFamily: "poppins", fontWeight: 700, letterSpacing: 0.6 }}
        >
          UPGRADE
        </Button>
      );
    } else if (
      [OrgSubscriptionType.PAYG, OrgSubscriptionType.FLEXIBLE].includes(
        defaultOrg.subscription_type
      )
    ) {
      if (defaultOrg.license_used >= defaultOrg.license_count) {
        if (defaultOrg.license_count === MAX_ORG_SEATS) {
          return (
            <Button
              variant="gradient"
              target="_blank"
              href={getLandingPageExternalUrl(
                getClientEnvConfig(),
                LandingPageExternalPath.CONTACT_SALES
              )}
              sx={{ fontSize: "12px", whiteSpace: "nowrap", px: "16px" }}
            >
              Contact sales
            </Button>
          );
        } else {
          return (
            <Button
              variant="contained"
              onClick={() => setUpdatedOrgSeats(defaultOrg.license_count)}
              startIcon={<AddIcon sx={{ width: 16, height: 16 }} />}
            >
              Add more seats
            </Button>
          );
        }
      } else {
        return (
          <Button onClick={() => setUpdatedOrgSeats(defaultOrg.license_count)}>Manage seats</Button>
        );
      }
    }
  };

  const renderMembersCta = () => (
    <Button onClick={() => navigate(WebServerInternalPath.ORG_MEMBERS)}>View all members</Button>
  );

  const renderHubsCta = () => (
    <Button onClick={() => navigate(WebServerInternalPath.ORG_GROUPS)}>View all groups</Button>
  );

  const renderHeader = () => {
    return (
      <Stack
        direction="row"
        gap={1}
        ref={notifAnchorRef}
        sx={{
          ...defaultHeaderSx,
          px: { xs: 3, md: 5 },
          py: { xs: 2, md: "unset" },
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
          <Typography
            sx={{
              color: getDynamicColor("light1"),
              fontFamily: "poppins",
              fontWeight: 600,
            }}
          >
            Overview
          </Typography>
          {isOrgTrialEnded(defaultOrg) && <TrialEndedBanner />}
        </Stack>
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <DefaultOrgSwitcher />
        </Box>
      </Stack>
    );
  };

  const OVERALL_ENGAGEMENT_MIN_HEIGHT = 218;

  const renderOverallEngagementSection = (
    value: string,
    label: string,
    icon: JSX.Element,
    width: number,
    hasBorder: boolean
  ) => {
    const sharedSectionStyles = {
      py: { xs: 3, md: 0 },
      px: { xs: 2, md: 0 },
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      flexBasis: "50%",
      minHeight: { xs: "unset", md: OVERALL_ENGAGEMENT_MIN_HEIGHT },
    };
    return (
      <Stack
        sx={{
          borderRight: hasBorder && `1px solid ${getDynamicColor("dark3")}`,
          ...sharedSectionStyles,
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: 28, md: 32 },
            fontWeight: 700,
            color: getDynamicColor("purple3"),
            width,
            textAlign: "left",
          }}
        >
          {value ?? "N/A"}
        </Typography>
        <Stack
          direction="row"
          gap={0.75}
          alignItems="center"
          sx={{
            width,
          }}
        >
          {icon}
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: getDynamicColor("purple3"),
            }}
          >
            {label}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const renderOverallEngagement = () => {
    let content = (
      <>
        {renderOverallEngagementSection(
          // if no reports show "N/A", otherwise just show 0
          !parsedRecentReport ? undefined : parsedRecentReport?.data?.[0]?.speechesStartedL30 ?? 0,
          "Yoodlis started",
          <VideoCameraIcon sx={{ height: 21, width: 21 }} />,
          119,
          true
        )}
        {renderOverallEngagementSection(
          // if no reports show "N/A", otherwise just show 0
          !parsedRecentReport ? undefined : parsedRecentReport?.data?.[0]?.minutesRecordedL30 ?? 0,
          "Minutes recorded",
          <ClockIcon style={{ height: 18, width: 18 }} />,
          132,
          false
        )}
        {parsedRecentReport?.renderDate && (
          <Typography
            sx={{
              color: getDynamicColor("dark4"),
              fontSize: 10,
              fontWeight: 500,
              position: "absolute",
              bottom: -24,
              left: 0,
            }}
          >
            Last updated{" "}
            {getHumanReadableDate(parsedRecentReport?.renderDate, {
              month: "numeric",
              day: "numeric",
              year: "2-digit",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            }).replace(",", " at")}
          </Typography>
        )}
      </>
    );
    if (orgOverallEngagementQuery?.isLoading) {
      content = <CircularProgress />;
    } else if (!parsedRecentReport) {
      content = (
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontSize: 14,
            fontWeight: 600,
            fontFamily: "poppins",
            textAlign: "center",
            px: 2,
          }}
        >
          No data available
        </Typography>
      );
    }
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          border: `1px solid ${getDynamicColor("dark3")}`,
          borderRadius: "8px",
          flexGrow: 1,
          fontFamily: "poppins",
          position: "relative",
          height: { xs: "unset", md: `max(100%, ${OVERALL_ENGAGEMENT_MIN_HEIGHT}px)` },
          minHeight: { xs: "unset", md: OVERALL_ENGAGEMENT_MIN_HEIGHT },
        }}
      >
        {content}
      </Stack>
    );
  };

  const renderSeatUtilization = () => {
    return (
      <Stack
        sx={{
          border: `1px solid ${getDynamicColor("dark3")}`,
          borderRadius: "8px",
          py: 2,
          px: 4,
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        {updatedOrgSeats ? (
          <Stack
            gap={{ xs: 2, md: 6 }}
            direction={{ xs: "column-reverse", md: "row" }}
            sx={{
              alignItems: "center",
              pt: { xs: 2, md: 5 },
              pb: { xs: updatedOrgSeats === defaultOrg.license_count ? 8 : 2, md: 7 },
              justifyContent: "center",
            }}
          >
            {isSmallScreen && renderUpdateSeatsCta()}
            <UpdateOrgSeatsControls
              updatedOrgSeats={updatedOrgSeats}
              setUpdatedOrgSeats={setUpdatedOrgSeats}
              org={defaultOrg}
              size={isSmallScreen ? 80 : 60}
              fontSize={isSmallScreen ? 27 : 20}
            />
            <Stack gap={1}>
              <Typography
                sx={{
                  color: getDynamicColor("dark4"),
                  fontSize: "10px",
                  fontWeight: 600,
                  fontFamily: "poppins",
                }}
              >
                {defaultOrg.license_used}/{defaultOrg.license_count} seats occupied
              </Typography>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontSize: "12px",
                  fontWeight: 600,
                  fontFamily: "poppins",
                  width: "150px",
                }}
              >
                Use the buttons to add or remove seats, or enter the total number of desired seats
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction={{ xs: "column", md: "row" }}
            columnGap={8}
            rowGap={2}
            sx={{ alignItems: "center", flexWrap: "wrap", justifyContent: "center", py: 4 }}
          >
            <SeatUtilization org={defaultOrg} />
            <Stack
              sx={{
                color: getDynamicColor("dark4"),
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              {/* If Inactive Seat Release is active, invites will no longer count as occupied so the math needs to be adjusted accordingly */}
              <Typography sx={{ lineHeight: "28px" }}>
                {defaultOrg.license_used -
                  (defaultOrg.num_seat_inactive_days > 0 ? 0 : orgInvitesQuery.data)}{" "}
                occupied
              </Typography>
              <Typography sx={{ lineHeight: "28px" }}>
                {orgInvitesQuery.data} pending invites
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderMostActiveMembers = () => {
    const getDate = (date: string) => {
      if (isToday(new Date(date))) {
        return "today";
      } else if (isYesterday(new Date(date))) {
        return "yesterday";
      } else {
        return getHumanReadableDate(date, { year: "2-digit", month: "numeric", day: "numeric" });
      }
    };

    if (!orgUsersQuery.data?.users?.length) {
      return (
        <Typography
          sx={{
            color: getDynamicColor("dark4"),
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "poppins",
          }}
        >
          No member activity
        </Typography>
      );
    }

    return (
      <Stack sx={{ width: "100%", justifyContent: "space-between", gap: 1.5 }}>
        {orgUsersQuery.data.users.map((member, index) => (
          <Stack
            key={index}
            direction="row"
            gap={2}
            sx={{
              alignItems: "center",
              borderRadius: "8px",
              border: `1px solid ${getDynamicColor("dark3")}`,
              pl: 2,
              pr: 4,
              py: 2,
            }}
          >
            <YoodliAvatar name={member.name} />
            <Stack
              gap={1}
              direction={{ xs: "column", md: "row" }}
              sx={{
                alignItems: { xs: "flex-start", md: "center" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    color: getDynamicColor("purple3"),
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    flexWrap: "wrap",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  sx={{
                    color: getDynamicColor("dark4"),
                    fontFamily: "poppins",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Active {getDate(member.date_last_activity)}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: getDynamicColor("purple3"),
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                {member.num_started_speeches} Yoodlis
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    );
  };

  const renderHubs = () => {
    const mostActiveHubs = hubDataQuery.data
      .sort((a, b) => b.numMembers - a.numMembers)
      .slice(0, 3);

    return (
      <Stack
        direction="row"
        gap={3}
        sx={{
          overflowX: { xs: "auto", md: "initial" },
          flexWrap: { xs: "initial", md: "wrap" },
        }}
      >
        <HubDetailCard
          disableCreate={hubDataQuery.data.length >= defaultOrg.hub_quota}
          onClick={() =>
            navigate(WebServerInternalPath.CREATE_GROUP, { state: { from: "overview" } })
          }
        />
        {mostActiveHubs.map((hub, index) => (
          <HubDetailCard
            key={index}
            hub={hub}
            onClick={() => {
              const hubParam = new URLSearchParams({ hubId: hub.id });
              navigate({
                pathname: WebServerInternalPath.ORG_GROUPS,
                search: hubParam.toString(),
              });
            }}
          />
        ))}
      </Stack>
    );
  };

  const renderBanner = () => {
    const showExpired =
      [OrgSubscriptionType.PREPAID, OrgSubscriptionType.FLEXIBLE].includes(
        defaultOrg?.subscription_type
      ) &&
      defaultOrg.cancellation_date &&
      new Date(defaultOrg.cancellation_date).getTime() < Date.now();

    const outOfSeats =
      [OrgSubscriptionType.PAYG, OrgSubscriptionType.FLEXIBLE].includes(
        defaultOrg?.subscription_type
      ) && defaultOrg?.license_count === defaultOrg.license_used;
    const outOfSeatsTitle = (
      <Stack
        direction="row"
        gap={0.5}
        sx={{
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
      >
        <Typography sx={{ fontFamily: "poppins", fontWeight: 600 }}>
          Looks like your organization is out of seats.
        </Typography>
        <Typography sx={{ fontFamily: "poppins" }}>
          Upgrade now to add more members to your org.
        </Typography>
      </Stack>
    );
    if (showExpired) {
      return <OrgContractExpirationBanner />;
    }
    if (
      (defaultOrg.is_trial &&
        !defaultOrg.payment_method_added &&
        new Date(defaultOrg.renewal_date).getTime() - Date.now() < DAY_IN_MS &&
        Date.now() < new Date(defaultOrg.renewal_date).getTime()) ||
      outOfSeats
    ) {
      return (
        <OrgCtaBanner
          sx={{
            backgroundColor: getDynamicColor("redErrorLight"),
            color: getDynamicColor("redError"),
            justifyContent: "space-between",
          }}
          title={
            outOfSeats ? (
              outOfSeatsTitle
            ) : (
              <Stack
                direction="row"
                gap={0.5}
                sx={{
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  textAlign: "left",
                }}
              >
                <Typography sx={{ fontFamily: "poppins", fontWeight: 600 }}>
                  Your free trial ends tomorrow!
                </Typography>
                <Typography sx={{ fontFamily: "poppins" }}>
                  Upgrade now to keep your organization
                </Typography>
              </Stack>
            )
          }
          cta={
            <Button
              onClick={() => updateOrgPaymentMethodMutation.mutate()}
              startIcon={
                <Box sx={{ width: 17, height: 14 }}>
                  <UpgradeIcon />
                </Box>
              }
              variant="gradient"
              sx={{ fontFamily: "poppins", fontSize: "12px", fontWeight: 700, letterSpacing: 0.6 }}
            >
              UPGRADE
            </Button>
          }
        />
      );
    } else if (
      defaultOrg.license_used >= defaultOrg.license_count &&
      (defaultOrg.subscription_type === OrgSubscriptionType.PREPAID ||
        defaultOrg.license_count === MAX_ORG_SEATS)
    ) {
      return (
        <OrgCtaBanner
          sx={{
            justifyContent: "space-between",
            width: "unset",
            backgroundColor: getDynamicColor("redErrorLight"),
            color: getDynamicColor("redError"),
          }}
          title={
            <Stack
              direction="row"
              gap={0.5}
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              <Typography sx={{ fontFamily: "poppins", fontWeight: 600 }}>
                You're out of seats!
              </Typography>
              <Typography sx={{ fontFamily: "poppins" }}>
                Contact sales to add more seats or learn about our Enterprise plan
              </Typography>
            </Stack>
          }
          cta={
            <Button
              variant="gradient"
              target="_blank"
              href={getLandingPageExternalUrl(
                getClientEnvConfig(),
                LandingPageExternalPath.CONTACT_SALES
              )}
              sx={{ fontSize: "12px", whiteSpace: "nowrap", px: "16px" }}
            >
              Contact sales
            </Button>
          }
        />
      );
    }
    // if no other banners are showing -- show the org almost expired banner
    return <OrgContractExpirationBanner />;
  };

  if (
    !defaultOrg ||
    orgUsersQuery.isLoading ||
    orgInvitesQuery.isLoading ||
    hubDataQuery.isLoading
  ) {
    return <OrgLoading prompts={["Loading organization overview..."]} />;
  }

  const renderContent = () => {
    return (
      <Stack gap={3} sx={{ background: getDynamicColor("light1"), pb: 14, minHeight: "100vh" }}>
        {renderHeader()}
        {isOrgTrialEnded(defaultOrg) ? (
          <TrialEnded org={defaultOrg} />
        ) : (
          <Stack gap={5} sx={{ px: { xs: 3, md: 8 }, width: "100%", mx: "auto", maxWidth: "xxl" }}>
            {renderBanner()}
            <Collapse
              in={!!featureUpdate && featureUpdateVisibility !== FeatureUpdateVisibility.Hide}
              unmountOnExit
              appear
            >
              <Box>
                <FeatureUpdateBanner
                  featureUpdate={featureUpdate}
                  visibility={featureUpdateVisibility}
                  onClose={() => handleCloseFeatureUpdate(WhatsNewAnnouncementType.ADMIN)}
                  type={WhatsNewAnnouncementType.ADMIN}
                />
              </Box>
            </Collapse>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              gap={{ xs: 5, md: 3 }}
              sx={{ width: "100%" }}
            >
              <OrgOverviewSection
                title="Overall engagement"
                content={renderOverallEngagement()}
                cta={renderOverallEngagementTimeWindow()}
              />
              <OrgOverviewSection
                title="Seat utilization"
                tooltip="Seat utilization includes members who have joined and are invited."
                content={renderSeatUtilization()}
                cta={renderSeatUtilizationCta()}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              gap={{ xs: 5, md: 3 }}
              sx={{ width: "100%" }}
            >
              <OrgOverviewSection
                title="Most active groups"
                content={renderHubs()}
                cta={renderHubsCta()}
              />
              <OrgOverviewSection
                title="Most active members"
                content={renderMostActiveMembers()}
                cta={renderMembersCta()}
              />
            </Stack>

            <Box sx={{ pt: 3 }}>
              <ZoodliCard />
            </Box>
          </Stack>
        )}
      </Stack>
    );
  };

  return renderContent();
}
