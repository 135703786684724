import React from "react";
import { Stack, Button, Menu, Typography, CircularProgress } from "@mui/material";
import { HubOption } from "components/Orgs/BulkInviteByCSVWizard/SelectHub";
import { getDynamicColor, Y_SHADOWS } from "lib-frontend/utils/Colors";
// Components
import { ExpandCircleDownRounded as ExpandCircleDownRoundedIcon } from "@mui/icons-material";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { ApiErrorCode } from "lib-fullstack/utils/enums";
import { AxiosError } from "axios";
import { ErrorResponse } from "lib-fullstack/api/apiTypes";

export const GroupAssignmentMenu = ({
  activeGroupIds,
  handleUpdateGroupAssignment,
}: {
  activeGroupIds: string[];
  handleUpdateGroupAssignment: (groupIds: string[]) => Promise<AxiosError<ErrorResponse> | null>;
}): JSX.Element => {
  const { defaultOrg } = React.useContext(UserOrgContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedGroupIds, setSelectedGroupIds] = React.useState<string[]>(activeGroupIds ?? []);
  const [groupAssignmentError, setGroupAssignmentError] = React.useState<string>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const allGroups = React.useMemo(() => {
    return defaultOrg?.hubs.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  }, [defaultOrg]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = async () => {
    setGroupAssignmentError(undefined);
    setLoading(true);
    let groupAssignmentError = "";
    if (selectedGroupIds.sort().join() !== activeGroupIds.sort().join()) {
      const error: AxiosError<ErrorResponse> = await handleUpdateGroupAssignment(selectedGroupIds);
      if (error) {
        groupAssignmentError = error.message;
        if (error.response?.data?.code === ApiErrorCode.UsedByPublishedProgram) {
          groupAssignmentError =
            "This scenario is used by a published program and cannot be unassigned from one of its active groups.";
        }
        setGroupAssignmentError(groupAssignmentError);
      }
    }
    if (!groupAssignmentError) {
      setAnchorEl(null);
    }
    // set a timeout for this so as he menu animates out the user doesnt see the word "Done" pop back in form the spinner
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  return (
    <>
      <Button
        id="hub-menu-button"
        aria-controls={open ? "hub-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
        endIcon={
          <ExpandCircleDownRoundedIcon
            sx={{
              height: 20,
              width: 20,
              color: getDynamicColor("primary"),
            }}
          />
        }
        sx={{
          justifyContent: "space-between",
          height: 40,
          borderRadius: "8px",
          border: `1px solid ${getDynamicColor("dark2")}`,
          fontSize: 14,
          fontWeight: 500,
          minWidth: { xs: "100%", sm: 210 },
          color: getDynamicColor(selectedGroupIds.length === 0 ? "dark4" : "purple3"),
          whiteSpace: "nowrap",
        }}
      >
        {selectedGroupIds.length === 0
          ? "Not assigned"
          : `Assigned to ${selectedGroupIds.length} group${
              selectedGroupIds.length === 1 ? "" : "s"
            }`}
      </Button>
      <Menu
        id="hub-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "hub-menu-button",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: { xs: "100%", sm: 190 },

              borderRadius: "8px",
              boxShadow: Y_SHADOWS.box_shadow_1,
              border: `1px solid ${getDynamicColor("dark2")}`,
            },
          },
        }}
      >
        <Stack
          direction="column"
          gap={1}
          alignItems="flex-start"
          sx={{
            height: "100%",

            px: 2,
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <Button
            sx={{
              fontSize: 12,
              position: "relative",
              top: 4,
              left: -4,
            }}
            onClick={() => {
              if (selectedGroupIds.length > 0) {
                setSelectedGroupIds([]);
              } else {
                setSelectedGroupIds(allGroups.map((hub) => hub.id));
              }
            }}
          >
            {selectedGroupIds?.length === 0 ? "Select " : "Deselect "}all
          </Button>
          {allGroups.map((group) => (
            <HubOption
              key={group.id}
              hubName={group.name}
              checked={selectedGroupIds.includes(group.id)}
              onChange={(e) => {
                setSelectedGroupIds(
                  e.target.checked
                    ? [...selectedGroupIds, group.id]
                    : selectedGroupIds.filter((id) => id !== group.id)
                );
              }}
              sx={{
                mr: 0,
              }}
            />
          ))}
          {groupAssignmentError && (
            <Typography
              sx={{
                color: getDynamicColor("redError"),
                fontSize: "12px",
                textWrap: "wrap",
                maxWidth: "300px",
              }}
            >
              {groupAssignmentError}
            </Typography>
          )}
          <Button
            onClick={handleCloseMenu}
            variant="outlined_small"
            sx={{
              mx: "auto",
              mb: 1,
              px: 2,
              fontSize: 14,
              borderWidth: 1,
              // static height and width so the loader doesn't change the size of the button
              width: 72,
              height: 30,
            }}
          >
            {loading ? <CircularProgress size={16} /> : "Done"}
          </Button>
        </Stack>
      </Menu>
    </>
  );
};
