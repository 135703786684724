import React from "react";
import { useNavigate } from "react-router";
// React Slick Carousel
import Slider from "react-slick";

// Components
import { Box, Button, Stack, Typography } from "@mui/material";
import { scenarioTypeMap } from "lib-fullstack/utils/orgUtils";
import { MyLearningSection } from "components/Orgs/MyLearning";
import { MemberProgramItem } from "components/Orgs/Programs/MemberProgramItem";
import { OrgProgramsQueryKeys, ScenarioSubQueryKeys } from "components/Orgs/Programs/OrgPrograms";

// Utils
import { HOMEPAGE_SLIDER_STYLES, renderHomepageSliderDots } from "./Home";
import { UseQueryResult, useQuery as useApiQuery } from "@tanstack/react-query";
import { UserOrgContext } from "lib-frontend/contexts/UserOrgContext";
import { listScenariosMemberView } from "lib-frontend/modules/AxiosInstance";
import { getScenarioPracticePath } from "lib-frontend/utils/orgUtils";
import { Instrumentation } from "lib-frontend/utils/ProductAnalyticsUtils";
import { ProgramMemberViewItem } from "lib-fullstack/api/programApiTypes";
import { ProgramRecordState, PlanStepState, UITestId } from "lib-fullstack/utils/enums";
import { WebServerExternalPath } from "lib-fullstack/utils/paths";
import { ProgramMemberNextStepCtaWheres } from "lib-fullstack/utils/productAnalyticEvents";
import { MyLearningQueryParams } from "lib-fullstack/utils/queryParams";

type ProgramsCarouselProps = {
  programMemberViewQuery: UseQueryResult<ProgramMemberViewItem[]>;
};

export const ProgramsCarousel = ({
  programMemberViewQuery,
}: ProgramsCarouselProps): JSX.Element => {
  const navigate = useNavigate();

  const { defaultOrg } = React.useContext(UserOrgContext);

  const scenariosQuery = useApiQuery({
    queryKey: [OrgProgramsQueryKeys.ScenarioList, defaultOrg?.id, ScenarioSubQueryKeys.Member],
    queryFn: () => listScenariosMemberView(defaultOrg.id),
    enabled: !!defaultOrg,
  });

  const activePrograms = React.useMemo(() => {
    return (
      programMemberViewQuery?.data?.sort((a, b) => {
        // sorting such that completed programs are at the end but the order is otherwise unchanged.
        if (a.state === ProgramRecordState.Completed && b.state !== ProgramRecordState.Completed) {
          return 1;
        }
        if (a.state !== ProgramRecordState.Completed && b.state === ProgramRecordState.Completed) {
          return -1;
        }
        return 0;
      }) ?? []
    );
  }, [programMemberViewQuery?.data]);

  const sliderRef = React.useRef<Slider>(null);
  const settings = {
    dots: true,
    slidesToShow: 1,
    initialSlide: 0,
    infinite: activePrograms.length > 1,
    appendDots: (dots) => renderHomepageSliderDots(dots, sliderRef),
  };

  if (
    programMemberViewQuery.isLoading ||
    scenariosQuery.isLoading ||
    programMemberViewQuery?.data?.length === 0 ||
    !defaultOrg
  ) {
    return;
  }

  const practiceProgram = (program: ProgramMemberViewItem) => {
    Instrumentation.logProgramMemberNextStepCtaClicked(
      defaultOrg.id,
      program.id,
      ProgramMemberNextStepCtaWheres.HOME
    );
    const programStepToPractice = program.plan_step_results.find(
      (step) => step.state !== PlanStepState.Completed
    );
    const scenario = scenariosQuery.data?.contentArray.find(
      (s) => s.id === programStepToPractice.scenario_id
    );
    navigate(
      getScenarioPracticePath(
        scenario.id,
        defaultOrg.id,
        false,
        scenarioTypeMap[scenario.scenarioTypeId]
      )
    );
  };

  const goToProgramDetails = (program: ProgramMemberViewItem) => {
    navigate(
      `${WebServerExternalPath.MY_LEARNING}?section=${MyLearningSection.ProgramInfo}&programId=${program.id}`
    );
  };

  const getCta = (program: ProgramMemberViewItem) => {
    switch (program.state) {
      case ProgramRecordState.NotStarted:
        return (
          <Button
            data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
            variant="contained"
            onClick={() => practiceProgram(program)}
            sx={{ fontSize: "12px" }}
          >
            Start
          </Button>
        );
      case ProgramRecordState.InProgress:
        return (
          <Stack direction="row" gap={2}>
            <Button
              data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
              variant="contained"
              onClick={() => practiceProgram(program)}
              sx={{ fontSize: "12px" }}
            >
              Continue
            </Button>
            <Button
              onClick={() =>
                navigate(
                  `${WebServerExternalPath.MY_LEARNING}?${MyLearningQueryParams.SECTION}=${MyLearningSection.ProgramInfo}&${MyLearningQueryParams.PROGRAM_ID}=${program.id}`
                )
              }
              sx={{
                fontSize: "12px",
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              View report
            </Button>
          </Stack>
        );
      case ProgramRecordState.Completed:
        return (
          <Button
            data-testid={`${UITestId.ProgramCarouselCardPracticeButton}-${program.id}`}
            variant="contained"
            onClick={() => goToProgramDetails(program)}
            sx={{ fontSize: "12px" }}
          >
            Practice Again
          </Button>
        );
    }
  };

  return (
    <Stack
      gap={2}
      sx={{
        pb: activePrograms?.length > 1 ? 4.5 : 3,
      }}
    >
      <Stack direction="row" gap={1} sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          data-testid={UITestId.ProgramCarouselTitle}
          variant="poppins"
          component="h2"
          sx={{
            fontSize: { xs: 18, sm: 20 },
            fontWeight: 600,
          }}
        >
          Your programs
        </Typography>
        <Button onClick={() => navigate(WebServerExternalPath.MY_LEARNING)} sx={{ fontSize: 14 }}>
          See programs
        </Button>
      </Stack>
      <Box sx={HOMEPAGE_SLIDER_STYLES}>
        <Slider ref={sliderRef} {...settings}>
          {activePrograms.map((program) => (
            <MemberProgramItem key={program.id} program={program} cta={getCta(program)} />
          ))}
        </Slider>
      </Box>
    </Stack>
  );
};
